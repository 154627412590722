import gg_axios from '../gg_axios';

/**
 * Remove a player from the ladder (with user token)
 * @param {string} ladder_id
 * @returns {object} player static information
 */
async function gg_ladder_remove_player(ladder_id) {
    try {
        const result = await gg_axios('PUT', `/ladder/remove/${ladder_id}`);

        return result;
    } catch (error) {
        throw error;
    }
}

export default gg_ladder_remove_player;
