<template>
  <DefaultCard :title="title" height="100%">
    <v-autocomplete
        filled
        rounded
        dense
        append-icon=""
        no-data-text="If you see this, something is wrong"
        background-color="background"
        :items="users"
        :item-text="getItemText"
        :label="$t('dashboard.search.label')"
        :filter="searchFilter"
    >
      <template v-slot:item="{ item }">
        <v-list-item :to="{name: 'PlayerProfile', params: {id: item._id}}">
          <v-list-item-avatar v-if="item.user_id">
            <img
                :src="
                  item.image_id
                    ? endpoint + '/image/raw/' + item.image_id
                    : require(`@/assets/images/default-avatar.png`)
                "
            />
          </v-list-item-avatar>
          <!-- Temporary solution to display the default avatar for teams -->
          <v-list-item-avatar v-else>
            <img :src="require(`@/assets/images/default-avatar.png`)"/>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-text="item.name"></v-list-item-title>
            <v-list-item-subtitle v-text="item.company_id.name"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
  </DefaultCard>
</template>

<script>
import gg_all_users from "@/api/user/gg_all_users";
import DefaultCard from "@/components/common/DefaultCard.vue";

export default {
name: "SearchUsers",

  components : {
    DefaultCard,
  },

  props: ['title'],

  data() {
    return {
      users: [],
    };
  },

  async created() {
    const users = await gg_all_users();
    if (users) {
      this.users = users;
    }
  },

  methods: {
    searchFilter(item, queryText, itemText) {
      const hasValue = val => val != null ? val : '';
      const text = hasValue(itemText);
      const query = hasValue(queryText);
      return text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1;
    },

    getItemText(item) {
      return `${item.name} - ${item.company_id.name}`;
    }
  }
}
</script>

<style scoped>

</style>