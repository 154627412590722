<template>
	<Main background="fifa-background.png">
		<Sidebar />
		<v-container>
			<BackBar />

			<div v-if="false" class="d-flex align-center">
				<span class="mr-4 font-weight-bold"> Console </span>
				<v-switch inset hide-details :ripple="false" color="primary" class="platform-switch mt-0"></v-switch>
				<span class="font-weight-bold"> PC </span>
			</div>
			<PageHeader :title="$t('common.my_ladders')">
				<Button v-if="$store.getters.getUserInfo.usertype === 'SUPERUSER'" to="/ladder/add"> {{ $t("ladder.manage.add_ladder") }} </Button>
				<div width="300px" class="ml-4">
					<SearchBar v-model="search" :placeholder="$t('ladder.search')" />
				</div>
			</PageHeader>

			<v-data-iterator
				:items="my_ladders"
				:items-per-page="-1"
				:search="search"
				hide-default-footer
				:no-data-text="$t('ladders.no_ladders')"
			>
				<template v-slot:default="{ items }">
					<v-row class="ml-n4 mt-n4" no-gutters>
						<div v-for="item in items" :key="item._id" class="ma-4">
							<LadderCard :data="item" />
						</div>
					</v-row>
				</template>
			</v-data-iterator>

			<PageHeader :title="$t('ladders.all')"> </PageHeader>

			<v-data-iterator :items="all_ladders" :items-per-page="-1" :search="search" hide-default-footer>
				<template v-slot:default="{ items }">
					<v-row class="ml-n4 mt-n4" no-gutters>
						<div v-for="item in items" :key="item._id" class="ma-4">
							<LadderCard :data="item" type="other" />
						</div>
					</v-row>
				</template>
			</v-data-iterator>
		</v-container>
	</Main>
</template>

<style scoped>
.platform-switch >>> .v-input--selection-controls__input div {
	color: var(--v-primary-base);
}
.platform-switch >>> .v-input--switch__track {
	color: rgba(255, 255, 255, 0.3) !important;
}
</style>

<script>
import Sidebar from '../components/common/Sidebar';
import BackBar from '../components/common/BackBar';
import Main from '../components/common/Main';
import PageHeader from '../components/common/PageHeader';
import LadderCard from '../components/ladders/LadderCard';
import Button from '../components/common/elements/Button';
import SearchBar from '../components/common/elements/SearchBar';

import { gg_ladder_all, gg_ladder_my, gg_teamladder_my } from '../api';

export default {
	name: 'Settings',

	components: {
		Sidebar,
		BackBar,
		Main,
		PageHeader,
		LadderCard,
		Button,
		SearchBar,
	},
	data() {
		return {
			all_ladders: [],
			my_ladders: [],
			search: '',
		};
	},

	async created() {
		try {
			const all = await gg_ladder_all();
			const my = await gg_ladder_my();
      const my_team = await gg_teamladder_my();

			this.all_ladders = all.filter((u) => !my.ranked.map((a) => a._id).includes(u._id) && !my_team.ranked.map((a) => a._id).includes(u._id));
			this.my_ladders = all.filter((u) => my.ranked.map((a) => a._id).includes(u._id) || my_team.ranked.map((a) => a._id).includes(u._id));
			//console.log(JSON.stringify(this.all_ladders));
			//console.log(JSON.stringify(this.my_ladders));
		} catch (err) {
			console.log(err);
			this.message = {
				type: 'error',
				text: err,
			};
		}
	},
};
</script>