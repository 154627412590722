<template>
	<Main background="fifa-background.png">
		<Sidebar />
		<v-container>
			<BackBar />

			<ValidationObserver v-slot="{ handleSubmit }">
				<PageHeader title="Settings">
					<v-tabs background-color="transparent" height="35" slider-size="3" class="ml-6" style="width: auto">
						<Tab to="/settings" exact> {{ $t("common.personal") }} </Tab>
						<Tab to="/settings/company"> {{ $t("common.company_c") }} </Tab>
					</v-tabs>

					<v-alert class="message-alert" dense outlined :type="message.type" v-if="message">
						{{ message.text }}
					</v-alert>
				</PageHeader>

				<v-row>
					<v-col cols="12">
						<Button class="mr-4" :loading="loading" :disabled="loading" @click="handleSubmit(updateCompany)">
              {{ $t("settings.save_changes") }}
						</Button>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12">
						<DefaultCard :title="$t('settings.company')">
							<v-row>
								<v-col cols="12" sm="3">
									<v-row no-gutters>
										<v-hover v-slot:default="{ hover }">
											<v-card color="primary" class="rounded-xl" width="100%">
												<v-img id="avatar" :src="image" aspect-ratio="1" v-ripple>
													<label for="file-input" style="cursor: pointer">
														<v-fade-transition>
															<v-overlay v-if="hover" absolute>
																<v-icon> mdi-image-edit-outline </v-icon>
																<input
																	id="file-input"
																	type="file"
																	accept="image/*"
																	style="display: none"
																	@change="
																		cropper_active = true;
																		cropperPreview($event);
																	"
																/>
															</v-overlay>
														</v-fade-transition>
													</label>
												</v-img>
											</v-card>
										</v-hover>
									</v-row>

									<v-row no-gutters>
										<label for="avatar" class="font-weight-bold mt-3"> {{ $t("settings.change_pic", {cp: $t('common.company')}) }} </label>
									</v-row>
								</v-col>
								<v-col cols="12" sm="9" lg="7">
									<v-row no-gutters>
										<v-col cols="12" md="3">
											<label for="name" class="formlabel font-weight-bold"> {{ $t("settings.change_name", {cp: $t('common.company')}) }} </label>
										</v-col>
										<v-col cols="12" md="9">
											<FormField id="name" v-model="name" rules="required" :placeholder="$t('settings.name_placeholder')" />
										</v-col>
									</v-row>

									<v-row no-gutters>
										<v-col cols="12" md="3">
											<label for="city" class="formlabel font-weight-bold"> {{ $t("settings.change_city") }} </label>
										</v-col>
										<v-col cols="12" md="9">
											<FormField id="city" v-model="city" rules="required" :placeholder="$t('settings.city_placeholder')" />
										</v-col>
									</v-row>

									<v-row no-gutters>
										<v-col cols="12" md="3">
											<label for="domain" class="formlabel font-weight-bold"> {{ $t("settings.domain") }} </label>
										</v-col>
										<v-col cols="12" md="9">
											<FormField disabled id="domain" v-model="domain" rules="required" />
										</v-col>
									</v-row>

									<v-row no-gutters>
										<v-col cols="12" md="3">
											<label for="bio" class="formlabel font-weight-bold"> {{ $t("settings.change_bio", {cp: $t('common.company')}) }} </label>
										</v-col>
										<v-col cols="12" md="9">
											<v-textarea
												filled
												rounded
												background-color="background"
												id="bio"
												v-model="bio"
												:placeholder="$t('misc.write_sth')"
											/>
										</v-col>
									</v-row>
								</v-col>
							</v-row>
						</DefaultCard>
					</v-col>

					<v-col cols="12">
						<DefaultCard title="Manage Admins">
							<v-row no-gutters>
								<v-col cols="12" lg="10">
									<div class="d-flex align-center mb-4">
										<v-icon color="primary" class="mr-2"> mdi-plus-circle </v-icon>
										<v-autocomplete
											filled
											rounded
											dense
											hide-details
											menu-props="rounded"
											:placeholder="$t('settings.admin_placeholder')"
											background-color="background"
											ref="select"
											:items="users"
											:filter="searchFilter"
										>
											<template v-slot:no-data>
												<v-list-item>
													<v-list-item-title> {{ $t("settings.no_users") }} </v-list-item-title>
												</v-list-item>
											</template>
											<template v-slot:item="{ item }">
												<v-list-item @click="addAdmin(item._id)" :disabled="loading && loading_index === item._id">
													<v-list-item-avatar>
														<img
															:src="
																item.image_id
																	? endpoint + '/image/raw/' + item.image_id
																	: require(`@/assets/images/default-avatar.png`)
															"
														/>
													</v-list-item-avatar>
													<v-list-item-content>
														<v-list-item-title v-text="item.name"></v-list-item-title>
														<v-list-item-subtitle v-text="item.email"></v-list-item-subtitle>
													</v-list-item-content>
													<v-list-item-icon v-if="loading && loading_index === item._id" style="align-self: center">
														<v-progress-circular indeterminate size="24" color="primary"></v-progress-circular>
													</v-list-item-icon>
												</v-list-item>
											</template>
										</v-autocomplete>
									</div>

									<v-list class="rounded-xl" style="background-color: var(--v-background-base); overflow: hidden">
										<v-row no-gutters class="px-4 text-caption grey--text">
											<v-spacer />
											<v-col cols="3">
												<span> {{ $t("settings.admin_name") }} </span>
											</v-col>
											<v-col cols="8">
												<span> {{ $t("common.email") }} </span>
											</v-col>
										</v-row>
										<v-list-item v-for="(item, i) in admins" :key="i" ripple>
											<v-row no-gutters align="center">
												<v-col cols="1">
													<v-avatar color="black" size="32">
														<v-img
															:src="
																item.image_id
																	? endpoint + '/image/raw/' + item.image_id
																	: require(`@/assets/images/default-avatar.png`)
															"
														/>
													</v-avatar>
												</v-col>
												<v-col cols="3">
													<span> {{ item.name }} </span>
												</v-col>
												<v-col cols="7">
													<span> {{ item.email }} </span>
												</v-col>
												<v-col cols="1" class="d-flex justify-end">
													<v-list-item-action style="margin: 0px" v-if="item._id !== $store.getters.getUserInfo._id">
														<v-btn
															icon
															:loading="loading && loading_index === item._id"
															:disabled="loading && loading_index === item._id"
															@click="removeAdmin(item._id)"
														>
															<v-icon color="grey lighten-1"> mdi-close </v-icon>
														</v-btn>
													</v-list-item-action>
												</v-col>
											</v-row>
										</v-list-item>
									</v-list>
									<span v-if="message_admin" class="text-caption red--text text--accent-2 ml-4">
										{{ message_admin }}
									</span>
								</v-col>
							</v-row>
						</DefaultCard>
					</v-col>
				</v-row>
			</ValidationObserver>

			<v-overlay v-if="cropper_active" z-index="7" opacity="0.9">
				<div class="d-flex align-center flex-column">
					<cropper
						ref="cropper"
						:src="new_image_uncropped"
						:stencil-props="{
							aspectRatio: 1 / 1,
						}"
						:canvas="{
							minHeight: 0,
							minWidth: 0,
							maxHeight: 512,
							maxWidth: 512,
						}"
						style="max-height: 80vh; max-width: 60vw"
					/>
					<div class="d-flex mt-4">
						<Button color="cardBaseColor" class="mr-3" @click="cropper_active = false"> {{ $t("common.close") }} </Button>
						<Button @click="applyCropped"> {{ $t("common.apply") }} </Button>
					</div>
				</div>
			</v-overlay>
		</v-container>
	</Main>
</template>

<style scoped>
.formlabel {
	display: flex;
	align-items: center;
	min-height: 40px;
}

.message-alert {
	border: none !important;
	padding-top: 6px;
	padding-bottom: 6px;
	margin-bottom: 0;
}
.message-alert >>> .v-alert__wrapper i {
	margin-right: 10px;
}
</style>

<script>
import Sidebar from '../components/common/Sidebar';
import BackBar from '../components/common/BackBar';
import Main from '../components/common/Main';
import PageHeader from '../components/common/PageHeader';
import Button from '../components/common/elements/Button';
import Tab from '../components/common/elements/Tab';
import DefaultCard from '../components/common/DefaultCard.vue';
import FormField from '../components/common/elements/FormField.vue';
import { ValidationObserver } from 'vee-validate';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

import {
	gg_company_info,
	gg_update_company,
	gg_upload_image,
	gg_company_users,
	gg_company_admins,
	gg_add_admin,
	gg_remove_admin,
	GG_ENDPOINT,
} from '../api';

export default {
	name: 'CompanySettings',

	components: {
		Sidebar,
		BackBar,
		Main,
		PageHeader,
		Button,
		Tab,
		DefaultCard,
		FormField,
		ValidationObserver,
		Cropper,
	},

	data() {
		return {
			name: null,
			city: null,
			domain: null,
			bio: null,

			image_id: null,
			image: require(`@/assets/images/default-company.png`),
			new_image_uncropped: null,
			new_image_cropped: null,

			loading: false,
			loading_index: -1,
			message: null,
			message_admin: null,
			cropper_active: false,

			admins: [],
			users: [],
			endpoint: GG_ENDPOINT,
		};
	},

	async created() {
		try {
			const result = await gg_company_info();
			if (result) {
				this.name = result.name;
				this.city = result.city;
				this.domain = result.domain;
				this.bio = result.bio;
				this.image_id = result.image_id;
			}

			if (result.image_id) {
				this.image = GG_ENDPOINT + '/image/raw/' + result.image_id;
			}

			await this.fetchUsersAndAdmins();
		} catch (err) {
			console.log(err);
			this.message = {
				type: 'error',
				text: err,
			};
		}
	},

	methods: {
		async updateCompany() {
			this.reset();

			this.loading = true;

			var payload = {
				name: this.name,
				city: this.city,
				bio: this.bio,
				image_id: this.image_id,
			};

			try {
				if (this.new_image_cropped) {
					const image_result = await gg_upload_image(this.new_image_cropped);
					payload.image_id = image_result._id;
				}
				await gg_update_company(payload);
				this.message = {
					type: 'success',
					text: 'Changes saved',
				};
			} catch (err) {
				this.message = {
					type: 'error',
					text: err,
				};
			}

			this.loading = false;
		},

		async addAdmin(id) {
			this.reset();
			this.loading = true;
			this.loading_index = id;

			try {
				await gg_add_admin(id);
				this.$refs.select.blur();
				await this.fetchUsersAndAdmins();
			} catch (err) {
				this.message_admin = err;
			}

			this.loading = false;
		},

		async removeAdmin(id) {
			this.reset();
			this.loading = true;
			this.loading_index = id;

			try {
				await gg_remove_admin(id);
				await this.fetchUsersAndAdmins();
			} catch (err) {
				this.message_admin = err;
			}

			this.loading = false;
		},

		async fetchUsersAndAdmins() {
			try {
				this.users = await gg_company_users();
				this.admins = await gg_company_admins();

				// subtract admin users list from users list
				this.users = this.users.filter(u => !this.admins.map(a => a._id).includes(u._id));
			} catch (err) {
				this.message_admin = err;
			}
		},

		searchFilter(item, queryText, itemText) {
			const name = item.name.toLowerCase();
			const email = item.email.toLowerCase();
			const searchText = queryText.toLowerCase();

			return name.indexOf(searchText) > -1 || email.indexOf(searchText) > -1;
		},

		reset() {
			this.message = null;
			this.message_admin = null;
			this.loading = false;
			this.loading_index = -1;
		},

		cropperPreview(e) {
			const file = e.target.files[0];
			const reader = new FileReader();

			const that = this;
			reader.addEventListener(
				'load',
				function() {
					// convert image file to base64 string
					that.new_image_uncropped = reader.result;
				},
				false
			);

			if (file) {
				reader.readAsDataURL(file);
			}
		},

		applyCropped() {
			const { canvas } = this.$refs.cropper.getResult();
			this.image = canvas.toDataURL();

			const that = this;
			canvas.toBlob(function(blob) {
				that.new_image_cropped = blob;
			}, 'image/png');

			this.cropper_active = false;
		},
	},
};
</script>
