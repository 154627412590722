<template>
	<v-card flat color="primary" class="rounded-xl my-2">
		<v-row align="center" no-gutters>
			<v-col cols="3">
				<span class="filter text-subtitle-2 font-weight-bold ml-4" :class="activeSort('rank')" @click="sortBy('name')">
					<span class="hidden-xs-only">User</span>
					<span class="hidden-sm-and-up">User</span>
				</span>
			</v-col>
			<v-col cols="1">
				<span class="filter text-subtitle-2 font-weight-bold">
					<span class="hidden-xs-only">Activated</span>
					<span class="hidden-sm-and-up">Act</span>
				</span>
			</v-col>
			<v-col cols="8">
				<span class="filter text-subtitle-2 font-weight-bold">
					<span class="hidden-xs-only">Email</span>
					<span class="hidden-sm-and-up">Email</span>
				</span>
			</v-col>
    </v-row>
	</v-card>
</template>

<style scoped>
.filter {
	cursor: pointer;
	user-select: none;
}

.ascending::after {
	font-family: 'Material Design Icons';
	content: '\F0360';
	font-size: large;
	vertical-align: bottom;
}

.descending::after {
	font-family: 'Material Design Icons';
	content: '\F035D';
	font-size: large;
	vertical-align: bottom;
}
</style>

<script>
export default {
	props: ['value'],

	data() {
		return {
			sort: '',
			sort_ascending: false,
		};
	},

	created() {
		this.sort = 'name';
		this.$emit('input', { header: this.sort, direction: this.sort_ascending });
	},

	methods: {
		sortBy(header) {
			if (header !== this.sort) this.sort_ascending = true;
			else this.sort_ascending = !this.sort_ascending;
			this.sort = header;
			this.$emit('input', { header: this.sort, direction: this.sort_ascending });
		},
		activeSort(header) {
			return {
				ascending: header === this.sort && this.sort_ascending,
				descending: header === this.sort && !this.sort_ascending,
			};
		},
	},
};
</script>
