import gg_axios from '../gg_axios';

/**
 * Gets ladders the user is part of
 * @param {*} - OPTIONAL PARAM if `usder_id` returns users ladders else returns my ladder
 * @returns {array} player static information
 */
export default async function(user_id = null) {
	try {
		return await gg_axios('GET', user_id ? `/ladder/my/${user_id}` : `/ladder/my/`);
	} catch (error) {
		throw error;
	}
}
