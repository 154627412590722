<template>
	<Main background="fifa-background.png">
		<Sidebar />
		<v-container>
			<BackBar />
			<PageHeader :title="name" style="min-height: 36px">
        {{ $t("common.matches") }}
			</PageHeader>


      <MatchesHeader v-model="sort_matches" />
      <v-data-iterator
        :items="matches"
        :items-per-page="items_per_page"
        :page="page_matches"
        :length="length_matches"
        :search="search"
        :sort-by="sort_matches.header"
        :sort-desc="sort_matches.direction"
        hide-default-footer
        :no-data-text="$t('matches.no_matches')"
      >
        <template v-slot:default="{ items }">
          <MatchesRow v-for="item in items" :key="item._id" :data="item" />
        </template>
        <template v-slot:footer>
          <div class="text-center">
            <v-pagination v-model="page_matches" :length="length_matches"></v-pagination>
          </div>
        </template>
      </v-data-iterator>

      <TeamMatchesHeader v-model="sort_matches" />
      <v-data-iterator
          :items="teammatches"
          :items-per-page="items_per_page"
          :page="page_matches"
          :length="length_matches"
          :search="search"
          :sort-by="sort_matches.header"
          :sort-desc="sort_matches.direction"
          hide-default-footer
          :no-data-text="$t('matches.no_matches')"
      >
        <template v-slot:default="{ items }">
          <TeamMatchesRow v-for="item in items" :key="item._id" :data="item" />
        </template>
        <template v-slot:footer>
          <div class="text-center">
            <v-pagination v-model="page_matches" :length="length_matches"></v-pagination>
          </div>
        </template>
      </v-data-iterator>
		</v-container>
	</Main>
</template>

<script>
import Sidebar from '../components/common/Sidebar';
import BackBar from '../components/common/BackBar';
import Main from '../components/common/Main';
import PageHeader from '../components/common/PageHeader';
import MatchesRow from '../components/ladders/MatchesRow';
import MatchesHeader from '../components/ladders/MatchesHeader';

import { gg_matches_all } from '../api';
import TeamMatchesHeader from "@/components/teamladders/TeamMatchesHeader.vue";
import TeamMatchesRow from "@/components/teamladders/TeamMatchesRow.vue";

export default {
	name: 'Matches',

	components: {
    TeamMatchesHeader,
    TeamMatchesRow,
    MatchesHeader,
    MatchesRow,
		Sidebar,
		BackBar,
		Main,
		PageHeader,
	},

	data() {
		return {
			tab: null,
			message: null,
			name: null,
			search: '',
      matches: [],
      teammatches: [],
			page_matches: 1,
			length_matches: 0,
			items_per_page: 10,
			sort_matches: {},
		};
	},

	async created() {
		try {
      const matches = await gg_matches_all();
			if (matches) {
        matches.forEach(match => {
          if (match.players[0].user_id) {
            this.matches.push(match);
          } else {
            this.teammatches.push(match);
          }
        });
				this.length_matches = Math.ceil(matches.length / this.items_per_page);
			}
		} catch (err) {
			console.log(err);
			this.message = err;
		}
	},
};
</script>
