import gg_axios from '../gg_axios';


export default async function () {
    try {
        const result = await gg_axios('GET', `/social/feed`);

        return result;
    } catch (error) {
        throw error;
    }
}

