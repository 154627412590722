<template>
	<Main background="fifa-background.png">
		<div class="d-flex flex-column align-center justify-center fill-height">
			<v-icon x-large color="primary"> mdi-alert-circle-outline </v-icon>
			<span class="text-h6 font-weight-bold"> {{ $t("error_page_not_found") }} </span>
			<span class="text-caption"> {{ $t("error.page_not_exist") }} </span>
			<Button class="mt-4" to="/dashboard"> {{ $t("error.go_home") }} </Button>
		</div>
	</Main>
</template>

<script>
import Main from '../components/common/Main';
import Button from '../components/common/elements/Button';

export default {
	name: 'Error',

	components: {
		Main,
		Button,
	},
};
</script>