import gg_axios from '../gg_axios';

/**
 * Endpoint to request a user's info
 * @param {string=} user_id - If none passed returns own user information else returns info of the `_id` passed
 * @returns {object} user data
 */
async function gg_user_info(user_id = null) {
	try {
		return await gg_axios('GET', user_id ? `/user/user/${user_id}` : '/user');
	} catch (error) {
		throw new Error(error);
	}
}

export default gg_user_info;
