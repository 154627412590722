import gg_axios from '../gg_axios';

/**
 * Accept score of opponent for a match
 * @param {*} match_id
 * @returns {string} - IMPORTANT DISPLAY THE MESSAGE TO USER!!
 */
export default async function(match_id) {
    try {
        const result = await gg_axios('PUT', `/match/acceptscore`, {
            match_id,
        });

        return result;
    } catch (error) {
        throw error;
    }
}
