<template>
	<Main background="login-background.png">
		<v-container class="my-1" ref="introduction">
			<HomepageMenu v-on:go-section="goToSection" />
			<HomepageCard>
				<div style="margin-top:5px">
					<Button :to="{ name: 'Register' }">
						Click here to register!
					</Button>
				</div>

				<v-card color="white" class="rounded-xl mt-8 primary--text">
					<v-card-title>Introduction</v-card-title>
					<v-card-text class="primary--text text-body-1">
						Welcome to the esportsladder, the premier business and student esports competition. <br />
						<br />
						At the Esport Ladder, you can compete against players from other companies and with students in a wide range
						of popular esports titles. (The first ladder competition will start at december 15th)<br />
						<br />
						We offer a variety of tournaments and leagues for all skill levels, so no matter your experience or favorite
						game, there is something for everyone. You can take part in team-based and solo tournaments, complete with
						leaderboards and awards. Plus, with our easy-to-use matchmaking system, you can find opponents quickly and
						easily. So get ready to show off your skills and compete on the Esports Ladder! We can't wait to see you in
						the arena! <br />
					</v-card-text>
				</v-card>

				<v-card color="primary" class="rounded-xl mt-8 white--text">
					<v-card-title ref="ladder-competition">Ladder competition</v-card-title>
					<v-card-text class="white--text text-body-1">
						<!-- The row container -->
						<v-row>
							<!-- The first column -->
							<v-col cols="12" sm="8">
								<p>
									The esportsladder is organized via the ladder competition principle. A ladder competition is a type of
									tournament that involves players competing against each other in a set number of rounds. Players are
									placed in a ladder system and will play against opponents of similar skill levels. The goal of the
									ladder competition is to move up the ladder and eventually become the highest-ranking player. <br />
									<br />
									At the start of the tournament, players are assigned a rank based on their skill level. During each
									round, players will compete against opponents of a similar rank. If a player wins, they will earn
									points and move up the ladder. The higher a player moves on the ladder, the more challenging their
									opponents will be. <br />
									<br />
									At the end of the tournament, the player with the highest ranking will be declared the winner. The
									winner will receive a prize and bragging rights for their success. <br />
									<br />
									Ladder competitions are a great way for players of all skill levels to compete and have fun. They
									provide a structured way for players to test their skills and push themselves to become better. <br />
								</p>
							</v-col>

							<!-- The second column -->
							<v-col cols="12" sm="4">
								<p>
									<v-img src="https://gg.esportsladder.gg/stcimg/ladder_structure.png" max-width="468" /><br />
									Figure 1. Ladder competition structure<br />
								</p>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>

				<v-card color="white" class="rounded-xl mt-8 primary--text">
					<v-card-title
						><span ref="business-and-students">An esports competition for businesses and students</span></v-card-title
					>
					<v-card-text class="primary--text text-body-1">
						The esportsladder is a great way for businesses and students to interact and build relationships. Not only
						is it a fun activity, but it can also help build trust and create lasting connections. Businesses can
						benefit from interacting with students through the esportsladder by gaining insights into their interests
						and abilities, as well as expanding their network of contacts. Students can benefit from interacting with
						businesses through the esportsladder by developing their skills and networking with professionals who can
						help them in their future career endeavors. <br />
						<br />
						Business sports competitions <strong><i>within</i></strong> companies are becoming increasingly popular
						among companies and organizations of all sizes. They provide an excellent opportunity for employees to have
						fun, build relationships, and even strengthen their sense of team spirit. <br />
						<br />
						The benefits of business esports competitions go beyond gaming. Participating in such events encourages
						teamwork, communication, and collaboration among team members. It also fosters an understanding of the
						importance of working together to achieve success. Companies that can create an atmosphere of healthy
						competition are likely to be more successful in the long run.<br />
						<br />
						Furthermore, business esports competitions can be great for employee morale. This kind of event can help
						staff members to come together in a fun and friendly environment, which can be a great way to build
						relationships and foster a sense of community within the company. This can help employees to feel more
						motivated and connected to their workplace, which can lead to improved productivity and job satisfaction.
						<br />
						<br />
						Overall, business esports competitions are a great way to encourage teamwork, communication, and
						collaboration in the workplace. They can help to strengthen relationships among staff members, create a
						sense of camaraderie, and increase job satisfaction. Interacting through the esportladder can have a great
						impact on both businesses and students. It can help improve relationships, build trust, and create lasting
						connections.<br />
					</v-card-text>
				</v-card>

				<div style="margin-top:50px">
					<Button :to="{ name: 'Register' }">
						Click here to register!
					</Button>
				</div>
			</HomepageCard>
		</v-container>
	</Main>
</template>

<script>
import Main from '../components/common/Main';
import DefaultCard from '../components/common/DefaultCard.vue';
import Button from '../components/common/elements/Button';
import HomepageCard from '../components/homepage/HomepageCard.vue';
import HomepageMenu from '../components/homepage/HomepageMenu.vue';

export default {
	components: {
		Main,
		Button,
		HomepageCard,
		HomepageMenu,
	},
	methods: {
		goToSection(refName) {
			console.log(refName + ' in parent');
			var element = this.$refs[refName];
			console.log(element);
			const divOffset = this.offset(element);
			console.log(divOffset.top);

			window.scrollTo(0, divOffset.top - 50);
		},
		offset(el) {
			var rect = el.getBoundingClientRect(),
				scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
				scrollTop = window.pageYOffset || document.documentElement.scrollTop;
			return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
		},
	},
};
</script>
