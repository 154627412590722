<template>
	<DefaultCard :title="title" height="100%">
		<div v-if="!processed_matches.length" class="d-flex justify-center">
			<span class="text--disabled"> {{ none }} </span>
		</div>
		<div v-else>
			<div v-for="(item, index) in processed_matches" :key="index">
				<v-card
					:to="item.team_ladder ? { name: 'TeamManageMatches', params: { id: item.ladder_id, highlight_id: item.id } } : { name: 'ManageMatches', params: { id: item.ladder_id, highlight_id: item.id } }"
					flat
					color="background"
					class="rounded-xl my-2"
					style="overflow: hidden"
				>
					<div class="d-flex flex-column px-4 py-2">
						<div class="d-flex">
              <span class="text-caption primary--text"> {{ item.name }} </span>
              <span class="text-caption ml-auto"> {{ item.date }} {{ item.time }} </span>
            </div>
              <span class="font-weight-bold"> vs. {{ item.opponent }} </span>
					</div>
				</v-card>
			</div>
		</div>
	</DefaultCard>
</template>

<script>
import DefaultCard from '../common/DefaultCard';

import { gg_ladder_info, gg_user_info, gg_team_byid } from '../../api';

export default {
	name: 'Upcoming',

	components: {
		DefaultCard,
	},

	props: ['matches', 'title', 'none'],

	data() {
		return {
			processed_matches: [],
		};
	},

	watch: {
		matches: {
			immediate: true,
			async handler(newVal) {
				for (const m of newVal.slice(0, 2)) {
					this.processed_matches.push({
						id: m._id,
						ladder_id: m.ladder_id,
						name: await this.getLadderName(m.ladder_id),
						date: m.time ? this.getDate(m.time) : "Date: TBD",
						time: m.time ? this.getTime(m.time) : "",
						opponent: await this.getOpponentName(m),
            team_ladder: this.checkTeamLadder(m),
					});
				}
			},
		},
	},

	methods: {
		getDate(time) {
			var d = new Date(parseInt(time));
			return d.toLocaleDateString('nl-NL', {
				year: 'numeric',
				month: 'numeric',
				day: '2-digit',
			});
		},

		getTime(time) {
			var d = new Date(parseInt(time));
			return d.toLocaleTimeString('nl-NL', {
				hour: '2-digit',
				minute: '2-digit',
			});
		},

		async getOpponentName(match) {
			for (const player of match.players) {
        if (player.user_id) {
          const user = await gg_user_info(player.user_id)
          if (user._id.toString() !== this.$store.getters.getUserInfo._id.toString()) return user.name;
        } else {
          const team = await gg_team_byid(player.team_id)
          if (!(team.users.map((u) => u._id.toString())).includes(this.$store.getters.getUserInfo._id.toString())) return team.name;
        }
			}
		},

    checkTeamLadder(match) {
      for (const player of match.players) {
        if (player.team_id) return true;
      }
      return false;
    },

		async getLadderName(ladder_id) {
			try {
				const result = await gg_ladder_info(ladder_id);
				return result.name;
			} catch (err) {
				console.log(err);
				return '';
			}
		},
	},
};
</script>
