<template>
	<DefaultCard>
		<v-row no-gutters>
			<v-col cols="12" md="2" class="align-self-center">
				<div class="d-flex flex-column">
					<span class="text-h4 font-weight-bold"> {{ $t("stats.all") }} </span>
					<span class="text-h7 font-weight-bold"> {{ $t("stats.combined") }} </span>
				</div>
			</v-col>
			<v-col>
				<div class="d-md-flex justify-space-around flex-wrap">
					<div class="d-flex flex-column">
						<span class="text-h6 font-weight-bold"> {{ $t("common.my_ladders") }} </span>
						<span class="text-h3 font-weight-bold primary--text"> {{ stats.ladders }} </span>
					</div>
					<div class="d-flex flex-column">
						<span class="text-h6 font-weight-bold"> {{ $t("stats.wins") }} </span>
						<span class="text-h3 font-weight-bold primary--text"> {{ stats.wins }} </span>
					</div>
					<div class="d-flex flex-column">
						<span class="text-h6 font-weight-bold"> {{ $t("stats.losses") }} </span>
						<span class="text-h3 font-weight-bold primary--text"> {{ stats.losses }} </span>
					</div>
					<div class="d-flex flex-column">
						<span class="text-h6 font-weight-bold"> {{ $t("stats.draws") }} </span>
						<span class="text-h3 font-weight-bold primary--text"> {{ stats.draws }} </span>
					</div>
					<div class="d-flex flex-column">
						<span class="text-h6 font-weight-bold"> {{ $t("common.total") }} Score </span>
						<span class="text-h3 font-weight-bold primary--text"> {{ stats.elo }} </span>
					</div>
				</div>
			</v-col>
		</v-row>
	</DefaultCard>
</template>

<script>
import DefaultCard from '../common/DefaultCard';

export default {
	name: 'AllStats',

	components: {
		DefaultCard,
	},

	props: ['stats'],
};
</script>
