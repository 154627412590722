import gg_axios from '../gg_axios';

/**
 * Endpoint to request a copmany's info
 * @param {string=} company_id - If none passed returns own company information else returns info of the `_id` passed
 * @returns {object} copmany data
 */
async function gg_company_info(company_id = null) {
	try {
		return await gg_axios('GET', company_id ? `/company/${company_id}` : '/company');
	} catch (error) {
		throw new Error(error);
	}
}

export default gg_company_info;
