import gg_axios from '../gg_axios';

async function gg_team_request(team_id, user_id) {
    try {
        return await gg_axios('POST', `/team/request`, { team_id, user_id });
    } catch (error) {
        throw error;
    }
}

export default gg_team_request;