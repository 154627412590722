import gg_axios from '../gg_axios';

/**
 * Score a match (moves to `PLAYED` if only one player scored or inaccurate score | moves to `COMPLETED` if all players scored correctly)
 * @param {*} match_id
 * @param {number} my_goal
 * @param {number} opponent_goal
 * @param {boolean} penalty - is match was type of penalty
 * @param {boolean} details - details from Python 
 * @param {boolean} image_id - store image info
 * @returns {string} - IMPORTANT DISPLAY THE MESSAGE TO USER!!
 */
export default async function (match_id, my_goal, opponent_goal, penalty, image_id = null, details = null) {
	try {
		const result = await gg_axios('PUT', `/match/score`, {
			match_id,
			my_goal,
			opponent_goal,
			penalty,
			details,
			image_id
		});

		return result;
	} catch (error) {
		throw error;
	}
}
