<template>
	<validation-provider mode="passive" :rules="rules" v-slot="{ errors }">
		<v-text-field
			v-bind="$attrs"
			v-on="$listeners"
			filled
			rounded
			dense
			background-color="background"
			:id="id"
			:type="type"
			:value="value"
			@input="$emit('input', $event)"
			:error-messages="errors"
		></v-text-field>
	</validation-provider>
</template>

<script>
import { ValidationProvider, extend } from 'vee-validate';
import { required, email, digits, min, max, alpha_num } from 'vee-validate/dist/rules';

// Install validation rules and set error messages
extend('required', {
	...required,
	message: 'Required',
});
extend('email', {
	...email,
	message: 'Please enter a valid email',
});
extend('digits', {
	...digits,
	message: 'Please enter a valid phone number',
});
extend('min_phone', {
	...min,
	message: 'Please enter a valid phone number',
});
extend('max_phone', {
	...max,
	message: 'Please enter a valid phone number',
});
extend('alpha_num', {
	validate: value => {
		var strongRegex = new RegExp('^[a-zA-Z0-9 ]+$');
		return strongRegex.test(value);
	},
	message: 'Only alphanumeric characters and spaces are allowed',
});
extend('max_user', {
	...max,
	message: 'Your username is too long',
});
extend('min_password', {
	...min,
	message: 'Your password is too short (min 8 characters)',
});
extend('verify_password', {
	validate: value => {
		var strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\?_;,\-\^:])(?=.{8,})');
		return strongRegex.test(value);
	},
	message:
		'The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character (E.g. , . _ & ? etc)',
});

export default {
	components: {
		ValidationProvider,
	},
	props: ['id', 'type', 'value', 'rules'],
};
</script>
