import gg_axios from '../gg_axios';

/**
 * Create a post
 * @param {*} post_id - REQUIRED
 * @param {string} data - REQUIRED
 * @param {string} link - optional
 * @param {*} image_id - optional
 * @returns
 */
export default async function(ladder_id, data, link = null, image_id = null) {
	try {
		const result = await gg_axios('POST', `/social/post/`, {
			ladder_id,
			data,
			link,
			image_id,
		});

		return result;
	} catch (error) {
		throw error;
	}
}
