<template>
	<Main background="fifa-background.png">
		<Sidebar />
		<v-container>
			<PageHeader title="Social Feed" />
			<v-row no-gutters>
				<v-col cols="12">
					<CreatePost @action="updateFeed" />

					<v-data-iterator
						:items="posts"
						:items-per-page="-1"
						hide-default-footer
						:no-data-text="$t('feed.no_posts')"
					>
						<template v-slot:default="{ items }">
							<div v-for="item in items" :key="item._id">
								<Post :type="item.type" :data="item" @action="updateFeed" />
							</div>
						</template>
					</v-data-iterator>
				</v-col>
			</v-row>
		</v-container>
	</Main>
</template>

<script>
import Sidebar from '../components/common/Sidebar';
import Main from '../components/common/Main';
import PageHeader from '../components/common/PageHeader';
import CreatePost from '../components/social/CreatePost';
import Post from '../components/social/Post';

import { gg_social_feed } from '../api';

export default {
	name: 'SocialFeed',

	components: {
		Sidebar,
		Main,
		PageHeader,
		CreatePost,
		Post,
	},

	data() {
		return {
			posts: [],
			message: null,
		};
	},

	async created() {
		await this.updateFeed();
	},

	methods: {
		async updateFeed() {
			try {
				this.posts = await gg_social_feed();
			} catch (err) {
				console.log(err);
				this.message = {
					type: 'error',
					text: err,
				};
			}
		},
	},
};
</script>
