import gg_axios from '../gg_axios';

/**
 * Reject score of opponent for a match
 * @param {*} match_id
 * @returns {string} - IMPORTANT DISPLAY THE MESSAGE TO USER!!
 */
export default async function(match_id) {
	try {
		const result = await gg_axios('PUT', `/match/rejectscore`, {
			match_id,
		});

		return result;
	} catch (error) {
		throw error;
	}
}
