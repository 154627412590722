<template>
    <v-card flat color="cardBaseColor" class="rounded-xl" v-bind="$attrs">
        <v-card-text class="white--text">
            <div v-if="title" class="text-h6 font-weight-bold mb-4">{{ title }}</div>
            <slot></slot>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: ['title'],
}
</script>