import gg_axios from '../gg_axios';

/**
 * Request an email to reset forgotten password
 * @param {string} id - user id
 * @param {string} password - user new password
 * @param {string} hash - hash to prevent unauthorized password reset
 * @returns {object} Message informing password has been reset or not
 */
async function gg_reset_pass(id, password, hash) {
	try {
		return await gg_axios('PUT', `/auth/reset`, { id, password, hash });
	} catch (error) {
		throw error;
	}
}

export default gg_reset_pass;
