<template>
	<Main background="fifa-background.png">
		<Sidebar />
		<v-container>
			<BackBar />
			<PageHeader :title="name" style="min-height: 36px">
					Users
			</PageHeader>


						<UserHeader v-model="sort_users" />
						<v-data-iterator
							:items="users"
							:items-per-page="items_per_page"
							:page="page_users"
							:length="length_users"
							:search="search"
							:sort-by="sort_users.header"
							:sort-desc="sort_users.direction"
							hide-default-footer
							no-data-text="There are no users"
						>
							<template v-slot:default="{ items }">
								<UserRow v-for="item in items" :key="item._id" :data="item" />
							</template>
							<template v-slot:footer>
								<div class="text-center">
									<v-pagination v-model="page_users" :length="length_users"></v-pagination>
								</div>
							</template>
						</v-data-iterator>
		</v-container>
	</Main>
</template>

<script>
import Sidebar from '../components/common/Sidebar';
import BackBar from '../components/common/BackBar';
import Main from '../components/common/Main';
import PageHeader from '../components/common/PageHeader';
import UserHeader from "@/components/common/UserHeader.vue";
import UserRow from "@/components/common/UserRow.vue";

import { gg_user_info  } from '../api';

export default {
	name: 'Users',

	components: {
    UserRow,
    UserHeader,
		Sidebar,
		BackBar,
		Main,
		PageHeader,
	},

	data() {
		return {
			tab: null,
			message: null,
			name: null,
			search: '',
      users: [],
			page_users: 1,
			length_users: 0,
			items_per_page: 10,
			sort_users: {},
		};
	},

	async created() {
		try {
      const users = await gg_user_info();
			if (users) {
				this.users = users;
				this.length_users = Math.ceil(users.length / this.items_per_page);
			}
		} catch (err) {
			console.log(err);
			this.message = err;
		}
	},
};
</script>
