<template>
	<v-card flat color="cardBaseColor" class="rounded-xl my-2" style="overflow: hidden">
		<v-row align="center" no-gutters style="min-height: 44px">
			<v-col
				class="d-flex justify-center align-center align-self-stretch"
				cols="1"
				style="background-color: var(--v-primary-base)"
			>
				<span class="text-h6 font-weight-bold"> {{ data.rank }} </span>
			</v-col>

			<v-col class="d-flex justify-center" cols="1">
				<router-link :to="{ name: 'PlayerProfile', params: { id: data.user_id._id } }">
					<v-avatar color="black" size="32">
						<v-img
							:src="
								data.user_id.image_id
									? endpoint + '/image/raw/' + data.user_id.image_id
									: require(`@/assets/images/default-avatar.png`)
							"
						/>
					</v-avatar>
				</router-link>
			</v-col>

			<v-col class="d-flex flex-column" cols="5">
        <router-link :to="{ name: 'PlayerProfile', params: { id: data.user_id._id } }">
				  <span class="text-h7 font-weight-bold" style="color: white"> {{ data.user_id.name }} </span>
        </router-link>
        <router-link :to="{ name: 'CompanyProfile', params: { id: data.user_id.company_id } }">
				  <span class="text-caption" style="color: white"> {{ data.user_id.company_id.name }} </span>
        </router-link>
			</v-col>

			<v-col class="d-flex justify-center">
				<span class="text-h6 font-weight-bold"> {{ data.matches }} </span>
			</v-col>

			<v-col class="d-flex justify-center">
				<span class="text-h6 font-weight-bold"> {{ data.wins }} </span>
			</v-col>

			<v-col class="d-flex justify-center">
				<span class="text-h6 font-weight-bold"> {{ data.losses }} </span>
			</v-col>

			<v-col class="d-flex justify-center">
				<span class="text-h6 font-weight-bold"> {{ data.draws }} </span>
			</v-col>

			<v-col class="d-flex justify-center">
				<span class="text-h6 font-weight-bold primary--text"> {{ Math.round(data.elo) }} </span>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
import { GG_ENDPOINT } from '@/api';

export default {
	props: ['data'],

	data() {
		return {
			endpoint: GG_ENDPOINT,
		};
	},
};
</script>