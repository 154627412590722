import gg_axios from '../gg_axios';

async function gg_team_remove_user(team_id, user_id) {
    try {
        const result = await gg_axios('DELETE', `/team/user`, { team_id, user_id });
        return result;
    } catch (error) {
        throw error;
    }
}

export default gg_team_remove_user;
