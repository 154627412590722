import gg_axios from '../gg_axios';

/**
 * Get all matches for super user
 * @returns {Array}
 */
export default async function() {
	try {
		const result = await gg_axios('GET', `/matches/all`);

		return result;
	} catch (error) {
		throw error;
	}
}
