import gg_axios from '../gg_axios';

/**
 * Obtain players of a ladder
 * @param {string} ladder_id
 * @returns {Array} an array of static player informaiton
 */
async function gg_teamladder_teams(ladder_id) {
    try {
        const result = await gg_axios('GET', `/teamladder/teams/${ladder_id}`);
        return result;
    } catch (error) {
        throw error;
    }
}

export default gg_teamladder_teams;
