<template>
	<div>
		<v-card flat color="cardBaseColor" class="rounded-xl my-2" style="overflow: hidden; z-index: 1">
			<div :class="{ highlight: highlight }">
				<v-row align="center" no-gutters>
					<v-col
						class="d-flex flex-column justify-center align-center align-self-stretch"
						cols="2"
						md="1"
						style="background-color: var(--v-primary-base)"
					>
						<span class="text-subtitle-2 font-weight-bold"> {{ getDate(data.time) }} </span>
						<span v-if="type === 'completed' || type === 'upload'" class="text-caption">
							{{ getTime(data.time) }}
						</span>
					</v-col>

					<v-col class="d-flex justify-center" cols="1">
						<router-link :to="{ name: 'PlayerProfile', params: { id: data.players[0].user_id._id } }">
							<v-avatar color="black" size="32">
								<v-img
									:src="
										data.players[0].user_id.image_id
											? endpoint + '/image/raw/' + data.players[0].user_id.image_id
											: require(`@/assets/images/default-avatar.png`)
									"
								/>
							</v-avatar>
						</router-link>
					</v-col>

					<v-col class="d-none d-sm-flex flex-column" cols="3">
						<router-link :to="{ name: 'PlayerProfile', params: { id: data.players[0].user_id._id } }">
							<div class="d-flex">
								<span class="text-subtitle-1 font-weight-bold" style="color: white;">
									{{ data.players[0].user_id.name }}
								</span>
								<v-tooltip right v-if="left_tooltip.message">
									<template v-slot:activator="{ on, attrs }">
										<v-icon small color="primary" class="ml-2" v-bind="attrs" v-on="on">
											{{ left_tooltip.icon }}
										</v-icon>
									</template>
									<span> {{ left_tooltip.message }} </span>
								</v-tooltip>
							</div>
						</router-link>
						<router-link :to="{ name: 'CompanyProfile', params: { id: data.players[0].user_id.company_id } }">
							<span class="text-caption" style="color: white;"> {{ data.players[0].user_id.company_id.name }} </span>
						</router-link>
					</v-col>

					<v-col class="d-flex flex-column align-center">
						<span class="text-caption" style="line-height: 0.75rem"> {{ $t("common.rank") }} </span>
						<span class="text-h6 font-weight-bold">
							{{
								data.players[0].rank
							}}
						</span>
					</v-col>

					<v-col class="d-flex flex-column align-center">
						<span v-if="type === 'planned' || type === 'planned-prompt'" class="text-h6 font-weight-bold">
							{{ getTime(data.time) }}
						</span>
            <v-menu
                ref="menu"
                v-if="type === 'planned' && changed === false"
                v-model="datePicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                :nudge-left="150"
                min-width="auto"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                    elevation="0"
                    class="rounded-pill align-center"
                    color="blue"
                    height="20px"
                    :disabled="loading"
                    v-on="on"
                >
                  {{ $t("matches.change") }}
                </v-btn>
              </template>
              <v-date-picker
                  v-if="!timePicker"
                  v-model="date_selected"
                  color="primary"
                  @change="timePicker = true"
              ></v-date-picker>
              <v-time-picker
                  v-if="timePicker"
                  v-model="time_selected"
                  color="primary"
                  format="24hr"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="$refs.menu.save(time_selected); timePicker=false; changed=true; saveMatchTime();">
                  OK
                </v-btn>
              </v-time-picker>
            </v-menu>

						<span v-if="type === 'cancelled'" class="text-caption" style="line-height: 0.75rem"> {{ $t("common.cancelled") }} </span>
						<span
							v-if="type === 'cancelled'"
							class="text-h6 font-weight-bold"
							style="text-decoration: line-through; opacity: 0.4"
						>
							{{ getTime(data.time) }}
						</span>

            <span v-if="scored" class="text-caption">
              {{ data.players[0].stats ? data.players[0].stats.my_goal : '?' }}-{{
                data.players[1].stats ? data.players[1].stats.my_goal : '?'
              }}
            </span>

						<span v-if="type === 'completed'" class="text-caption" style="line-height: 0.75rem"> {{ $t("common.score") }} </span>
						<span v-if="type === 'completed'" class="text-h6 font-weight-bold">
							{{ data.players[0].stats ? data.players[0].stats.my_goal : '?' }}-{{
								data.players[1].stats ? data.players[1].stats.my_goal : '?'
							}}
						</span>

						<Button
							v-else-if="type === 'upload' && data.last_scored !== $store.getters.getUserInfo._id"
							height="20"
							:to="{ name: 'Upload', params: { id: data._id, match_info: getMatchInfo() } }"
						>
              <template v-if="scored">
                {{ $t("matches.object") }}
              </template>
              <template v-else-if="reupload">
                {{ $t("matches.reupload") }}
              </template>
              <template v-else>
                {{ $t("matches.upload") }}
              </template>
						</Button>
            <Button
              v-if="scored && data.last_scored !== $store.getters.getUserInfo._id"
              height="20"
              @click="acceptScore(data._id)"
              class="mt-1"
              color="green"
            >
              {{ $t("matches.accept_score") }}
            </Button>
					</v-col>

					<v-col class="d-flex flex-column align-center">
						<span class="text-caption" style="line-height: 0.75rem"> {{ $t("common.rank") }} </span>
						<span class="text-h6 font-weight-bold">
							{{
								data.players[1].rank
							}}
						</span>
					</v-col>

					<v-col class="d-none d-sm-flex flex-column align-end" cols="3">
						<router-link :to="{ name: 'PlayerProfile', params: { id: data.players[1].user_id._id } }">
							<div class="d-flex">
								<v-tooltip left v-if="right_tooltip.message">
									<template v-slot:activator="{ on, attrs }">
										<v-icon small color="primary" class="mr-2" v-bind="attrs" v-on="on">
											{{ right_tooltip.icon }}
										</v-icon>
									</template>
									<span> {{ right_tooltip.message }} </span>
								</v-tooltip>
								<span class="text-subtitle-1 font-weight-bold" style="color: white;">
									{{ data.players[1].user_id.name }}
								</span>
							</div>
						</router-link>
						<router-link :to="{ name: 'CompanyProfile', params: { id: data.players[1].user_id.company_id } }">
							<span class="text-caption" style="color: white;"> {{ data.players[1].user_id.company_id.name }} </span>
						</router-link>
					</v-col>

					<v-col class="d-flex justify-center" cols="1">
						<router-link :to="{ name: 'PlayerProfile', params: { id: data.players[1].user_id._id } }">
							<v-avatar color="black" size="32">
								<v-img
									:src="
										data.players[1].user_id.image_id
											? endpoint + '/image/raw/' + data.players[1].user_id.image_id
											: require(`@/assets/images/default-avatar.png`)
									"
								/>
							</v-avatar>
						</router-link>
					</v-col>
				</v-row>
			</div>
		</v-card>
		<v-card
			v-if="type === 'planned-prompt'"
			flat
			class="rounded-b-xl rounded-t-0 mt-n8"
			color="primary"
			style="z-index: 0"
		>
			<div class="d-flex align-center pt-7 pb-1 px-6">
				<span class="text-caption"> {{ $t("matches.invited_to_play") }} </span>
        <v-btn
            v-if="typeof data.time === 'undefined'"
            elevation="0"
            class="rounded-pill mr-2 ml-auto"
            color="errorColor"
            height="20px"
            :disabled="loading"
            @click="denyMatch(data._id)"
        >
          {{ $t("common.decline") }}
        </v-btn>
        <v-menu
            ref="menu"
            v-model="datePicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            :nudge-left="150"
            min-width="auto"
        >
          <template v-slot:activator="{ on }">
            <v-btn
                v-if="typeof data.time === 'undefined'"
                elevation="0"
                class="rounded-pill mr-2"
                color="blue"
                height="20px"
                :disabled="loading"
                v-on="on"
            >
              {{ $t("matches.set_time_date") }}
            </v-btn>
          </template>
          <v-date-picker
              v-if="!timePicker"
              v-model="date_selected"
              color="primary"
              @change="timePicker = true"
          ></v-date-picker>
          <v-time-picker
              v-if="timePicker"
              v-model="time_selected"
              color="primary"
              format="24hr"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="$refs.menu.save(time_selected); timePicker=false; saveMatchTime();">
              OK
            </v-btn>
          </v-time-picker>
        </v-menu>

				<v-btn
            v-if="typeof data.time !== 'undefined'"
					elevation="0"
					class="rounded-pill mr-2 ml-auto"
					color="errorColor"
					height="20px"
					:disabled="loading"
					@click="denyMatch(data._id)"
				>
          {{ $t("common.decline") }}
				</v-btn>
				<v-btn
            v-if="typeof data.time !== 'undefined'"
					elevation="0"
					class="rounded-pill"
					color="#1BBE5C"
					height="20px"
					:disabled="loading"
					@click="acceptMatch(data._id)"
				>
          {{ $t("common.accept") }}
				</v-btn>
			</div>
		</v-card>
	</div>
</template>

<style scoped>
.highlight {
	animation: flash 1500ms ease-out;
}

@keyframes flash {
	from {
		background-color: gray;
	}
}
</style>

<script>
import { GG_ENDPOINT, gg_accept_match, gg_deny_match, gg_update_match_time, gg_accept_score_match } from '../../api';
import Button from '../common/elements/Button';

export default {
	components: {
		Button,
	},

	props: ['data', 'ranks', 'highlight'],

	data() {
		return {
			endpoint: GG_ENDPOINT,
			loading: false,
			errormessage: null,
			type: null,
			left_tooltip: {},
			right_tooltip: {},
			reupload: false,
      datePicker: false,
      timePicker: false,
      dateSet: null,
      timeSet: null,
      scored: false,
      changed: false,
		};
	},

	watch: {
		data: {
			immediate: true,
			handler(newVal) {
				// console.log(JSON.stringify(newVal));
				// determine type of card to display
				if (newVal.status === 'COMPLETED') {
					this.type = 'completed';
				} else if (
					newVal.status === 'PLAYED' ||
					(newVal.time < Date.now() && newVal.status !== 'PLANNING' && newVal.status !== 'CANCELLED')
				) {
					this.type = 'completed';
					for (const player of newVal.players) {
						if (player.user_id._id === this.$store.getters.getUserInfo._id && player.scored) this.reupload = true;
					}
					if (newVal.status !== 'COMPLETED') this.type = 'upload';
				} else if (newVal.status === 'PLANNED') {
					this.type = 'planned';
				} else if (newVal.status === 'PLANNING') {
					this.type = 'planned';
					for (const player of newVal.players) {
						if (player.user_id._id === this.$store.getters.getUserInfo._id && !player.accepted)
							this.type = 'planned-prompt';
					}
				} else {
					this.type = 'cancelled';
				}
        if (newVal.status === 'SCORED') {
          this.scored = true;
        }
				// determine tooltip text
				var left_check, right_check, append_text;
				if (this.type === 'completed' || this.type === 'upload') {
					this.left_tooltip.icon = this.right_tooltip.icon = 'mdi-account-clock';
					left_check = !newVal.players[0].scored;
					right_check = !newVal.players[1].scored;
					append_text = ' not uploaded the score for this match yet';
				} else if (this.type === 'planned') {
					this.left_tooltip.icon = this.right_tooltip.icon = 'mdi-account-clock';
					left_check = !newVal.players[0].accepted;
					right_check = !newVal.players[1].accepted;
					append_text = ' not accepted this match yet';
				} else if (this.type === 'cancelled') {
					this.left_tooltip.icon = this.right_tooltip.icon = 'mdi-cancel';
					left_check = !newVal.players[0].accepted;
					right_check = !newVal.players[1].accepted;
					append_text = ' declined this match';
				}
				if (left_check)
					this.left_tooltip.message =
						(newVal.players[0].user_id._id === this.$store.getters.getUserInfo._id
							? 'You have'
							: newVal.players[0].user_id.name + ' has') + append_text;
				if (right_check)
					this.right_tooltip.message =
						(newVal.players[1].user_id._id === this.$store.getters.getUserInfo._id
							? 'You have'
							: newVal.players[1].user_id.name + ' has') + append_text;
			},
		},
	},

	methods: {
		getDate(time) {
      if(typeof time === 'undefined') {
        return 'TBD'
      }

			var d = new Date(parseInt(time));
			let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
			let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
			return `${da}-${mo}`;
		},

		getTime(time) {
      if(typeof time === 'undefined') {
        return '--:--'
      }

			var d = new Date(parseInt(time));
			return d.toLocaleTimeString(undefined, {
				hour12: false,
				hour: '2-digit',
				minute: '2-digit',
			});
		},

		async acceptMatch(id) {
			this.loading = true;

			try {
				await gg_accept_match(id);
			} catch (err) {
				this.errormessage = err;
			}

			this.loading = false;
			this.$emit('action');
		},

		async denyMatch(id) {
			this.loading = true;

			try {
				await gg_deny_match(id);
			} catch (err) {
				this.errormessage = err;
			}

			this.loading = false;
			this.$emit('action');
		},

    async acceptScore(id) {
      this.loading = true;

      try {
        await gg_accept_score_match(id);
      } catch (err) {
        this.errormessage = err;
      }

      this.loading = false;
      this.$emit('action');
      this.$router.replace({
        query: { tab: 'completed' },
      });
    },

		getMatchInfo() {
			return {
				ladder_id: this.data.ladder_id,
        status: this.data.status,
				players: this.data.players,
				date: this.getDate(this.data.time),
				time: this.getTime(this.data.time),
			};
		},

    async saveMatchTime() {
      const timestamp = Date.parse(this.dateSet + ' ' + this.timeSet);

      this.loading = true;

      try {
        await gg_update_match_time(this.data._id, timestamp)
      } catch (err) {
        this.errormessage = err;
      }

      this.loading = false;
      this.timeSet = null;
      this.dateSet = null;

      this.$emit('action');
    },

	},

  computed: {
    date_selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.dateSet = value;
        this.$emit('input', value);
      },
    },
    time_selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.timeSet = value;
        this.$emit('input', value);
      },
    },
  },
};
</script>
