<template>
	<Main background="fifa-background.png">
		<Sidebar />
		<PageHeader left="true">
			<span class="text-h4 font-weight-bold primary--text"> {{ $t("matches.information") }} {{ ladder.name }} ladder </span>
		</PageHeader>
		<v-container>
			<PlannedMatchHeader />
			<v-card flat color="cardBaseColor" class="rounded-xl my-2" style="overflow: hidden; z-index: 1">
				<div>
					<v-row align="center" no-gutters>
						<v-col
							class="d-flex flex-column justify-center align-center align-self-stretch"
							cols="1"
							style="background-color: var(--v-primary-base)"
						>
							<span class="text-subtitle-2 font-weight-bold"> {{ parsed_date }} </span>
						</v-col>

						<v-col class="d-flex justify-center" cols="1">
							<router-link :to="{ name: 'PlayerProfile', params: { id: user1._id } }">
								<v-avatar color="black" size="32">
									<v-img
										:src="
											user1.image_id
												? endpoint + '/image/raw/' + user1.image_id
												: require(`@/assets/images/default-avatar.png`)
										"
									/>
								</v-avatar>
							</router-link>
						</v-col>

						<v-col class="d-flex flex-column" cols="3">
							<router-link
								style="text-decoration: none; color: inherit;"
								:to="{ name: 'PlayerProfile', params: { id: user1._id } }"
							>
								<div class="d-flex">
									<span class="text-subtitle-1 font-weight-bold"> {{ user1.name }} </span>
								</div>
							</router-link>
							<router-link
								style="text-decoration: none; color: inherit;"
								:to="{ name: 'CompanyProfile', params: { id: company2._id } }"
							>
								<span> {{ company1.name }} </span>
							</router-link>
						</v-col>

						<v-col class="d-flex flex-column align-center" cols="3">
							<span class="text-h6 font-weight-bold">
								{{ parsed_time }}
							</span>
						</v-col>

						<v-col class="d-flex flex-column align-end" cols="3">
							<router-link
								style="text-decoration: none; color: inherit;"
								:to="{ name: 'PlayerProfile', params: { id: user2._id } }"
							>
								<div class="d-flex">
									<span class="text-subtitle-1 font-weight-bold"> {{ user2.name }} </span>
								</div>
							</router-link>
							<router-link
								style="text-decoration: none; color: inherit;"
								:to="{ name: 'CompanyProfile', params: { id: company2._id } }"
							>
								<span> {{ company2.name }} </span>
							</router-link>
						</v-col>

						<v-col class="d-flex justify-center" cols="1">
							<router-link :to="{ name: 'PlayerProfile', params: { id: user2._id } }">
								<v-avatar color="black" size="32">
									<v-img
										:src="
											user2.image_id
												? endpoint + '/image/raw/' + user2.image_id
												: require(`@/assets/images/default-avatar.png`)
										"
									/>
								</v-avatar>
							</router-link>
						</v-col>
					</v-row>
				</div>
			</v-card>
			<Button :to="{ name: 'ManageMatches', params: { id: match.ladder_id } }" class="ml-auto mt-2">
        {{ $t("ladders.manage") }}
			</Button>
		</v-container>
	</Main>
</template>

<script>
import Sidebar from '../components/common/Sidebar';
import PageHeader from '../components/common/PageHeader';
import Button from '../components/common/elements/Button';
import Main from '../components/common/Main';
import MatchesRow from '../components/ladders/MatchesRow';
import PlannedMatchHeader from '../components/sms/MatchInfoHeader.vue';
import { GG_ENDPOINT, gg_user_info, gg_get_match, gg_company_info, gg_ladder_info } from '../api';
export default {
	methods: {
		getDate(time) {
			var d = new Date(parseInt(time));
			// console.log(time);
			let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
			let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
			return `${da}-${mo}`;
		},

		getTime(time) {
			var d = new Date(parseInt(time));
			return d.toLocaleTimeString(undefined, {
				hour12: false,
				hour: '2-digit',
				minute: '2-digit',
			});
		},
	},

	data() {
		return {
			endpoint: GG_ENDPOINT,
			match: {},
			ladder: {},
			players: {},
			user1: {},
			user2: {},
			match_time: {},
			parsed_time: {},
			parsed_date: {},
			company1: {},
			company2: {},
		};
	},

	async created() {
		try {
			this.match = await gg_get_match(this.$route.params.id);
			this.ladder = await gg_ladder_info(this.match.ladder_id);
			this.players = this.match.players;
			this.user1 = await gg_user_info(this.players[0].user_id);
			this.user2 = await gg_user_info(this.players[1].user_id);
			this.company1 = await gg_company_info(this.user1.company_id);
			this.company2 = await gg_company_info(this.user2.company_id);
			this.match_time = this.match.time;
			this.parsed_time = this.getTime(this.match.time);
			this.parsed_date = this.getDate(this.match.time);
		} catch (err) {
			console.log(err);
		}
	},

	name: 'MatchInfo',

	components: {
		Sidebar,
		PageHeader,
		Button,
		Main,
		PlannedMatchHeader,
		MatchesRow,
	},
};
</script>
