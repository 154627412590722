import gg_axios from '../gg_axios';

async function gg_upload_image(file) {
    try {
        let formData = new FormData();
        formData.append('file', file);

        const result = await gg_axios(
            'POST',
            `/image/upload`,
            formData,
            'multipart/form-data;'
        );

        return result;
    } catch (error) {
        throw error;
    }
}

export default gg_upload_image;