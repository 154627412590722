<template>
	<v-card flat color="cardBaseColor" class="rounded-xl my-2" style="overflow: hidden">
		<v-row align="center" no-gutters style="min-height: 44px">

			<v-col class="d-flex flex-column pl-2" cols="3">
				<span class="text-h7 font-weight-bold"> {{ data.name }} </span>
			</v-col>

			<v-col class="d-flex flex-column" cols="1">
				<span class="text-h7 font-weight-bold"> {{ data.activated }} </span>
			</v-col>

			<v-col class="d-flex flex-column" cols="8">
				<span class="text-h7 font-weight-bold"> {{ data.email }} </span>
			</v-col>

		</v-row>
	</v-card>
</template>

<script>
import { GG_ENDPOINT } from '../../api';

export default {
	props: ['data'],

	data() {
		return {
			endpoint: GG_ENDPOINT,
		};
	},
};
</script>
