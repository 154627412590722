<template>
	<Main background="login-background.png">
		<v-row class="px-1 fill-height justify-end" no-gutter>
			<v-col cols="12" sm="8" md="5" align-self="center">
				<p class="text-h4 font-weight-bold text-center">Reset password</p>
				<v-card flat class="rounded-xl" color="cardBaseColor" style="z-index: 1">
					<v-card-text class="px-8 py-8">
						<ValidationObserver v-slot="{ handleSubmit }">
							<v-form @submit.prevent="handleSubmit(resetPassword)">
								<div>
									<div>
										<label for="password" class="ml-6"> Please enter a new password </label>
										<FormField
											id="password"
											type="password"
											v-model="password"
											rules="required|min_password:8|verify_password"
										/>
									</div>
									<v-btn
										color="primary"
										rounded
										block
										depressed
										:loading="loading"
										:disabled="loading"
										:error-messages="errormessage"
										type="submit"
									>
										{{ 'Submit' }}
									</v-btn>
									<span v-if="errormessage" class="text-caption red--text text--accent-2"> {{ errormessage }} </span>
									<span v-if="successmessage" class="text-caption green--text text--accent-2">
										{{ successmessage.text }} <a href="/#/login"> Login </a>
									</span>
								</div>
							</v-form>
						</ValidationObserver>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</Main>
</template>

<style scoped>
.message-alert {
	border: none !important;
	padding-top: 6px;
	padding-bottom: 6px;
	margin-bottom: 0;
}
.message-alert >>> .v-alert__wrapper i {
	margin-right: 10px;
}
</style>

<script>
import { ValidationObserver } from 'vee-validate';
import FormField from '../components/common/elements/FormField';
import Main from '../components/common/Main';

import { gg_reset_pass } from '../api';

export default {
	components: {
		ValidationObserver,
		FormField,
		Main,
	},

	data() {
		return {
			id: this.$route.params.id,
			resettoken: this.$route.params.resettoken,
			password: null,
			errormessage: null,
			loading: false,
			successmessage: null,
		};
	},

	methods: {
		async resetPassword() {
			this.reset();

			this.loading = true;

			try {
				const result = await gg_reset_pass(this.id, this.password, this.resettoken);

				this.successmessage = {
					type: 'success',
					text: 'Your password has been reset. You can now continue to login.',
				};
			} catch (err) {
				this.errormessage = err;
			}

			this.loading = false;
		},

		reset() {
			this.errormessage = null;
			this.loading = false;
		},
	},
};
</script>
