import gg_axios from '../gg_axios';

async function gg_update_email(email) {
	try {
		const result = await gg_axios('PUT', '/user/email', { email });

		return result;
	} catch (error) {
		throw error;
	}
}

export default gg_update_email;
