import gg_axios from '../gg_axios';

/**
 * Get all matches of a ladder
 * @param {string} ladder_id
 * @returns {Array}
 */
export default async function(ladder_id) {
	try {
		const result = await gg_axios('GET', `/match/all/${ladder_id}`);

		return result;
	} catch (error) {
		throw error;
	}
}
