import gg_axios from '../gg_axios';

async function gg_team_my(user_id = null) {
	try {
		return await gg_axios('GET', user_id ? `/team/my/${user_id}` : `/team/my/`);
	} catch (error) {
		throw error;
	}
}

export default gg_team_my;