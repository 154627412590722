import gg_axios from '../gg_axios';

async function gg_remove_admin(id) {
    try {
        const result = await gg_axios('PUT', `/company/admin/remove/${id}`);

        return result;
    } catch (error) {
        throw error;
    }
}

export default gg_remove_admin;