import gg_axios from '../gg_axios';

/**
 * My matches for a specific ladder
 * @param {*} ladder_id
 * @returns
 */
export default async function(ladder_id) {
	try {
		const result = await gg_axios('GET', `/match/my/${ladder_id}`);

		return result;
	} catch (error) {
		throw error;
	}
}
