<template>
	<v-app>
		<v-theme-provider root>
			<router-view></router-view>
		</v-theme-provider>
	</v-app>
</template>

<script>
export default {
	name: 'App',
};
</script>
