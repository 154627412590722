<template>
  <Main v-if="$store.getters.getUserInfo._id === creator" background="fifa-background.png">
    <Sidebar />
    <v-container>
      <BackBar />
      <PageHeader :title="team.name" style="min-height: 36px"/>
      <div>
        <span class="text-h5 font-weight-bold primary--text"> {{ $t("team.manage.title") }} </span>
      </div>

      <v-col cols="12">
        <DefaultCard title="Current team members">
          <v-row no-gutters>
            <v-col cols="12" lg="10">

              <v-list class="rounded-xl" style="background-color: var(--v-background-base); overflow: hidden">
                <v-row no-gutters class="px-4 text-caption grey--text">
                  <v-spacer />
                  <v-col cols="3">
                    <span> User name </span>
                  </v-col>
                  <v-col cols="8">
                    <span> {{ $t("common.email") }} </span>
                  </v-col>
                </v-row>
                <v-list-item v-for="(item, i) in team_members" :key="i" ripple>
                  <v-row no-gutters align="center">
                    <v-col cols="1">
                      <v-avatar color="black" size="32">
                        <v-img
                            :src="
                                  item.image_id
                                    ? endpoint + '/image/raw/' + item.image_id
                                    : require(`@/assets/images/default-avatar.png`)
                                "
                        />
                      </v-avatar>
                    </v-col>
                    <v-col cols="3">
                      <span> {{ item.name }} </span>
                    </v-col>
                    <v-col>
                      <span> {{ item.email }} </span>
                    </v-col>
                    <v-btn
                        v-if="item._id !== creator"
                        elevation="0"
                        class="rounded-pill"
                        color="red"
                        height="20px"
                        @click="removeUser(item._id)"
                    >
                      {{ $t("team.manage.remove") }}
                    </v-btn>
                  </v-row>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </DefaultCard>
      </v-col>

      <v-col cols="12">
        <DefaultCard title="Join requests">
          <v-row no-gutters>
            <v-col cols="12" lg="10">

              <v-list class="rounded-xl" style="background-color: var(--v-background-base); overflow: hidden">
                <v-row no-gutters class="px-4 text-caption grey--text">
                  <v-spacer />
                  <v-col cols="3">
                    <span> User name </span>
                  </v-col>
                  <v-col cols="8">
                    <span> {{ $t("common.email") }} </span>
                  </v-col>
                </v-row>
                <v-list-item v-for="(item, i) in requests_users" :key="i" ripple>
                  <v-row no-gutters align="center">
                    <v-col cols="1">
                      <v-avatar color="black" size="32">
                        <v-img
                            :src="
                                  item.image_id
                                    ? endpoint + '/image/raw/' + item.image_id
                                    : require(`@/assets/images/default-avatar.png`)
                                "
                        />
                      </v-avatar>
                    </v-col>
                    <v-col cols="3">
                      <span> {{ item.name }} </span>
                    </v-col>
                    <v-col>
                      <span> {{ item.email }} </span>
                    </v-col>
                    <v-btn
                        elevation="0"
                        class="rounded-pill ml-auto mr-0"
                        color="green"
                        height="20px"
                        @click="acceptRequest(item._id)"
                    >
                      {{ $t("team.manage.accept") }}
                    </v-btn>
                    <v-btn
                        elevation="0"
                        class="rounded-pill mr-1 ml-2"
                        color="red"
                        height="20px"
                        @click="declineRequest(item._id)"
                    >
                      {{ $t("team.manage.decline") }}
                    </v-btn>
                  </v-row>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </DefaultCard>
      </v-col>

    </v-container>
  </Main>
</template>

<script>
import Sidebar from "../components/common/Sidebar.vue";
import Main from "../components/common/Main.vue";
import DefaultCard from "../components/common/DefaultCard.vue";
import BackBar from "../components/common/BackBar.vue";
import PageHeader from "../components/common/PageHeader.vue";
import {gg_team_byid, gg_user_info, gg_accept_request, gg_decline_request, gg_team_remove_user} from "../api";

export default {
  name: "TeamManage",
  components: {
    Sidebar,
    Main,
    DefaultCard,
    BackBar,
    PageHeader,
  },
  data() {
    return {
      team: null,
      creator: null,
      team_members: [],
      join_requests: [],
      requests_users: [],
    };
  },

  async created() {
    await this.fetchData();
  },

  methods: {
    async fetchData() {
      this.team = await gg_team_byid(this.$route.params.id);
      this.creator = this.team.creator._id;
      this.team_members = this.team.users;
      this.join_requests = this.team.requests;

      if (this.join_requests.length > 0) {
        for (let i = 0; i < this.join_requests.length; i++) {
          let user = await gg_user_info(this.join_requests[i]);
          this.requests_users.push(user);
        }
      } else {
        this.requests_users = [];
      }
    },

    async acceptRequest(user_id) {
      await gg_accept_request(this.team._id, user_id);
      await this.fetchData();
    },
    async declineRequest(user_id) {
      await gg_decline_request(this.team._id, user_id);
      await this.fetchData();
    },
    async removeUser(user_id) {
      await gg_team_remove_user(this.team._id, user_id);
      await this.fetchData();
    },
  },
};
</script>