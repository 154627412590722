import gg_axios from '../gg_axios';

/**
 * Create a new match
 * @param {*} ladder_id
 * @param {*} opponent_id - The user_id or team_id of the opponent
 * @param {*} time - This your custom DateTime string (frontend manages this)
 * @param {*} my_id - user_id or team_id of the current user
 * @returns
 */
export default async function(ladder_id, opponent_id, time, my_id) {
	try {
		const result = await gg_axios('POST', `/match`, {
			ladder_id,
			opponent_id,
			time,
			my_id
		});

		return result;
	} catch (error) {
		throw error;
	}
}
