import gg_axios from '../gg_axios';


export default async function (ladder_id) {
    try {
        const result = await gg_axios('PUT', `/ladder/follow/${ladder_id}`);

        return result;
    } catch (error) {
        throw error;
    }
}

