import gg_axios from '../gg_axios';

/**
 * Delete a comment
 * @param {*} post_id - REQUIRED
 * @param {*} comment_id - REQUIRED
 * @returns
 */
export default async function(post_id, comment_id) {
	try {
		const result = await gg_axios('DELETE', `/social/comment/${post_id}/${comment_id}`);

		return result;
	} catch (error) {
		throw error;
	}
}
