import gg_axios from '../gg_axios';

/**
 * Change the time of a match
 * @params {*} match_id
 * {*} timestamp
 * @returns
 */
export default async function(match_id, time = null) {
    try {
        const result = await gg_axios('PUT', `/match/update/${match_id}`, {
            time
        });
        return result;
    } catch (error) {
        throw error;
    }
}