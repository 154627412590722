<template>
	<Main background="default-ladder-bg.jpg" :image_id="bg_image_id">
		<RightPanel no_backbar="true">
			<div class="mb-6">
				<Button color="cardBaseColor" @click="goBack">
					<v-icon left color="primary"> mdi-arrow-left-circle </v-icon>
					Go Back
				</Button>
			</div>

			<div class="text-h5 font-weight-bold mb-6">Upload score</div>
			<v-card v-ripple flat color="cardBaseColor" class="rounded-xl px-4 py-1 mb-6 d-flex">
				<div class="d-flex flex-column mr-4 justify-center">
					<span class="text-subtitle-2 text-center font-weight-bold">{{ match_info.date }}</span>
					<span class="text-caption text-center mt-n1">{{ match_info.time }}</span>
				</div>
				<div class="d-flex flex-column">
					<span class="font-weight-bold"
						>{{ match_info.players[0].user_id.name }} vs. {{ match_info.players[1].user_id.name }}</span
					>
					<span class="text-caption mt-n1">{{ ladder_name }}</span>
				</div>
			</v-card>

			<div v-if="show_analysed">
				<div class="text-h7 font-weight-bold mb-2">Selected file</div>

				<div class="text-h7 mb-2">
					<span v-if="image" class="text-caption">{{ this.image.name }}</span>
				</div>

				<div class="container pa-0">
					<v-row no-gutters>
						<v-col cols="10">
							<v-card flat class="rounded-xl" style="overflow: hidden">
								<v-img :src="url" alt=""></v-img>
								<v-overlay :absolute="true" :value="analysing" opacity="0.8">
									<v-progress-circular indeterminate color="primary" class="mr-2" /><span> Analysing score </span>
								</v-overlay>
							</v-card>
						</v-col>
						<v-col cols="2">
							<div class="vertical-center">
								<v-btn class="mx-2" fab dark color="cardBaseColor" @click="deleteFile()">
									<v-icon white color="primary"> mdi-delete </v-icon>
								</v-btn>
							</div>
						</v-col>
					</v-row>
				</div>

				<div class="text-h6 font-weight-bold my-5">Analysed end score: {{ home_score }} - {{ away_score }}</div>

				<Button
					v-if="!analysing"
					color="cardBaseColor"
					:loading="loading"
					:disabled="!home_score || !away_score || home_score < 0 || away_score < 0 || loading"
					class="uploadButton mt-10"
					@click="uploadScore"
				>
					Upload
				</Button>
			</div>

			<div v-else-if="manual_entry || match_info.status === 'SCORED'">
        <span v-if="match_info.status === 'SCORED'" class="font-weight-bold red--text">
            Your opponent will be notified that you changed the score.
        </span>
				<div class="scores">
					<div class="score mr-6">
						<span>Your score:</span>
						<v-text-field class="mt-0 pt-0" v-model="home_score" hide-details single-line type="number" :min="0" />
					</div>
					<div class="score">
						<span>Opponent's score: </span>
						<v-text-field class="mt-0 pt-0" v-model="away_score" hide-details single-line type="number" :min="0" />
					</div>
				</div>

				<Button
					color="cardBaseColor"
					:loading="loading"
					:disabled="!home_score || !away_score || loading"
					class="uploadButton mt-10"
					@click="uploadScore"
				>
					Upload
				</Button>
			</div>

			<div v-else>
				<div v-if="opp_index !== null">
					<span class="text-h7 font-weight-bold"> Score by opponent </span>
					<div class="text-caption mb-4">
						Your opponent has uploaded the following score. If you agree you don't have to do anything.
					</div>

					<div class="d-flex">
						<v-card
							flat
							color="cardBaseColor"
							height="36px"
							class="rounded-pill px-4 d-flex align-center justify-space-between mb-4 mr-2 flex-grow-1"
						>
							<span class="font-weight-bold">{{ match_info.players[0].user_id.name }}</span>
							<span class="text-h6 font-weight-bold">{{
								opp_index === 0 ? opp_home_score + '-' + opp_away_score : opp_away_score + '-' + opp_home_score
							}}</span>
							<span class="font-weight-bold">{{ match_info.players[1].user_id.name }}</span>
						</v-card>
					</div>
				</div>

				<div class="mb-8">
					<div v-if="opp_index !== null">
						<Button color="red" @click="rejectScore">Reject</Button>
						<Button color="green" @click="approveScore">Accept</Button>
					</div>
					<div v-else>
						<Button color="cardBaseColor" @click="manual_entry = true"> Click here to enter score </Button>
					</div>
				</div>

<!--				<hr />-->

<!--				<div class="text-h7 font-weight-bold mt-8">-->
<!--					Upload a file (disabled for wirwar)-->
<!--					<span class="filetype"> PNG | JPG | JPEG </span>-->
<!--				</div>-->

<!--				<div class="text-caption mb-4">Upload a match endscreen with the score (optional)</div>-->

<!--				<input type="file" style="display: none" ref="fileInput" accept="image/*" @change="onFilePicked" />-->
<!--				<DragDrop />-->
			</div>
			<span v-if="errormessage" class="text-caption red--text text--accent-2"> {{ errormessage }} </span>
		</RightPanel>

		<v-snackbar v-model="snackbar">
			Your opponent already entered the score, please go back and try again to accept the score.

			<template v-slot:action="{ attrs }">
				<v-btn color="primary" text v-bind="attrs" @click="goBack"> OK </v-btn>
			</template>
		</v-snackbar>
	</Main>
</template>

<script>
// https://www.figma.com/proto/Xcnr6hkjiTNIs5Ec5pHqek/Prototype-3?node-id=184%3A759&scaling=contain&page-id=0%3A1&starting-point-node-id=258%3A1

import Main from '../components/common/Main';
import Button from '../components/common/elements/Button';
import RightPanel from '../components/common/RightPanel.vue';
import 'vue-advanced-cropper/dist/style.css';

import { gg_get_match, gg_score_match, gg_ladder_info, gg_reject_score_match } from '../api';

export default {
	props: ['file', 'match_info'],
	name: 'Dashboard',

	components: {
		Main,
		Button,
		RightPanel,
	},
	data() {
		return {
			image: null,
			url: null,
			home_score: null,
			away_score: null,
			scoreinfo: [],

			opp_index: null,
			opp_home_score: null,
			opp_away_score: null,

			image_id: null,
			new_image_uncropped: null,
			new_image_cropped: null,

			loading: false,
			analysing: false,
			message: null,
			errormessage: null,
			cropper_active: false,

			show_analysed: false,
			manual_entry: false,

			ladder_name: null,
			bg_image_id: null,

			snackbar: false,
		};
	},

	async created() {
		if (!this.match_info) this.$router.replace({ name: 'Error' });

		// Todo: Refresh match info
		const refreshedMatch = await gg_get_match(this.$route.params.id);

		for (const player of refreshedMatch.players) {
			if (player.user_id._id !== this.$store.getters.getUserInfo._id && player.scored) {
				this.opp_index = refreshedMatch.players.indexOf(player);
				this.opp_home_score = player.stats.my_goal;
				this.opp_away_score = player.stats.opponent_goal;
			}
		}

		try {
			const result = await gg_ladder_info(this.match_info.ladder_id);
			if (result) {
				this.ladder_name = result.name;
				this.bg_image_id = result.bg_image_id;
			}
		} catch (err) {
			console.log(err);
			this.message = err;
		}
	},
	mounted() {
		// console.log(this.image);
		this.$root.$on('dragDrop', image => {
			this.image = image;
			this.url = URL.createObjectURL(this.image);
			this.onUpload();
		});
	},
	methods: {
		preview_image() {
			this.url = URL.createObjectURL(this.image);
		},
		chooseFiles() {
			let fileInputElement = this.$refs.fileInput;
			fileInputElement.click();
		},
		onFilePicked(event) {
			const files = event.target.files;
			// let filename = files[0].name;
			const fileReader = new FileReader();
			fileReader.addEventListener('load', () => {
				this.imageUrl = fileReader.result;
			});
			fileReader.readAsDataURL(files[0]);
			this.image = files[0];
			this.url = URL.createObjectURL(this.image);
			// onUpload();
		},
		deleteFile() {
			this.image = null;
			this.show_analysed = null;
			this.home_score = null;
			this.away_score = null;
			this.url = null;
			this.message = null;
		},
		async onUpload() {
			// this.analysing = true;
			// this.loading = true;
			// if (this.image) {
			// 	this.show_analysed = true;
			// 	// Here, the python script should be called to extract the scores.
			// 	//console.log(this.image);
			// 	await gg_upload_scoreimage(this.image).then(info => {
			// 		this.analysing = false;
			// 		this.loading = false;
			// 		this.scoreinfo = [];
			// 		for (var si of info.result) {
			// 			this.scoreinfo.push(si);
			// 		}
			// 		if (this.scoreinfo.length > 7) this.home_score = this.scoreinfo[6];
			// 		if (this.scoreinfo.length > 8) this.away_score = this.scoreinfo[7];
			// 	});
			// }
		},
		async uploadScore() {
			this.errormessage = null;
			this.loading = true;

			try {
				const result = await gg_score_match(this.$route.params.id, this.home_score, this.away_score, false);
				if (result) {
					var result_index = parseInt(result.message);
					if (result_index === 0 || result_index === 1)
						this.$router.replace({
							name: 'ManageMatches',
							params: { id: this.match_info.ladder_id, highlight_id: this.$route.params.id },
							query: { tab: 'played' },
						});
					else if (result_index === 2) this.snackbar = true;
				}
			} catch (err) {
				console.log(err);
				this.errormessage = err;
			}

			this.loading = false;

      this.$router.replace({
        name: 'ManageMatches',
        params: { id: this.match_info.ladder_id, highlight_id: this.$route.params.id },
        query: { tab: 'played' },
      });
		},
		goBack() {
			this.snackbar = false;
			if (this.show_analysed) this.show_analysed = false;
			else if (this.manual_entry) this.manual_entry = false;
			else this.$router.go(-1);
		},

		async approveScore() {
			this.home_score = this.opp_away_score;
			this.away_score = this.opp_home_score;
			await this.uploadScore();
		},

		async rejectScore() {
			this.errormessage = null;
			this.loading = true;

			try {
				const result = await gg_reject_score_match(this.$route.params.id);
				console.log(result);
				if (result) {
					this.$router.replace({
						name: 'ManageMatches',
						params: { id: this.match_info.ladder_id, highlight_id: this.$route.params.id },
						query: { tab: 'played' },
					});
				}
			} catch (err) {
				console.log(err);
				this.errormessage = err;
			}

			this.loading = false;
		},
	},
};
</script>

<style scoped>
.container {
	position: relative;
}

.vertical-center {
	margin: 0;
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.scores {
	display: flex;
}

.score {
	width: 40%;
}

.filetype {
	font-size: 10px;
	margin-left: 20px;
	position: relative;
	bottom: 2px;
}

.fill img {
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	object-fit: cover;
}

.uploadButton {
	display: flex;
	/* margin-top: 50px;
	margin-left: 50px;
	margin-right: 50px;
	margin-bottom: 50px; */
}

main {
	background-size: cover;
}

</style>
