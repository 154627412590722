import gg_axios from '../gg_axios';

/**
 * Request an email to reset forgotten password
 * @param {string} email - user email address
 * @returns {object} Message informing wheter email is sent or not
 */
async function gg_forgot_pass(email) {
	try {
		return await gg_axios('PUT', `/auth/forgot/${email}`);
	} catch (error) {
		throw error;
	}
}

export default gg_forgot_pass;
