import gg_axios from '../gg_axios';

/**
 * POST - Signup
 * @param {string} name
 * @param {string} phone
 * @param {string} email
 * @param {string} password
 * @returns token + payload (user info)
 */
async function gg_signup(name, phone, email, password) {
    try {
        const result = await gg_axios('POST', '/auth/signup', { name, phone, email, password });

        return result;
    } catch (error) {
        throw error;
    }
}

export default gg_signup;