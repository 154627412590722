<template>
	<Main background="fifa-background.png">
		<RightPanel>
			<span class="text-h5 font-weight-bold"> Plan match | Philips FIFA Ladder </span>
			<span class="text-caption font-weight-bold"> Plan your match with your opponent </span>

			<v-card flat color="cardBaseColor" class="d-flex justify-space-around align-center rounded-pill py-1 mt-4">
				<v-avatar color="black" size="32">
					<v-img :src="$store.getters.getAvatar" />
				</v-avatar>
				<div class="d-flex flex-column">
					<span class="player-name text-h6 font-weight-bold"> Jasper Ipskamp </span>
					<span class="text-h8"> Number 6 </span>
				</div>
				<span class="text-h6 font-weight-bold"> vs. </span>
				<div class="d-flex flex-column align-end">
					<span class="player-name text-h6 font-weight-bold"> Tristan Raterink </span>
					<span class="text-h8"> Number 4 </span>
				</div>
				<v-avatar color="black" size="32">
					<v-img :src="$store.getters.getAvatar" />
				</v-avatar>
			</v-card>

			<span class="text-h5 font-weight-bold mt-6"> Match Details </span>
			<span class="text-caption font-weight-bold"> Select your preferred date and time for the match </span>

			<DatePicker v-model="date" class="mt-4" />
			<TimePicker v-model="time" class="mt-4" />

			<Button class="align-self-center mt-12 mb-auto" color="background" width="30%"> Plan Match </Button>
			<span class="text-caption font-weight-bold text-center align-self-center" style="width: 70%">
				*After planning the match you or your opponent can always change the match date and match time
			</span>
		</RightPanel>
	</Main>
</template>

<style scoped>
.player-name {
	line-height: 1.5rem;
}
</style>

<script>
import Main from '../components/common/Main';
import RightPanel from '../components/common/RightPanel';
import Button from '../components/common/elements/Button';
import DatePicker from '../components/common/elements/DatePicker';
import TimePicker from '../components/common/elements/TimePicker';

export default {
	name: 'PlanMatch',

	components: {
		Main,
		RightPanel,
		Button,
		DatePicker,
		TimePicker,
	},

	data() {
		return {
			date: null,
			time: null,
		};
	},
};
</script>