<template>
	<Main background="login-background.png">
		<v-row class="px-1 fill-height justify-end" no-gutter>
			<v-col cols="12" sm="8" md="5" align-self="center">
				<p class="text-h4 font-weight-bold text-center">Esportsladder</p>
				<v-card flat class="rounded-xl" color="cardBaseColor" style="z-index: 1">
					<v-card-text class="px-8 py-8">
						<ValidationObserver v-slot="{ handleSubmit }">
							<v-form @submit.prevent="handleSubmit(signUp)">
								<div>
									<div>
										<label for="name" class="ml-6"> Name </label>
										<FormField id="name" v-model="name" rules="required|alpha_num|max_user:25" />
										<label for="phone" class="ml-6" style="display: inline-block;">
											Phone Number. IMPORTANT: This must be in the form (31612345678)
										</label>
										<FormField id="phone" v-model="phone" rules="required|min_phone:10|max_phone:12" />
									</div>
									<label for="email" class="ml-6" style="display: inline-block;"> Company or student email </label>
									<FormField id="email" v-model="email" rules="required|email" />
									<div>
										<label for="password" class="ml-6"> Password </label>
										<FormField
											id="password"
											type="password"
											v-model="password"
											rules="required|min_password:8|verify_password"
										/>
									</div>
									<v-btn
										color="primary"
										rounded
										block
										depressed
										:loading="loading"
										:disabled="loading"
										:error-messages="errormessage"
										type="submit"
										@click="email = email.toLowerCase()"
									>
										{{ 'Sign up' }}
									</v-btn>
									<span v-if="errormessage" class="text-caption red--text text--accent-2"> {{ errormessage }} </span>
									<span v-if="successmessage" class="text-caption green--text text--accent-2">
										{{ successmessage.text }}
									</span>
								</div>
							</v-form>
						</ValidationObserver>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<v-snackbar v-model="snackbar">
			Check your inbox at {{ emailUpdateSnackbar }} to verify your email address

			<template v-slot:action="{ attrs }">
				<v-btn color="primary" text v-bind="attrs" @click="snackbar = false"> OK </v-btn>
			</template>
		</v-snackbar>
	</Main>
</template>

<style scoped>
.message-alert {
	border: none !important;
	padding-top: 6px;
	padding-bottom: 6px;
	margin-bottom: 0;
}
.message-alert >>> .v-alert__wrapper i {
	margin-right: 10px;
}
</style>

<script>
import { ValidationObserver } from 'vee-validate';
import FormField from '../components/common/elements/FormField';
import Main from '../components/common/Main';

import { gg_signup } from '../api';

export default {
	components: {
		ValidationObserver,
		FormField,
		Main,
	},

	props: ['emailUpdateSnackbar'],

	data() {
		return {
			name: null,
			phone: null,
			email: null,
			password: null,
			errormessage: null,
			mailsent: null,
			loading: false,
			snackbar: false,
			successmessage: null,
		};
	},

	mounted() {
		if (this.emailUpdateSnackbar) {
			setTimeout(() => {
				this.snackbar = true;
			}, 100);
		}
	},

	methods: {
		async signUp() {
			this.reset();

			this.loading = true;

			try {
				const result = await gg_signup(this.name, this.phone, this.email, this.password);
				this.mailsent = 'signup';

				this.successmessage = {
					type: 'success',
					text: 'Thank you for registering! You will receive more information before WirWar starts.',
				};
			} catch (err) {
				this.errormessage = err;
			}

			this.loading = false;
		},

		reset() {
			this.errormessage = null;
			this.loading = false;
		},
	},
};
</script>
