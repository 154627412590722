<template>
	<v-row>
		<v-col>
			<div class="d-flex px-4 py-2">
				<v-avatar color="black" size="96">
					<v-img :src="avatar" alt="Ed" />
				</v-avatar>
				<div class="d-flex flex-column justify-center ml-3">
					<span class="text-h5 font-weight-bold"> {{ type === 'player' ? user_info.name : company_info.name }} </span>
					<div class="d-flex align-center mt-2">
						<v-chip
							v-if="type === 'player'"
							small
							color="primary"
							class="text-caption font-weight-bold align-self-start"
							:to="{ name: 'CompanyProfile', params: { id: user_info.company_id } }"
						>
							{{ company_info.name }}
						</v-chip>
						<v-btn v-if="!id || id === $store.getters.getUserInfo._id" icon x-small to="/settings" class="ml-2"
							><v-icon> mdi-cog </v-icon></v-btn
						>
					</div>
					<span v-if="type === 'company'"> {{ company_info.city }} </span>
				</div>
			</div>
			<DefaultCard>
				<span class="font-weight-bold"> Bio </span><br />
				<div v-if="type === 'player' && user_info.bio">{{ user_info.bio }}</div>
				<div v-else-if="type === 'company' && company_info.bio">{{ company_info.bio }}</div>
				<div v-else class="text--disabled">{{ $t("profile.no_bio") }}</div>
			</DefaultCard>
		</v-col>
		<v-col v-if="type === 'player'">
			<DefaultCard class="fill-height d-flex flex-column">
				<div class="mb-4"><span class="font-weight-bold"> {{ $t("profile.contact_info") }} </span></div>
				<div class="my-1">
					<v-icon class="mr-2" color="primary"> mdi-email </v-icon><span>{{ user_info.email }}</span>
				</div>
				<div class="my-1">
					<v-icon class="mr-2" color="primary"> mdi-phone </v-icon><span>{{ user_info.phone }}</span>
				</div>
				<div class="my-1">
					<v-icon class="mr-2" color="primary"> mdi-whatsapp </v-icon
					><span
						><a target="_blank" :href="whatsapp_link" style="color:white;">{{ user_info.phone }}</a></span
					>
				</div>
				<div v-if="user_info.socials">
					<div v-if="user_info.socials.instagram" class="my-1">
						<v-icon class="mr-2" color="primary"> mdi-instagram </v-icon><span>{{ user_info.socials.instagram }}</span>
					</div>
					<div v-if="user_info.socials.discord" class="my-1">
						<v-icon class="mr-2" color="primary"> mdi-discord </v-icon><span>{{ user_info.socials.discord }}</span>
					</div>
					<div v-if="user_info.socials.facebook" class="my-1">
						<v-icon class="mr-2" color="primary"> mdi-facebook </v-icon><span>{{ user_info.socials.facebook }}</span>
					</div>
					<div v-if="user_info.socials.linkedin" class="my-1">
						<v-icon class="mr-2" color="primary"> mdi-linkedin </v-icon><span>{{ user_info.socials.linkedin }}</span>
					</div>
					<div v-if="user_info.socials.nintendo" class="my-1">
						<v-icon class="mr-2" color="primary"> mdi-nintendo-switch </v-icon><span>{{ user_info.socials.nintendo }}</span>
					</div>
					<div v-if="user_info.socials.eaid" class="my-1">
						<v-icon class="mr-2" color="primary"> mdi-soccer </v-icon><span>{{ user_info.socials.eaid }}</span>
					</div>
				</div>
			</DefaultCard>
			<span v-if="message" class="text-caption red--text text--accent-2"> {{ message }} </span>
		</v-col>
	</v-row>
</template>

<script>
import DefaultCard from '../common/DefaultCard';

import { GG_ENDPOINT, gg_user_info, gg_company_info } from '../../api';

export default {
	name: 'ProfileInfo',

	props: ['type', 'id'],

	components: {
		DefaultCard,
	},

	data() {
		return {
			user_info: {},
			company_info: {},
			avatar: null,

			message: null,
		};
	},

	computed: {
		whatsapp_link() {
			return 'https://wa.me/' + this.user_info.phone;
		},
	},

	async created() {
		this.updateInfo();
	},

	methods: {
    async updateInfo() {
      if (this.id && this.type === 'player') {
        await this.getUserInfo(this.id);
        await this.getCompanyInfo(this.user_info.company_id);
        this.avatar = this.user_info.image_id
            ? GG_ENDPOINT + '/image/raw/' + this.user_info.image_id
            : require(`@/assets/images/default-avatar.png`);
      } else if (this.id && this.type === 'company') {
        await this.getCompanyInfo(this.id);
        this.avatar = this.company_info.image_id
            ? GG_ENDPOINT + '/image/raw/' + this.company_info.image_id
            : require(`@/assets/images/default-company.png`);
      } else {
        this.user_info = this.$store.getters.getUserInfo;
        this.company_info = this.$store.getters.getCompanyInfo;
        this.avatar =
            this.type === 'player'
                ? this.$store.getters.getAvatar
                : this.company_info.image_id
                    ? GG_ENDPOINT + '/image/raw/' + this.company_info.image_id
                    : require(`@/assets/images/default-company.png`);
      }
    },

		async getUserInfo(id) {
			try {
				const result = await gg_user_info(id);
				if (result) {
					this.user_info = result;
				}
			} catch (err) {
				this.message = err;
			}
		},

		async getCompanyInfo(id) {
			try {
				const result = await gg_company_info(id);
				if (result) {
					this.company_info = result;
				}
			} catch (err) {
				this.message = err;
			}
		},
	},

  watch: {
    '$route.params.id': function () {
      this.updateInfo();
    },
  },
};
</script>
