import gg_axios from '../gg_axios';

/**
 * Endpoint to request all users' info
 * @returns {object} users
 */
async function gg_all_users() {
    try {
        return await gg_axios('GET', '/user/all');
    } catch (error) {
        throw new Error(error);
    }
}

export default gg_all_users;
