import gg_axios from '../gg_axios';

/**
 * Add a player to the ladder
 * @param {string} team_id
 * @param {string} ladder_id
 * @param {number} frequency - read docs
 * @param {number} availability - read docs
 * @param {number} skill - read docs
 * @returns {object} player static information
 */
async function gg_teamladder_add_team(team_id, ladder_id, frequency, availability, skill = null) {
    try {
        console.log(team_id)
        const result = await gg_axios('PUT', `/teamladder/add/`, {
            team_id,
            ladder_id,
            frequency,
            availability,
            skill,
        });

        return result;
    } catch (error) {
        throw error;
    }
}

export default gg_teamladder_add_team;
