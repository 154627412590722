<template>
	<Main background="fifa-background.png">
		<Sidebar />
		<v-container>
			<BackBar />

			<ValidationObserver v-slot="{ handleSubmit }">
				<PageHeader :title="$t('ladder.manage.manage_ladder')">
					<v-alert class="message-alert" dense outlined :type="message.type" v-if="message">
						{{ message.text }}
					</v-alert>

					<Button
						class="mr-4"
						:loading="loading"
						:disabled="loading"
						@click="$route.params.mode === 'add' ? handleSubmit(addLadder) : handleSubmit(updateLadder)"
					>
            {{ $t('settings.save_changes') }}
					</Button>
				</PageHeader>

				<v-row>
					<v-col cols="12">
						<DefaultCard :title=" $route.params.mode === 'add' ? $t('ladder.manage.add_ladder') : $t('ladder.manage.edit_ladder')">
							<v-row>
								<v-col cols="12" sm="3">
									<v-row no-gutters>
										<v-hover v-slot:default="{ hover }">
											<v-card color="primary" class="rounded-xl" width="100%">
												<v-img :src="image" :aspect-ratio="2 / 3" v-ripple>
													<label for="file-input" style="cursor: pointer">
														<v-fade-transition>
															<v-overlay v-if="hover" absolute>
																<v-icon> mdi-image-edit-outline </v-icon>
																<input
																	id="file-input"
																	type="file"
																	accept="image/*"
																	style="display: none"
																	@change="
																		cropper_active = true;
																		cropperPreview($event);
																	"
																/>
															</v-overlay>
														</v-fade-transition>
													</label>
												</v-img>
											</v-card>
										</v-hover>
									</v-row>

									<v-row no-gutters>
										<label class="font-weight-bold mt-3"> {{ $t("ladder.manage.thumbnail") }} </label>
									</v-row>
								</v-col>
								<v-col cols="12" sm="9" lg="7">
									<v-row no-gutters>
										<v-col cols="12" md="3">
											<label for="name" class="formlabel font-weight-bold"> {{ $t("ladder.manage.name") }} </label>
										</v-col>
										<v-col cols="12" md="9">
											<FormField id="name" v-model="name" rules="required" :placeholder="$t('ladder.manage.name_placeholder')" />
										</v-col>
									</v-row>

                  <v-row no-gutters>
                    <v-col cols="12" md="3">
                      <label for="name" class="formlabel font-weight-bold"> {{ $t("ladder.manage.teams") }} </label>
                    </v-col>
                    <v-col cols="12" md="9">
                      <v-btn v-bind:class="{'green': uses_teams, 'red': !uses_teams}" class="mb-5" @click="uses_teams = !uses_teams"> {{ uses_teams }} </v-btn>
                    </v-col>
                  </v-row>

									<v-row no-gutters>
										<v-col cols="12" md="3">
											<label for="platform" class="formlabel font-weight-bold"> {{ $t("ladder.manage.platform") }} </label>
										</v-col>
										<v-col cols="12" md="9">
											<FormField id="platform" v-model="platform" rules="required" :placeholder="$t('ladder.manage.platform_placeholder')" />
										</v-col>
									</v-row>

									<v-row no-gutters>
										<v-col cols="12" md="3">
											<label for="bio" class="formlabel font-weight-bold"> {{ $t("ladder.manage.bio") }} </label>
										</v-col>
										<v-col cols="12" md="9">
											<v-textarea
												filled
												rounded
												background-color="background"
												id="bio"
												v-model="bio"
												:placeholder="$t('misc.write_sth')"
											/>
										</v-col>
									</v-row>

									<v-row no-gutters>
										<v-col cols="12" md="3">
											<label for="platform" class="formlabel font-weight-bold"> {{ $t("ladder.manage.background") }} </label>
										</v-col>
										<v-col cols="12" md="9">
											<div class="d-flex">
												<v-hover v-slot:default="{ hover }">
													<v-card flat class="rounded-xl" color="background" width="50%">
														<v-img :src="bg_image" :aspect-ratio="16 / 9" v-ripple>
															<label for="file-input" style="cursor: pointer">
																<v-fade-transition>
																	<v-overlay v-if="hover" absolute>
																		<v-icon> mdi-image-edit-outline </v-icon>
																		<input
																			id="file-input"
																			type="file"
																			accept="image/*"
																			style="display: none"
																			@change="bgImagePreview($event)"
																		/>
																	</v-overlay>
																</v-fade-transition>
															</label>
														</v-img>
													</v-card>
												</v-hover>
												<div class="ml-4 text--disabled">
                          {{ $t("ladder.manage.res_rec") }}
												</div>
											</div>
										</v-col>
									</v-row>
								</v-col>
							</v-row>
						</DefaultCard>
					</v-col>
				</v-row>
			</ValidationObserver>

			<v-overlay v-if="cropper_active" z-index="7" opacity="0.9">
				<div class="d-flex align-center flex-column">
					<cropper
						ref="cropper"
						:src="new_image_uncropped"
						:stencil-props="{
							aspectRatio: 2 / 3,
						}"
						:canvas="{
							minHeight: 0,
							minWidth: 0,
							maxHeight: 512,
							maxWidth: 512,
						}"
						style="max-height: 80vh; max-width: 60vw"
					/>
					<div class="d-flex mt-4">
						<Button color="cardBaseColor" class="mr-3" @click="cropper_active = false"> {{ $t("common.close") }} </Button>
						<Button @click="applyCropped"> {{ $t("common.apply") }} </Button>
					</div>
				</div>
			</v-overlay>
		</v-container>
	</Main>
</template>

<style scoped>
.formlabel {
	display: flex;
	align-items: center;
	min-height: 40px;
}
.message-alert {
	border: none !important;
	padding-top: 6px;
	padding-bottom: 6px;
	margin-bottom: 0;
}
.message-alert >>> .v-alert__wrapper i {
	margin-right: 10px;
}
</style>

<script>
import Sidebar from '../components/common/Sidebar';
import BackBar from '../components/common/BackBar';
import Main from '../components/common/Main';
import PageHeader from '../components/common/PageHeader';
import Button from '../components/common/elements/Button';
import DefaultCard from '../components/common/DefaultCard.vue';
import FormField from '../components/common/elements/FormField.vue';
import { ValidationObserver } from 'vee-validate';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

import { gg_add_ladder, gg_update_ladder, gg_ladder_info, gg_upload_image, GG_ENDPOINT } from '../api';

export default {
	name: 'LadderSettings',

	components: {
		Sidebar,
		BackBar,
		Main,
		PageHeader,
		Button,
		DefaultCard,
		FormField,
		ValidationObserver,
		Cropper,
	},

	data() {
		return {
			name: null,
      uses_teams: false,
			platform: null,
			bio: null,

			loading: false,
			message: null,
			cropper_active: false,

			image_id: null,
			image: require(`@/assets/images/default-ladder.png`),
			new_image_uncropped: null,
			new_image_cropped: null,

			bg_image_id: null,
			bg_image: require(`@/assets/images/default-ladder.png`),
			bg_image_blob: null,
		};
	},

	async created() {
		if (this.$route.params.mode === 'edit' && this.$route.params.id) {
			try {
				const result = await gg_ladder_info(this.$route.params.id);
				if (result) {
					this.name = result.name;
          this.uses_teams = result.uses_teams;
					this.platform = result.device;
					this.image_id = result.image_id;
					this.bg_image_id = result.bg_image_id;
					this.bio = result.bio ? result.bio : null;
				}

				if (result.image_id) {
					this.image = GG_ENDPOINT + '/image/raw/' + result.image_id;
				}

				if (result.bg_image_id) {
					this.bg_image = GG_ENDPOINT + '/image/raw/' + result.bg_image_id;
				}
			} catch (err) {
				console.log(err);
				this.message = {
					type: 'error',
					text: err,
				};
			}
		}
	},

	methods: {
		async addLadder() {
			this.reset();

			this.loading = true;

			try {
				if (this.new_image_cropped) {
					const image_result = await gg_upload_image(this.new_image_cropped);
					this.image_id = image_result._id;
				}
				if (this.bg_image_blob) {
					const image_result = await gg_upload_image(this.bg_image_blob);
					this.bg_image_id = image_result._id;
				}
				await gg_add_ladder(this.name, this.uses_teams, this.platform, this.bio, this.image_id, this.bg_image_id);
				this.message = {
					type: 'success',
					text: 'Changes saved',
				};
			} catch (err) {
				this.message = {
					type: 'error',
					text: err,
				};
			}

			this.loading = false;
		},

		async updateLadder() {
			this.reset();

			this.loading = true;

			try {
				if (this.new_image_cropped) {
					const image_result = await gg_upload_image(this.new_image_cropped);
					this.image_id = image_result._id;
				}
				if (this.bg_image_blob) {
					const image_result = await gg_upload_image(this.bg_image_blob);
					this.bg_image_id = image_result._id;
				}
				await gg_update_ladder(
					this.$route.params.id,
					this.name,
          this.uses_teams,
					this.platform,
					this.bio,
					this.image_id,
					this.bg_image_id
				);
				this.message = {
					type: 'success',
					text: 'Changes saved',
				};
			} catch (err) {
				this.message = {
					type: 'error',
					text: err,
				};
			}

			this.loading = false;
		},

		reset() {
			this.message = null;
			this.loading = false;
		},

		cropperPreview(e) {
			const file = e.target.files[0];
			const reader = new FileReader();

			const that = this;
			reader.addEventListener(
				'load',
				function () {
					// convert image file to base64 string
					that.new_image_uncropped = reader.result;
				},
				false
			);

			if (file) {
				reader.readAsDataURL(file);
			}
		},

		applyCropped() {
			const { canvas } = this.$refs.cropper.getResult();
			this.image = canvas.toDataURL();

			const that = this;
			canvas.toBlob(function (blob) {
				that.new_image_cropped = blob;
			}, 'image/png');

			this.cropper_active = false;
		},

		bgImagePreview(e) {
			const file = e.target.files[0];
			this.bg_image = URL.createObjectURL(file);
			this.bg_image_blob = file;
		},
	},
};
</script>