<template>
	<v-row no-gutters class="mx-n2">
		<v-col cols="12" sm="3">
			<DefaultCard class="mx-2 mb-2">
				<div class="d-flex flex-column">
					<span class="text-h6 font-weight-bold"> {{ $t("profile.played_matches") }} </span>
					<span class="text-h2 font-weight-bold primary--text text-center my-2"> {{ stats.matches }} </span>
				</div>
			</DefaultCard>
		</v-col>
		<v-col cols="12" sm="3">
			<DefaultCard class="mx-2 mb-2">
				<div class="d-flex flex-column">
					<span class="text-h6 font-weight-bold"> {{ $t("stats.wins") }} </span>
					<span class="text-h2 font-weight-bold primary--text text-center my-2"> {{ stats.wins }} </span>
				</div>
			</DefaultCard>
		</v-col>
		<v-col cols="12" sm="3">
			<DefaultCard class="mx-2 mb-2">
				<div class="d-flex flex-column">
					<span class="text-h6 font-weight-bold"> {{ $t("stats.losses") }} </span>
					<span class="text-h2 font-weight-bold primary--text text-center my-2"> {{ stats.losses }} </span>
				</div>
			</DefaultCard>
		</v-col>
		<v-col cols="12" sm="3">
			<DefaultCard class="mx-2 mb-2">
				<div class="d-flex flex-column">
					<span class="text-h6 font-weight-bold"> {{ $t("stats.draws") }} </span>
					<span class="text-h2 font-weight-bold primary--text text-center my-2"> {{ stats.draws }} </span>
				</div>
			</DefaultCard>
		</v-col>
	</v-row>
</template>

<script>
import DefaultCard from '../common/DefaultCard';

export default {
	name: 'LadderStats',

	components: {
		DefaultCard,
	},

	props: ['stats'],
};
</script>
