<template>
  <Main background="fifa-background.png">
    <Sidebar/>
    <v-container>
      <BackBar/>

      <ValidationObserver v-slot="{ handleSubmit }">
        <PageHeader title="Settings">
          <v-tabs
              v-if="
							$store.getters.getUserInfo.usertype === 'ADMIN' || $store.getters.getUserInfo.usertype === 'SUPERUSER'
						"
              background-color="transparent"
              height="35"
              slider-size="3"
              class="ml-6"
              style="width: auto"
          >
            <Tab to="/settings" exact> {{ $t("common.personal") }} </Tab>
            <Tab to="/settings/company"> {{ $t("common.company_c") }} </Tab>
          </v-tabs>

          <v-alert class="message-alert" dense outlined :type="message.type" v-if="message">
            {{ message.text }}
          </v-alert>

          <Button v-if="false" color="errorColor"> {{ $t("settings.delete_account") }}</Button>
        </PageHeader>

        <v-row>
          <v-col cols="12">
            <Button class="mr-4" :loading="loading" :disabled="loading" @click="handleSubmit(updateUser)">
              {{ $t("settings.save_changes") }}
            </Button>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <DefaultCard :title="$t('settings.profile')">
              <v-row>
                <v-col cols="12" sm="3">
                  <v-row no-gutters>
                    <v-hover v-slot:default="{ hover }">
                      <v-card color="primary" class="rounded-xl" width="100%">
                          <v-img id="avatar" @drop.prevent="drop" @dragenter.prevent @dragover.prevent :src="image" aspect-ratio="1" v-ripple>
                            <label for="file-input" style="cursor: pointer">
                              <v-fade-transition>
                                <v-overlay v-if="hover" absolute>
                                  <v-icon> mdi-image-edit-outline</v-icon>
                                  <input
                                      id="file-input"
                                      type="file"
                                      accept="image/*"
                                      style="display: none"
                                      @change="
																		cropper_active = true;
																		eventToFile($event);
																	"
                                  />
                                </v-overlay>
                              </v-fade-transition>
                            </label>
                          </v-img>
                      </v-card>
                    </v-hover>
                  </v-row>

                  <v-row no-gutters>
                    <label for="avatar" class="font-weight-bold mt-3"> {{ $t("settings.change_pic", {cp: $t('profile.profile')}) }} </label>
                  </v-row>
                  <v-row v-if="new_image_error" no-gutters>
                    <span style="color: red;">{{ new_image_error }}</span>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="9" lg="7">
                  <v-row no-gutters>
                    <v-col cols="12" md="3">
                      <label for="name" class="formlabel font-weight-bold"> {{ $t("settings.change_name", {cp: $t('profile.profile')}) }} </label>
                    </v-col>
                    <v-col cols="12" md="9">
                      <FormField id="name" v-model="name" rules="required|alpha_num|max_user:20"/>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col cols="12" md="3">
                      <label for="phone" class="formlabel font-weight-bold"> {{ $t("settings.change_phone") }} </label>
                    </v-col>
                    <v-col cols="12" md="9">
                      <FormField id="phone" v-model="phone" rules="required|min_phone:10|max_phone:12"/>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col cols="12" md="3">
                      <label for="email" class="formlabel font-weight-bold"> {{ $t("settings.change_email") }} </label>
                    </v-col>
                    <v-col cols="12" md="9">
                      <FormField id="email" v-model="email" rules="required|email" autocomplete="password"/>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col cols="12" md="3">
                      <label for="password" class="formlabel font-weight-bold"> {{ $t("settings.change_pass") }} </label>
                    </v-col>
                    <v-col cols="12" md="9">
                      <FormField
                          id="password"
                          v-model="password"
                          rules="|min_password:8|verify_password"
                          :type="show_password ? 'text' : 'password'"
                          :placeholder="$t('settings.pass_placeholder')"
                          autocomplete="new-password"
                          :append-icon="show_password ? 'mdi-eye-off' : 'mdi-eye'"
                          @click:append="show_password = !show_password"
                      />
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col cols="12" md="3">
                      <label for="company" class="formlabel font-weight-bold"> {{ $t("common.company_c") }} </label>
                    </v-col>
                    <v-col cols="12" md="9">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            <FormField id="company" v-model="company" disabled/>
                          </div>
                        </template>
                        <span> {{ $t("misc.company_email") }}</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col cols="12" md="3">
                      <label for="bio" class="formlabel font-weight-bold"> Change bio </label>
                    </v-col>
                    <v-col cols="12" md="9">
                      <v-textarea
                          filled
                          rounded
                          background-color="background"
                          id="bio"
                          v-model="bio"
                          :placeholder="$t('misc.write_sth')"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </DefaultCard>
          </v-col>

          <v-col cols="12">
            <DefaultCard title="Socials">
              <v-row no-gutters>
                <v-col cols="12" md="6">
                  <v-row no-gutters>
                    <v-col cols="2">
                      <label for="instagram" class="formlabel font-weight-bold"> Instagram </label>
                    </v-col>
                    <v-col cols="9" md="8">
                      <FormField id="instagram" v-model="instagram" placeholder="Instagram handle"/>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" md="6">
                  <v-row no-gutters>
                    <v-col cols="2">
                      <label for="discord" class="formlabel font-weight-bold"> Discord </label>
                    </v-col>
                    <v-col cols="9" md="8">
                      <FormField id="discord" v-model="discord" placeholder="Discord username"/>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" md="6">
                  <v-row no-gutters>
                    <v-col cols="2">
                      <label for="facebook" class="formlabel font-weight-bold"> Facebook </label>
                    </v-col>
                    <v-col cols="9" md="8">
                      <FormField id="facebook" v-model="facebook" placeholder="Profile URL"/>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" md="6">
                  <v-row no-gutters>
                    <v-col cols="2">
                      <label for="linkedin" class="formlabel font-weight-bold"> LinkedIn </label>
                    </v-col>
                    <v-col cols="9" md="8">
                      <FormField id="linkedin" v-model="linkedin" placeholder="Profile URL"/>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" md="6">
                  <v-row no-gutters>
                    <v-col cols="2">
                      <label for="nintendo" class="formlabel font-weight-bold"> Nintendo </label>
                    </v-col>
                    <v-col cols="9" md="8">
                      <FormField id="nintendo" v-model="nintendo" placeholder="Friend code"/>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" md="6">
                  <v-row no-gutters>
                    <v-col cols="2">
                      <label for="eaid" class="formlabel font-weight-bold"> EA </label>
                    </v-col>
                    <v-col cols="9" md="8">
                      <FormField id="eaid" v-model="eaid" placeholder="EA ID"/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </DefaultCard>
          </v-col>
        </v-row>
      </ValidationObserver>

      <v-overlay v-if="cropper_active" z-index="7" opacity="0.9">
        <div class="d-flex align-center flex-column">
          <cropper
              ref="cropper"
              :src="new_image_uncropped"
              :stencil-props="{
							aspectRatio: 1 / 1,
						}"
              :canvas="{
							minHeight: 0,
							minWidth: 0,
							maxHeight: 512,
							maxWidth: 512,
						}"
              style="max-height: 80vh; max-width: 60vw"
          />
          <div class="d-flex mt-4">
            <Button color="cardBaseColor" class="mr-3" @click="cropper_active = false"> Close</Button>
            <Button @click="applyCropped"> Apply</Button>
          </div>
        </div>
      </v-overlay>

      <v-dialog v-model="dialog" persistent max-width="380" overlay-opacity="0.9">
        <v-card>
          <v-card-title class="text-h5"> Confirm email change</v-card-title>
          <v-card-text>
            <p>Are you sure you want to change to email address to {{ email }}?</p>
            <p>You will have to verify your new email address to be able to log in again.</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="resetEmail" style="text-transform: uppercase"> Cancel</v-btn>
            <v-btn
                color="primary"
                text
                @click="updateEmail"
                :loading="loading"
                :disabled="loading"
                style="text-transform: uppercase"
            >
              Confirm & log out
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </Main>
</template>

<style scoped>
.formlabel {
  display: flex;
  align-items: center;
  min-height: 40px;
}

.message-alert {
  border: none !important;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-bottom: 0;
}

.message-alert >>> .v-alert__wrapper i {
  margin-right: 10px;
}
</style>

<script>
import {mapGetters} from 'vuex';
import Sidebar from '../components/common/Sidebar';
import BackBar from '../components/common/BackBar';
import Main from '../components/common/Main';
import PageHeader from '../components/common/PageHeader';
import Button from '../components/common/elements/Button';
import Tab from '../components/common/elements/Tab';
import DefaultCard from '../components/common/DefaultCard.vue';
import FormField from '../components/common/elements/FormField.vue';
import {ValidationObserver} from 'vee-validate';
import {Cropper} from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

import {gg_update_user, gg_update_email, gg_upload_image, gg_company_info} from '../api';

export default {
  name: 'Settings',

  components: {
    Sidebar,
    BackBar,
    Main,
    PageHeader,
    Button,
    Tab,
    DefaultCard,
    FormField,
    ValidationObserver,
    Cropper,
  },

  data() {
    return {
      name: null,
      phone: null,
      email: null,
      password: null,
      company: null,
      bio: null,
      instagram: null,
      discord: null,
      facebook: null,
      linkedin: null,
      nintendo: null,
      eaid: null,

      image_id: null,
      image: this.$store.getters.getAvatar,
      new_image_uncropped: null,
      new_image_cropped: null,
      new_image_error: '',

      show_password: false,
      loading: false,
      message: null,
      cropper_active: false,
      dialog: false,
    };
  },

  computed: {
    ...mapGetters({
      user_info: 'getUserInfo',
      company_info: 'getCompanyInfo',
    }),
  },

  mounted() {
    if (this.user_info) {
      this.name = this.user_info.name;
      this.phone = this.user_info.phone;
      this.email = this.user_info.email;
      this.company = this.company_info.name;
      this.bio = this.user_info.bio;
      this.instagram = this.user_info.socials.instagram;
      this.discord = this.user_info.socials.discord;
      this.facebook = this.user_info.socials.facebook;
      this.linkedin = this.user_info.socials.linkedin;
      this.nintendo = this.user_info.socials.nintendo;
      this.eaid = this.user_info.socials.eaid;
    }
  },

  methods: {
    drop(event) {
      this.new_image_error = '';
      console.log("drop image");
      // Prevent the default action (open as link for some elements)
      event.preventDefault();

      // Check if a file was dropped
      if (event.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        for (let i = 0; i < event.dataTransfer.items.length; i++) {
          // If dropped items aren't files, reject them
          if (event.dataTransfer.items[i].kind === 'file') {
            const file = event.dataTransfer.items[i].getAsFile();
            // Check if the file is an image
            if (file.type.startsWith('image/')) {
              this.cropper_active = true;
              this.cropperPreview(file);
            } else {
              this.new_image_error = 'File is not an image';
            }
          }
        }
      } else {
        // Use DataTransfer interface to access the file(s)
        for (let i = 0; i < event.dataTransfer.files.length; i++) {
          if (event.dataTransfer.files[i].type.startsWith('image/')) {
            console.log(`2. File[${i}] is an image: ${event.dataTransfer.files[i].name}`);
            this.cropper_active = true;
            this.cropperPreview(event.dataTransfer.files[i])
          } else {
            this.new_image_error = 'File is not an image';
          }
        }
      }
    },

    async updateUser() {
      this.reset();

      this.loading = true;

      var payload = {
        name: this.name,
        phone: this.phone,
        password: this.password,
        bio: this.bio,
        image_id: this.image_id,
        socials: {
          instagram: this.instagram,
          discord: this.discord,
          facebook: this.facebook,
          linkedin: this.linkedin,
          nintendo: this.nintendo,
          eaid: this.eaid,
        },
      };

      try {
        if (this.new_image_cropped) {
          const image_result = await gg_upload_image(this.new_image_cropped);
          payload.image_id = image_result._id;
        }

        const result = await gg_update_user(payload);
        this.$store.commit('updateUser', result);
        const company_info = await gg_company_info(result.company_id);
        this.$store.commit('updateCompany', company_info);

        this.message = {
          type: 'success',
          text: 'Changes saved',
        };
      } catch (err) {
        this.message = {
          type: 'error',
          text: err,
        };
      }

      this.loading = false;

      this.email = this.email.toLowerCase();
      if (this.email !== this.user_info.email) this.dialog = true;
    },

    async updateEmail() {
      this.reset();
      this.loading = true;

      try {
        await gg_update_email(this.email);
        this.$router.push({
          name: 'Login',
          params: {
            emailUpdateSnackbar: this.email,
          },
        });
      } catch (err) {
        this.dialog = false;
        this.message = {
          type: 'error',
          text: err,
        };
      }

      this.loading = false;
    },

    resetEmail() {
      this.email = this.user_info.email;
      this.dialog = false;
    },

    reset() {
      this.message = null;
      this.loading = false;
    },

    eventToFile(e) {
      this.cropperPreview(e.target.files[0]);
    },

    cropperPreview(file) {
      const reader = new FileReader();

      const that = this;
      reader.addEventListener(
          'load',
          function () {
            // convert image file to base64 string
            that.new_image_uncropped = reader.result;
          },
          false
      );

      if (file) {
        reader.readAsDataURL(file);
      }
    },

    applyCropped() {
      const {canvas} = this.$refs.cropper.getResult();
      this.image = canvas.toDataURL();

      const that = this;
      canvas.toBlob(function (blob) {
        that.new_image_cropped = blob;
      }, 'image/png');

      this.cropper_active = false;
    },
  },
};
</script>
