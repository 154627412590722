import gg_axios from '../gg_axios';

async function gg_update_ladder(_id, name, uses_ladder, device, bio, image_id, bg_image_id, tags) {
	try {
		const result = await gg_axios('PUT', `/ladder`, { _id, name, uses_ladder, device, bio, image_id, bg_image_id, tags });

		return result;
	} catch (error) {
		throw error;
	}
}

export default gg_update_ladder;
