<template>
	<v-card flat color="primary" class="rounded-xl my-2">
		<v-row align="center" no-gutters>
			<v-col cols="2">
				<span class="filter text-subtitle-2 font-weight-bold ml-4" :class="activeSort('rank')" @click="sortBy('rank')">
					<span class="hidden-xs-only">Company Rank </span>
					<span class="hidden-sm-and-up">R</span>
				</span>
			</v-col>

			<v-col cols="5">
				<span class="filter text-subtitle-2 font-weight-bold" :class="activeSort('name')" @click="sortBy('name')">
					<span class="hidden-xs-only">Company Name </span>
					<span class="hidden-sm-and-up">Company Name</span>
				</span>
			</v-col>

			<v-col class="d-flex justify-center">
				<span class="filter text-subtitle-2 font-weight-bold" :class="activeSort('players')" @click="sortBy('players')">
					<span class="hidden-xs-only">Players </span>
					<span class="hidden-sm-and-up">Plrs</span>
				</span>
			</v-col>
			<v-col class="d-flex justify-center">
				<span class="filter text-subtitle-2 font-weight-bold" :class="activeSort('matches')" @click="sortBy('matches')">
					<span class="hidden-xs-only">Matches </span>
					<span class="hidden-sm-and-up">Mts</span>
				</span>
			</v-col>
			<v-col class="d-flex justify-center">
				<span class="filter text-subtitle-2 font-weight-bold" :class="activeSort('score')" @click="sortBy('score')">
					<span class="hidden-xs-only">Total Score </span>
					<span class="hidden-sm-and-up">Scr</span>
				</span>
			</v-col>
		</v-row>
	</v-card>
</template>

<style scoped>
.filter {
	cursor: pointer;
	user-select: none;
}

.ascending::after {
	font-family: 'Material Design Icons';
	content: '\F0360';
	font-size: large;
	vertical-align: bottom;
}

.descending::after {
	font-family: 'Material Design Icons';
	content: '\F035D';
	font-size: large;
	vertical-align: bottom;
}
</style>

<script>
export default {
	props: ['value'],

	data() {
		return {
			sort: '',
			sort_ascending: false,
		};
	},

	created() {
		this.sort = 'rank';
		this.$emit('input', { header: this.sort, direction: this.sort_ascending });
	},

	methods: {
		sortBy(header) {
			if (header !== this.sort) this.sort_ascending = true;
			else this.sort_ascending = !this.sort_ascending;
			this.sort = header;
			this.$emit('input', { header: this.sort, direction: this.sort_ascending });
		},
		activeSort(header) {
			return {
				ascending: header === this.sort && this.sort_ascending,
				descending: header === this.sort && !this.sort_ascending,
			};
		},
	},
};
</script>
