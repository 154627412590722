<template>
	<v-hover v-slot="{ hover }">
    <!-- Card for individual ladders -->
		<v-card
      v-if="!data.uses_teams"
			v-bind="$attrs"
			flat
			class="rounded-xl"
			height="300px"
			width="200px"
			style="overflow: hidden"
			:to="{ name: 'LadderView', params: { id: data._id } }"
		>
			<v-img
				:src="image"
				height="100%"
				gradient="180deg, rgba(0,0,0,0.25) 0%, rgba(0,0,0,0) 40%, rgba(83,89,235,0.3) 65%, rgba(83,89,235,0.6) 80%, rgba(83,89,235,0.9) 100%"
			>
				<v-card-text class="d-flex flex-column white--text fill-height">
					<div class="d-flex align-end mb-5">
						<v-icon> mdi-account-group </v-icon>
						<span class="ml-2"> {{ data.count }} {{ $t("ladders.players") }} </span>
						<v-fade-transition
							><v-btn
								v-if="hover && $store.getters.getUserInfo.usertype === 'SUPERUSER'"
								x-small
								icon
								:to="{ name: 'LadderSettings', params: { mode: 'edit', id: data._id } }"
								class="ml-auto"
								><v-icon> mdi-cog </v-icon></v-btn
							></v-fade-transition
						>
					</div>
					<div v-if="type === 'other'" class="d-flex flex-column mb-auto">
            <!-- Depending on whether a ladder uses teams, the signup button shown redirects to the according signup page -->
						<Button class="mb-3" :to="{ name: 'LadderSignup', params: { id: data._id } }"> {{ $t("common.signup") }} </Button>
						<Button class="mb-3" :to="{ name: 'LadderInfo', params: { id: data._id } }"> {{ $t("ladders.info") }} </Button>
						<Button v-if="following" @click="unfollowLadder">
              {{ $t("ladders.unfollow") }}
						</Button>
						<Button v-else @click="followLadder">
              {{ $t("ladders.follow") }}
						</Button>
					</div>
					<div class="d-flex flex-column align-center mt-auto">
						<span class="name-label text-h5 font-weight-bold text-center mb-3"> {{ data.name }} </span>
						<v-chip label outlined small class="platform-label"> {{ data.device }} </v-chip>
					</div>
				</v-card-text>
			</v-img>
    </v-card>

    <!-- Card for team ladders -->
    <v-card
        v-else-if="data.uses_teams"
        v-bind="$attrs"
        flat
        class="rounded-xl"
        height="300px"
        width="200px"
        style="overflow: hidden"
        :to="{ name: 'TeamLadderView', params: { id: data._id } }"
    >
      <v-img
          :src="image"
          height="100%"
          gradient="180deg, rgba(0,0,0,0.25) 0%, rgba(0,0,0,0) 40%, rgba(83,89,235,0.3) 65%, rgba(83,89,235,0.6) 80%, rgba(83,89,235,0.9) 100%"
      >
        <v-card-text class="d-flex flex-column white--text fill-height">
          <div class="d-flex align-end mb-5">
            <v-icon> mdi-account-group </v-icon>
            <span class="ml-2"> {{ data.count }} {{ $t("ladders.teams") }} </span>
            <v-fade-transition
            ><v-btn
                v-if="hover && $store.getters.getUserInfo.usertype === 'SUPERUSER'"
                x-small
                icon
                :to="{ name: 'LadderSettings', params: { mode: 'edit', id: data._id } }"
                class="ml-auto"
            ><v-icon> mdi-cog </v-icon></v-btn
            ></v-fade-transition
            >
          </div>
          <div v-if="type === 'other'" class="d-flex flex-column mb-auto">
            <!-- Depending on whether a ladder uses teams, the signup button shown redirects to the according signup page -->
            <Button class="mb-3" :to="{ name: 'TeamLadderSignup', params: { id: data._id } }"> {{ $t("common.signup") }} </Button>
            <Button class="mb-3" :to="{ name: 'LadderInfo', params: { id: data._id } }"> {{ $t("ladders.info") }} </Button>
            <Button v-if="following" @click="unfollowLadder">
              {{ $t("ladders.unfollow") }}
            </Button>
            <Button v-else @click="followLadder">
              {{ $t("ladders.follow") }}
            </Button>
          </div>
          <div class="d-flex flex-column align-center mt-auto">
            <span class="name-label text-h5 font-weight-bold text-center mb-3"> {{ data.name }} </span>
            <v-chip label outlined small class="platform-label"> {{ data.device }} </v-chip>
          </div>
        </v-card-text>
      </v-img>
    </v-card>
	</v-hover>
</template>

<style scoped>
.platform-label {
	height: 20px;
	padding: 0 8px;
	border-color: rgba(255, 255, 255, 0.3);
}
.platform-label::before {
	background-color: transparent;
}
.name-label {
	line-height: 1.375rem;
}
</style>

<script>
import Button from '../common/elements/Button';
import { GG_ENDPOINT, gg_ladder_follow, gg_ladder_my, gg_ladder_unfollow } from '../../api';

export default {
	name: 'BackBar',

	components: {
		Button,
	},

	props: ['type', 'data'],

	data() {
		return {
			following: false,
			message: null,
			image: this.data.image_id
				? GG_ENDPOINT + '/image/raw/' + this.data.image_id
				: require(`@/assets/images/default-ladder.png`),
		};
	},
	async created() {
		if (this.$store.getters.getLadders.follow.some(e => e._id === this.data._id)) this.following = true;
	},
	methods: {
		async followLadder() {
			try {
				const result = await gg_ladder_follow(this.data._id);
				this.following = true;

				const my_ladders = await gg_ladder_my();
				this.$store.commit('updateLadders', my_ladders);
			} catch (err) {
				this.message = err;
			}
		},

		async unfollowLadder() {
			try {
				const result = await gg_ladder_unfollow(this.data._id);
				this.following = false;

				const my_ladders = await gg_ladder_my();
				this.$store.commit('updateLadders', my_ladders);
			} catch (err) {
				this.message = err;
			}
		},
	},
};
</script>
