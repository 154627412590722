import { render, staticRenderFns } from "./CustomMatch.vue?vue&type=template&id=169ccf50&scoped=true&"
import script from "./CustomMatch.vue?vue&type=script&lang=js&"
export * from "./CustomMatch.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "169ccf50",
  null
  
)

export default component.exports