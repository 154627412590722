import gg_axios from '../gg_axios';

/**
 * Add a player to the ladder
 * @param {string} user_id
 * @param {boolean} is_team
 * @param {string} ladder_id
 * @param {number} frequency - read docs
 * @param {number} availability - read docs
 * @param {number} skill - read docs
 * @returns {object} player static information
 */
async function gg_ladder_add_player(user_id, ladder_id, frequency, availability, skill = null) {
	try {
		const result = await gg_axios('PUT', `/ladder/add/`, {
			user_id,
			ladder_id,
			frequency,
			availability,
			skill,
		});

		return result;
	} catch (error) {
		throw error;
	}
}

export default gg_ladder_add_player;
