<template>
	<v-btn
		v-bind="$attrs"
		v-on="$listeners"
		rounded
		depressed
		class="font-weight-bold"
		:color="color ? color : 'primary'"
		:loading="loading"
		:disabled="disabled"
	>
		<slot></slot>
	</v-btn>
</template>

<script>
export default {
	props: ['color', 'loading', 'disabled'],
};
</script>
