<template>
	<Main background="fifa-background.png">
		<Sidebar />
		<v-container>
			<BackBar />
			<PageHeader :title="name" left="true" />
			<div>
				<span class="text-h5 font-weight-bold primary--text"> {{ $t("ladder.manage_matches") }} </span>
			</div>

			<v-tabs v-model="tab" background-color="transparent" slider-size="4">
				<Button @click="updateMatches" class="mt-2 mr-3">
          {{ $t("matches.refresh") }}
				</Button>
				<Button :to="{ name: 'CustomMatch', params: { id: $route.params.id } }" class="mt-2">
          {{  $t("matches.plan") }}
				</Button>
			</v-tabs>

			<v-tabs v-model="tab" background-color="transparent" slider-size="4">
				<Tab @click="updateMatches" href="#planned"> {{ $t("matches.planned") }} </Tab>
				<Tab @click="updateMatches" href="#played"> {{ $t("matches.register_score") }} </Tab>
				<Tab @click="updateMatches" href="#completed"> {{ $t("matches.completed") }} </Tab>
				<Tab @click="updateMatches" href="#cancelled"> {{ $t("matches.cancelled") }} </Tab>
        <div width="300px" class="ml-auto">
          <SearchBar v-model="search" :placeholder="$t('matches.search')" />
        </div>

				<v-tabs-items v-model="tab" style="background-color: transparent">
					<v-tab-item value="planned">
						<TeamMatchesHeader type="planned" />
						<v-data-iterator
							:items="planned_matches"
							:items-per-page="items_per_page"
							:page="page_planned"
							:length="length_planned"
							:search="search"
							:custom-filter="matchSearch"
							hide-default-footer
							:no-data-text="$t('matches.no_planned')"
						>
							<template v-slot:default="{ items }">
								<TeamMatchesRow
									v-for="item in items"
									:key="item._id"
									:data="item"
									:ranks="player_ranks"
									:highlight="item._id === highlight_id"
									@action="updateMatches"
								/>
							</template>
							<template v-slot:footer>
								<div class="text-center">
									<v-pagination v-model="page_planned" :length="length_planned"></v-pagination>
								</div>
							</template>
						</v-data-iterator>
					</v-tab-item>
					<v-tab-item value="played">
						<TeamMatchesHeader />
						<v-data-iterator
							:items="played_matches"
							:items-per-page="items_per_page"
							:page="page_score"
							:length="length_score"
							:search="search"
							:custom-filter="matchSearch"
							hide-default-footer
							:no-data-text="$t('matches.no_played')"
						>
							<template v-slot:default="{ items }">
								<TeamMatchesRow
									v-for="item in items"
									:key="item._id"
									:data="item"
									:ranks="player_ranks"
									:highlight="item._id === highlight_id"
								/>
							</template>
							<template v-slot:footer>
								<div class="text-center">
									<v-pagination v-model="page_score" :length="length_score"></v-pagination>
								</div>
							</template>
						</v-data-iterator>
					</v-tab-item>
					<v-tab-item value="completed">
						<TeamMatchesHeader />
						<v-data-iterator
							:items="completed_matches"
							:items-per-page="items_per_page"
							:page="page_played"
							:length="length_played"
							:search="search"
							:custom-filter="matchSearch"
							hide-default-footer
							:no-data-text="$t('matches.no_completed')"
						>
							<template v-slot:default="{ items }">
								<TeamMatchesRow
									v-for="item in items"
									:key="item._id"
									:data="item"
									:ranks="player_ranks"
									:highlight="item._id === highlight_id"
								/>
							</template>
							<template v-slot:footer>
								<div class="text-center">
									<v-pagination v-model="page_played" :length="length_played"></v-pagination>
								</div>
							</template>
						</v-data-iterator>
					</v-tab-item>
					<v-tab-item value="cancelled">
						<TeamMatchesHeader />
						<v-data-iterator
							:items="cancelled_matches"
							:items-per-page="items_per_page"
							:page="page_cancelled"
							:length="length_cancelled"
							:search="search"
							:custom-filter="matchSearch"
							hide-default-footer
							:no-data-text="$t('matches.no_cancelled')"
						>
							<template v-slot:default="{ items }">
								<TeamMatchesRow
									v-for="item in items"
									:key="item._id"
									:data="item"
									:ranks="player_ranks"
									:highlight="item._id === highlight_id"
								/>
							</template>
							<template v-slot:footer>
								<div class="text-center">
									<v-pagination v-model="page_cancelled" :length="length_cancelled"></v-pagination>
								</div>
							</template>
						</v-data-iterator>
					</v-tab-item>
				</v-tabs-items>
			</v-tabs>
		</v-container>
	</Main>
</template>

<script>
import Sidebar from '../components/common/Sidebar';
import BackBar from '../components/common/BackBar';
import Main from '../components/common/Main';
import PageHeader from '../components/common/PageHeader';
import Button from '../components/common/elements/Button';
import Tab from '../components/common/elements/Tab';
import SearchBar from '../components/common/elements/SearchBar';
import TeamMatchesRow from '../components/teamladders/TeamMatchesRow';
import TeamMatchesHeader from '../components/teamladders/TeamMatchesHeader';

import { gg_my_matches_ladder, gg_ranked_players, gg_teamladder_info } from '../api';

export default {
	name: 'TeamLadderView',

	components: {
		Sidebar,
		BackBar,
		Main,
		PageHeader,
		Button,
		Tab,
		SearchBar,
		TeamMatchesRow,
		TeamMatchesHeader,
	},

	props: ['highlight_id'],

	data() {
		return {
			message: null,
			search: '',
			name: null,
			planned_matches: [],
			played_matches: [],
			cancelled_matches: [],
			completed_matches: [],
			player_ranks: [],
			highlighted: false,
			page_planned: 1,
			length_planned: 0,
			page_score: 1,
			length_score: 0,
			page_played: 1,
			length_played: 0,
			page_cancelled: 1,
			length_cancelled: 0,
			items_per_page: 10,
		};
	},

	async created() {
		try {
			const result = await gg_teamladder_info(this.$route.params.id);
			if (result) {
				this.name = result.name;
			}
		} catch (err) {
			console.log(err);
			this.message = err;
		}

		await this.updateMatches();
	},

	methods: {
		async updateMatches() {
			try {
				const matches = await gg_my_matches_ladder(this.$route.params.id);
				if (matches) {
					/* swap players to make logged in user player[0]
					for (const match of matches)
						if (match && match.players[1].user_id._id === this.$store.getters.getUserInfo._id) match.players.reverse();
					*/
					this.played_matches = matches.filter(
						match =>
							match &&
							match.time < Date.now() &&
							match.status !== 'PLANNING' &&
							match.status !== 'CANCELLED' &&
							match.status !== 'COMPLETED'
					);
					this.cancelled_matches = matches.filter(
						x => x && !this.played_matches.includes(x) && x.status === 'CANCELLED'
					);
					this.completed_matches = matches.filter(
						x => x && !this.played_matches.includes(x) && x.status === 'COMPLETED'
					);
					this.planned_matches = matches.filter(
						x =>
							x &&
							!this.played_matches.includes(x) &&
							!this.cancelled_matches.includes(x) &&
							!this.completed_matches.includes(x)
					);

					this.planned_matches.sort((a, b) => a.time - b.time);
					this.played_matches.sort((a, b) => a.time - b.time);
					this.completed_matches.sort((a, b) => b.time - a.time);
					this.cancelled_matches.sort((a, b) => b.time - a.time);

					this.length_planned = Math.ceil(this.planned_matches.length / this.items_per_page);
					this.length_score = Math.ceil(this.played_matches.length / this.items_per_page);
					this.length_played = Math.ceil(this.completed_matches.length / this.items_per_page);
					this.length_cancelled = Math.ceil(this.cancelled_matches.length / this.items_per_page);
				}

				// to get player ranks
				const ranks = await gg_ranked_players(this.$route.params.id);
				if (ranks) {
					this.player_ranks = ranks;
				}
			} catch (err) {
				console.log(err);
				this.message = err;
			}
		},
		matchSearch(items, search) {
			search = search.toString().toLowerCase();
      // console.log(items)
			return items.filter(
				e =>
					e.players[0].team_id.name.toLowerCase().includes(search) ||
					e.players[1].team_id.name.toLowerCase().includes(search)
			);
		},
	},

	computed: {
		tab: {
			set(tab) {
				if (!this.highlighted) {
					this.$router.replace({ query: { ...this.$route.query, tab }, params: { highlight_id: this.highlight_id } });
					this.highlighted = true;
				} else this.$router.replace({ query: { ...this.$route.query, tab } });
			},
			get() {
				return this.$route.query.tab;
			},
		},
	},
};
</script>
