import gg_axios from '../gg_axios';

/**
 * Add an user to admin
 * @param {string} id of the user 
 * @returns {object} retruns added admin details
 */
async function gg_add_admin(id) {
	try {
		const result = await gg_axios('PUT', `/company/admin/add/${id}`);

		return result;
	} catch (error) {
		throw error;
	}
}

export default gg_add_admin;
