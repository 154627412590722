<template>
	<Main background="fifa-background.png">
    <Sidebar/>
    <v-container>
      <BackBar />
      <PageHeader :title=team_name left="true" class="mt-12 mb-2"> </PageHeader>

      <v-col cols="12">
        <DefaultCard title="Current team members">
          <v-row no-gutters>
            <v-col cols="12" lg="10">

              <v-list class="rounded-xl" style="background-color: var(--v-background-base); overflow: hidden">
                <v-row no-gutters class="px-4 text-caption grey--text">
                  <v-spacer />
                  <v-col cols="3">
                    <span> Username </span>
                  </v-col>
                  <v-col cols="8">
                    <span> {{ $t("common.email") }} </span>
                  </v-col>
                </v-row>
                <v-list-item v-for="(item, i) in team_members" :key="i" ripple>
                  <v-row no-gutters align="center">
                    <v-col cols="1">
                      <router-link :to="{ name: 'PlayerProfile', params: { id: item._id } }">
                        <v-avatar color="black" size="32">
                          <v-img
                              :src="
                                    item.image_id
                                      ? endpoint + '/image/raw/' + item.image_id
                                      : require(`@/assets/images/default-avatar.png`)
                                  "
                          />
                        </v-avatar>
                      </router-link>
                    </v-col>
                    <v-col cols="3">
                    <router-link :to="{ name: 'PlayerProfile', params: { id: item._id } }">
                      <span class="text-subtitle-1" style="color: white;">
                        {{ item.name }}
                      </span>
                    </router-link>
                    </v-col>
                    <v-col cols="2">
                      <span> {{ item.email }} </span>
                    </v-col>
                    <v-col v-if="item._id === creator._id">
                      <span> {{$t('team.manage.manager')}} </span>
                    </v-col>
                    <v-btn
                        v-if="item._id !== creator._id && $store.getters.getUserInfo._id === creator._id"
                        elevation="0"
                        class="rounded-pill"
                        color="red"
                        height="20px"
                        @click="removeUser(item._id)"
                    >
                      {{ $t("team.manage.remove") }}
                    </v-btn>
                    <v-btn
                        v-if="item._id !== creator._id && $store.getters.getUserInfo._id !== creator._id"
                        elevation="0"
                        class="rounded-pill"
                        color="red"
                        height="20px"
                        @click="removeUser(item._id)"
                    >
                      {{ $t("team.manage.leave") }}
                    </v-btn>
                  </v-row>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </DefaultCard>
      </v-col>

      <PageHeader :title="$t('profile.my_stats')" left="true" class="mt-12 mb-2"> </PageHeader>
      <div>
        <v-select
            v-model="select"
            :items="ladders"
            item-text="ladder_id.name"
            return-object
            class="dropdown mt-0 pt-0"
            placeholder="Select ladder"
            background-color="primary"
            height="36"
            rounded
            style="width: 350px"
        ></v-select>
      </div>
      <LadderStats :stats="getSelectedStats" />

    </v-container>
	</Main>
</template>

<style scoped>
.dropdown >>> .mdi-menu-down::before {
	color: white;
}
</style>

<script>
import Sidebar from '../components/common/Sidebar';
import DefaultCard from '../components/common/DefaultCard';
import BackBar from '../components/common/BackBar';
import Main from '../components/common/Main';
import PageHeader from '../components/common/PageHeader';
import LadderStats from '../components/profile/LadderStats';

import {gg_team_byid, gg_team_remove_user, gg_team_stat} from '../api';

export default {
	name: 'TeamProfile',

	components: {
		Sidebar,
    DefaultCard,
		BackBar,
		Main,
		PageHeader,
		LadderStats,
	},

	data() {
		return {
			select: null,
      team: null,
      team_name: null,
      team_members: [],
      creator: null,
      ladders: [],
		};
	},

	async created() {
		try {
			this.team = await gg_team_byid(this.$route.params.id);
      this.team_name = this.team.name;
      this.team_members = this.team.users;
      this.creator = this.team.creator;

      const stats = await gg_team_stat(
          this.$route.params.id ? this.$route.params.id : this.$store.getters.getUserInfo._id
      );
      this.ladders = stats.ladders;
      this.ladders.unshift({ ladder_id: { name: 'All ladders' }, user: stats.all_stats });
      this.select = this.ladders[0];
		} catch (err) {
			console.log(err);
		}
	},

  methods: {
    async removeUser(user_id) {
      try {
        await gg_team_remove_user(this.$route.params.id, user_id);
        this.team_members = this.team_members.filter((user) => user._id !== user_id);
      } catch (err) {
        console.log(err);
      }
    },
  },

	computed: {
		getSelectedStats() {
			if (this.select) return this.select.user;
			else return { matches: 0, wins: 0, losses: 0, draws: 0 };
		},
	},
};
</script>
