<template>
  <Main background="default-ladder-bg.jpg" :image_id="bg_image_id">
    <v-container class="d-flex flex-column justify-center" style="height: 100%; width: 100%">
      <BackBar class="mt-0 align-self-start" />

      <v-card flat color="primary" class="signup-card rounded-xl pa-4">
        <div class="d-flex justify-center">
          <span class="text-h5 font-weight-bold"> {{ $t("common.signup") }} | {{ name }} </span>
        </div>

        <v-stepper flat v-model="page" style="background-color: transparent">
          <v-expand-transition>
<!--            <div v-if="page < 5" class="d-flex flex-column align-center">-->
<!--              <span class="text-subtitle-2 mb-2 mt-6"> {{ $t("ladder.signup.step") }} {{ page }} {{ $t("ladder.signup.of") }} 4 </span>-->
<!--              <v-progress-linear-->
<!--                  class="progress-bar"-->
<!--                  :value="page * 25"-->
<!--                  color="primary"-->
<!--                  height="25"-->
<!--                  style="border-radius: 12px"-->
<!--              ></v-progress-linear>-->
<!--            </div>-->
          </v-expand-transition>
          <v-stepper-items>
            <v-stepper-content step="1">
              <div class="d-flex flex-column align-center stepper-item">
                <span class="text-h6 font-weight-bold"> {{ $t("ladder.signup.info") }} </span>
                <span v-if="bio" class="text-h7" style="white-space: pre-line;">{{ bio }}</span>
              </div>
              <div class="d-flex justify-center">
                <Button class="mr-1" color="background" @click="page = 10"> {{ $t("ladder.signup.team.create") }} </Button>
                <Button class="ml-1" color="background" @click="page = 11"> {{ $t("ladder.signup.team.join") }} </Button>
              </div>
            </v-stepper-content>
<!--            <v-stepper-content step="2">-->
<!--              <div class="d-flex flex-column align-center stepper-item">-->
<!--                <span class="text-h6 font-weight-bold"> {{ $t("ladder.signup.frequency") }} </span>-->
<!--                <span class="text-h7"> {{ $t("ladder.signup.select_freq") }} </span>-->
<!--                <v-row class="d-flex justify-center mt-5 mb-5">-->
<!--                  <Button class="mr-2" color="background" @click="page = 3; addFrequency(1)"> {{ $t("ladder.signup.1per1") }} </Button>-->
<!--                  <Button class="mr-2" color="background" @click="page = 3; addFrequency(2)"> {{ $t("ladder.signup.2per2") }} </Button>-->
<!--                  <Button class="mr-2" color="background" @click="page = 3; addFrequency(3)"> {{ $t("ladder.signup.3per4") }} </Button>-->
<!--                </v-row>-->
<!--              </div>-->
<!--            </v-stepper-content>-->
            <v-stepper-content step="2">
              <div class="d-flex flex-column align-center stepper-item">
                <span class="text-h6 font-weight-bold"> {{ $t("ladder.signup.availability") }} </span>
                <span class="text-h7"> {{ $t("ladder.signup.select_avail") }} </span>
                <v-row class="d-flex justify-center mt-5 mb-5">
                  <v-btn v-bind:class="{'green': mon, 'red': !mon}" class="mr-2" @click="mon = !mon"> {{ $t("ladder.signup.mon") }} </v-btn>
                  <v-btn v-bind:class="{'green': tue, 'red': !tue}" class="mr-2" @click="tue = !tue"> {{ $t("ladder.signup.tue") }} </v-btn>
                  <v-btn v-bind:class="{'green': wed, 'red': !wed}" class="mr-2" @click="wed = !wed"> {{ $t("ladder.signup.wed") }} </v-btn>
                  <v-btn v-bind:class="{'green': thu, 'red': !thu}" class="mr-2" @click="thu = !thu"> {{ $t("ladder.signup.thu") }} </v-btn>
                  <v-btn v-bind:class="{'green': fri, 'red': !fri}" class="mr-2" @click="fri = !fri"> {{ $t("ladder.signup.fri") }} </v-btn>
                  <v-btn v-bind:class="{'green': sat, 'red': !sat}" class="mr-2" @click="sat = !sat"> {{ $t("ladder.signup.sat") }} </v-btn>
                  <v-btn v-bind:class="{'green': sun, 'red': !sun}" class="mr-2" @click="sun = !sun"> {{ $t("ladder.signup.sun") }} </v-btn>
                </v-row>

                <div class="d-flex justify-center">
                  <Button color="background" class="mr-2" @click="page = 1"> {{ $t("ladder.signup.previous") }} </Button>
                  <Button color="background" @click="page = 3; addAvailabity()"> {{ $t("ladder.signup.next") }} </Button>
                </div>
              </div>
            </v-stepper-content>

<!--            <v-stepper-content step="4">-->
<!--              <div class="d-flex flex-column align-center stepper-item">-->
<!--                <span class="text-h6 font-weight-bold"> {{ $t("ladder.signup.skill") }} </span>-->
<!--                <span class="text-h7"> {{ $t("ladder.signup.select_skill") }} </span>-->
<!--                <v-row class="mt-5 mb-5">-->
<!--                  <Button class="mr-2 justify-center" color="background" @click="page = 5; addSkillLevel(1)"> {{ $t("ladder.signup.beginner") }} </Button>-->
<!--                  <Button class="mr-2" color="background" @click="page = 5; addSkillLevel(2)"> {{ $t("ladder.signup.intermediate") }} </Button>-->
<!--                  <Button class="mr-2" color="background" @click="page = 5; addSkillLevel(3)"> {{ $t("ladder.signup.pro") }} </Button>-->
<!--                </v-row>-->
<!--                <div class="d-flex justify-center">-->
<!--                  <Button color="background" class="mr-2 justify-center" @click="page = 3"> {{ $t("ladder.signup.previous") }} </Button>-->
<!--                </div>-->
<!--              </div>-->
<!--            </v-stepper-content>-->
            <span class="text-h7 mb-4 background--text"> {{ $t("common.team") }} {{ team_name }}</span>

            <v-stepper-content step="3">
              <div class="d-flex flex-column align-center stepper-item">
                <span class="text-h6 font-weight-bold mb-4"> {{ $t("ladder.signup.overview") }} </span>
                <!--								<span class="text-h7 font-weight-bold"> Frequency of games </span>-->
                <!--								<span class="text-subtitle-2 font-weight-bold background&#45;&#45;text">-->
                <!--									{{ frequency ? frequency : 'No choice' }}-->
                <!--								</span>-->
                <span class="text-h7 font-weight-bold"> {{ $t("ladder.signup.availability") }} </span>
                <span class="text-subtitle-2 font-weight-bold background--text">
									{{ availability.length ? availability.join(', ') : $t("ladder.signup.no_choice") }}
								</span>
                <!--								<span class="text-h7 font-weight-bold"> Skill level </span>-->
                <!--								<span class="text-subtitle-2 font-weight-bold background&#45;&#45;text">-->
                <!--									{{ skill ? skill : 'No choice' }}-->
                <!--								</span>-->

                <span class="text-h7 font-weight-bold mt-4 mb-6"> {{ $t("ladder.signup.right") }} </span>
                <div class="d-flex justify-center mt-auto">
                  <Button color="background" class="mr-2" @click="page = 4"> {{ $t("ladder.signup.previous") }} </Button>
                  <Button color="background" @click="addRung" :loading="loading" :disabled="loading">
                    {{ $t("ladder.signup.yes") }}
                  </Button>
                </div>
              </div>
            </v-stepper-content>

            <v-stepper-content step="6">
              <div class="d-flex flex-column align-center justify-center stepper-item">
                <span class="text-h6 font-weight-bold"> {{ $t("ladder.signup.team.created") }} </span>
                <span class="text-h7 text-center"> {{ $t("ladder.signup.team.signed_up") }} {{ name }}. </span>
                <span class="text-h7 text-center"> {{ $t("ladder.signup.team.other_join") }} </span>
                <div class="d-flex justify-center mt-6">
                  <Button color="background" to="/ladders"> Ok </Button>
                </div>
              </div>
            </v-stepper-content>

            <v-stepper-content step="10">
              <div class="d-flex flex-column align-center stepper-item">
                <span class="text-h6 font-weight-bold"> {{ $t("ladder.signup.team.give_name") }} </span>
                <span class="text-subtitle-2 text-center mb-6"> {{ $t("ladder.signup.team.disc") }} </span>
                <v-row no-gutters class="mb-5 mt-5">
                  <v-col cols="12" md="4">
                    <label for="team_name" class="formlabel font-weight-bold"> {{ $t("ladder.signup.team.team_name") }} </label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <FormField id="team_name" v-model="team_name" rules="required" :placeholder="$t('ladder.signup.team.team_name_placeholder')" />
                  </v-col>
                </v-row>
                <div class="d-flex justify-center">
                  <Button color="background" class="mr-1 justify-center" @click="page = 1"> {{ $t("common.cancel") }} </Button>
                  <Button color="background" class="ml-1 justify-center" @click="createTeam()"> {{ $t("ladder.signup.team.create") }} </Button>
                </div>
              </div>
            </v-stepper-content>

            <v-stepper-content step="11">
              <div class="d-flex flex-column align-center stepper-item">
                <span class="text-h6 font-weight-bold"> {{ $t("ladder.signup.team.find_team") }} </span>
                <span class="text-subtitle-2 text-center mb-6"> {{ $t("ladder.signup.team.join_a_team") }} </span>
                <v-row no-gutters class="mb-5 mt-5">
                  <v-select
                      v-model="team_to_join"
                      :items="ladder_teams"
                      :item-text="item => item.team_id.name"
                      :item-value="item => item.team_id._id"
                      :placeholder="$t('ladder.signup.team.team_placeholder')"
                      background-color="background"
                      rounded
                      style="min-width: 80%;"
                  ></v-select>
                </v-row>
                <div class="d-flex justify-center">
                  <Button color="background" class="mr-1 justify-center" @click="page = 1"> {{ $t("common.cancel") }} </Button>
                  <Button color="background" class="ml-1 justify-center" @click="joinTeam()"> {{ $t("ladder.signup.team.join") }} </Button>
                </div>
              </div>
            </v-stepper-content>

            <v-stepper-content step="12">
              <div class="d-flex flex-column align-center justify-center stepper-item">
                <span class="text-h6 font-weight-bold"> Your request to join {{ team_name }} has been sent. </span>
                <span class="text-h7 text-center"> The owner of the team will have to accept or deny your request. </span>
                <div class="d-flex justify-center mt-6">
                  <Button color="background" to="/ladders"> Ok </Button>
                </div>
              </div>
            </v-stepper-content>

          </v-stepper-items>
          <span v-if="message" class="text-caption red--text text--accent-2 ml-4"> {{ message }} </span>
        </v-stepper>
      </v-card>
    </v-container>
  </Main>
</template>

<style scoped>
.formlabel {
  display: flex;
  align-items: center;
  min-height: 40px;
}
.stepper-item {
  margin-top: 20px;
  margin-bottom: 20px;
}
.progress-bar >>> .v-progress-linear__background {
  left: 0% !important;
  width: 100% !important;
  opacity: 100% !important;
  background-color: var(--v-background-base) !important;
}
.progress-bar >>> .v-progress-linear__determinate {
  border-radius: 12px;
  opacity: 50%;
}
</style>

<script>
import BackBar from '../components/common/BackBar';
import Main from '../components/common/Main';
import Button from '../components/common/elements/Button';
import FormField from "../components/common/elements/FormField";

import { gg_teamladder_add_team, gg_ladder_info, gg_ladder_my, gg_add_team, gg_teamladder_teams, gg_team_request, gg_team_byid } from '../api';

export default {
  name: 'LadderSignup',

  components: {
    BackBar,
    Main,
    Button,
    FormField,
  },

  data() {
    return {
      page: 1,
      name: null,
      bg_image_id: null,
      frequency: null,
      availability: [],
      skill: null,
      bio: null,
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
      sun: false,

      team_name: null,
      team_id: null,
      team_to_join: null,
      ladder_teams: null,

      frequency_items: ["1 match per week", '2 matches per 2 weeks', '3 matches per 4 weeks'],
      availability_items: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      skill_items: ['Beginner', 'Intermediate', 'Pro'],

      loading: false,
      message: null,
    };
  },

  async created() {
    try {
      const result = await gg_ladder_info(this.$route.params.id);
      if (result) {
        this.name = result.name;
        this.bg_image_id = result.bg_image_id;
        this.bio = result.bio ? result.bio : null;
      }
      this.ladder_teams = await gg_teamladder_teams(this.$route.params.id);


    } catch (err) {
      console.log(err);
      this.message = err;
    }
  },

  methods: {

    async createTeam() {
      try {
        const team = await gg_add_team(this.team_name)
        if (team) {
          this.team_id = team._id;
        }
        this.page = 2;
      } catch (err) {
        console.log(err);
        this.message = err;
      }
    },

    async joinTeam() {
      try {
        await gg_team_request(this.team_to_join, this.$store.getters.getUserInfo._id);
        const team = await gg_team_byid(this.team_to_join);
        this.team_name = team.name;
        this.page = 12;
      } catch (err) {
        console.log(err);
        this.message = err;
      }
    },

    addFrequency(freq) {
      if (freq === 1) {
        this.frequency = "1 match per week"
      } else if (freq === 2) {
        this.frequency = "2 matches per 2 weeks"
      } else if (freq===3) {
        this.frequency = "3 matches per 4 weeks"
      }
    },

    addSkillLevel(skill) {
      if (skill===1) {
        this.skill = "Beginner"
      } else if (skill===2) {
        this.skill = "Intermediate"
      } else if (skill===3) {
        this.skill = "Pro"
      }
    },

    addAvailabity() {
      this.availability = [];
      if(this.mon) {this.availability.push('Monday') }
      if(this.tue) {this.availability.push('Tuesday') }
      if(this.wed) {this.availability.push('Wednesday') }
      if(this.thu) {this.availability.push('Thursday') }
      if(this.fri) {this.availability.push('Friday') }
      if(this.sat) {this.availability.push('Saturday') }
      if(this.sun) {this.availability.push('Sunday') }
    },

    async addRung() {
      this.reset();
      this.loading = true;

      try {
        await gg_teamladder_add_team(
            this.team_id,
            this.$route.params.id,
            this.frequency_items.indexOf(this.frequency) + 1,
            this.availability.map(x => this.availability_items.indexOf(x) + 1),
            this.skill_items.indexOf(this.skill) + 1
        );

        const my_ladders = await gg_ladder_my();
        // console.log(my_ladders);
        this.$store.commit('updateLadders', my_ladders);

        this.page = 6;
      } catch (err) {
        this.message = err;
      }

      this.loading = false;
    },

    reset() {
      this.message = null;
      this.loading = false;
    },
  },
};
</script>
