<template>
	<div>
		<v-menu
			ref="menu"
			v-model="menu"
			:close-on-content-click="false"
			transition="scale-transition"
			offset-y
			:nudge-right="20"
			min-width="auto"
		>
			<template v-slot:activator="{ on }">
				<v-text-field
					v-model="selected"
					v-on:input="$emit('input', $event)"
					class="picker flex-grow-0"
					filled
					rounded
					dense
					height="42"
					background-color="cardBaseColor"
					:placeholder="$t('misc.date')"
					append-icon="mdi-menu-down"
					readonly
					hide-details
					v-on="on"
					value
				></v-text-field>
			</template>
			<v-date-picker v-model="selected" @input="menu = false" color="primary"></v-date-picker>
		</v-menu>
	</div>
</template>

<style scoped>
.picker >>> input::placeholder {
	color: white;
	opacity: 1;
}
.picker >>> input {
	cursor: pointer;
}
</style>

<script>
export default {
	props: ['value'],

	data() {
		return {
			menu: false,
		};
	},

	computed: {
		selected: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
};
</script>