<template>
	<Main background="fifa-background.png">
		<Sidebar />
		<v-container>
			<BackBar />
			<PageHeader :title="name" style="min-height: 36px">

			</PageHeader>

			<v-row class="mb-4">
				<v-col>
          <Button color="cardBaseColor" class="mr-3" :to="{ name: 'LadderInfo', params: { id: $route.params.id } }">
            {{ $t("ladder.ladder_info") }}
          </Button>
					<Button v-if="team_creator" :to="{ name: 'TeamManageMatches', params: { id: $route.params.id } }">
            {{ $t("ladder.manage_matches") }}
					</Button>
				</v-col>
			</v-row>

			<v-tabs v-model="tab" background-color="transparent" slider-size="4">
				<Tab> {{ $t("ladder.ranking") }} </Tab>
				<Tab> {{ $t("ladder.matchlog") }} </Tab>
<!--				<Tab> {{ $t("ladder.company_ranking") }} </Tab>-->
				<div width="300px" class="ml-auto">
					<SearchBar v-model="search" placeholder="Type to search" class="mt-2" />
				</div>
				<v-tabs-items class="tab-items" v-model="tab" style="background-color: transparent">
					<v-tab-item>
						<TeamLadderHeader v-model="sort_players" />
						<v-data-iterator
							:items="ladder_players"
							:items-per-page="items_per_page"
							:page="page_players"
							:length="length_players"
							:search="search"
							:custom-filter="ladderSearch"
							:sort-by="sort_players.header"
							:sort-desc="sort_players.direction"
							hide-default-footer
							:no-data-text="$t('ladder.no_players')"
						>
							<template v-slot:default="{ items }">
								<TeamLadderRow v-for="item in items" :key="item._id" :data="item" />
							</template>
							<template v-slot:footer>
								<div class="text-center">
									<v-pagination v-model="page_players" :length="length_players"></v-pagination>
								</div>
							</template>
						</v-data-iterator>
					</v-tab-item>
					<v-tab-item>
						<TeamMatchesHeader />
						<v-data-iterator
							:items="ladder_matches"
							:items-per-page="items_per_page"
							:page="page_matches"
							:length="length_matches"
							:search="search"
							:custom-filter="matchSearch"
							hide-default-footer
							:no-data-text="$t('ladder.no_matches_completed')"
						>
							<template v-slot:default="{ items }">
								<TeamMatchesRow v-for="item in items" :key="item._id" :data="item" :ranks="ladder_players" />
							</template>
							<template v-slot:footer>
								<div class="text-center">
									<v-pagination v-model="page_matches" :length="length_matches"></v-pagination>
								</div>
							</template>
						</v-data-iterator>
					</v-tab-item>
<!--					<v-tab-item>-->
<!--						<CompanyHeader v-model="sort_companies" />-->
<!--						<v-data-iterator-->
<!--							:items="ladder_companies"-->
<!--							:items-per-page="items_per_page"-->
<!--							:page="page_companies"-->
<!--							:length="length_companies"-->
<!--							:search="search"-->
<!--							:custom-filter="companySearch"-->
<!--							:sort-by="sort_companies.header"-->
<!--							:sort-desc="sort_companies.direction"-->
<!--							hide-default-footer-->
<!--							:no-data-text="$t('ladder.no_companies')"-->
<!--						>-->
<!--							<template v-slot:default="{ items }">-->
<!--								<CompanyRow v-for="item in items" :key="item._id" :data="item" />-->
<!--							</template>-->
<!--							<template v-slot:footer>-->
<!--								<div class="text-center">-->
<!--									<v-pagination v-model="page_companies" :length="length_companies"></v-pagination>-->
<!--								</div>-->
<!--							</template>-->
<!--						</v-data-iterator>-->
<!--					</v-tab-item>-->
				</v-tabs-items>
			</v-tabs>
		</v-container>
	</Main>
</template>

<script>
import Sidebar from '../components/common/Sidebar';
import BackBar from '../components/common/BackBar';
import Main from '../components/common/Main';
import PageHeader from '../components/common/PageHeader';
import Button from '../components/common/elements/Button';
import TeamLadderRow from '../components/teamladders/TeamLadderRow';
import TeamLadderHeader from '../components/teamladders/TeamLadderHeader';
import TeamMatchesRow from '../components/teamladders/TeamMatchesRow';
import TeamMatchesHeader from '../components/teamladders/TeamMatchesHeader';
import Tab from '../components/common/elements/Tab';
import SearchBar from '../components/common/elements/SearchBar';

import { gg_match_all, gg_teamladder_info, gg_ranked_players } from '../api';

export default {
	name: 'LadderView',

	components: {
		Sidebar,
		BackBar,
		Main,
		PageHeader,
		Button,
		TeamLadderRow,
		TeamLadderHeader,
		TeamMatchesRow,
		TeamMatchesHeader,
		Tab,
		SearchBar,
	},

	data() {
		return {
			tab: null,
			message: null,
			name: null,
			ladder_players: [],
			ladder_matches: [],
			ladder_companies: [],
			search: '',
			page_players: 1,
			length_players: 0,
			page_matches: 1,
			length_matches: 0,
			page_companies: 1,
			length_companies: 0,
			items_per_page: 10,
			sort_players: {},
			sort_companies: {},
      my_team: null,
      team_creator: false,
		};
	},

	async created() {
		try {
			const result = await gg_teamladder_info(this.$route.params.id);
			if (result) {
				this.name = result.name;
			}

			const players = await gg_ranked_players(this.$route.params.id);
			if (players) {
				this.ladder_players = players;
				this.length_players = Math.ceil(players.length / this.items_per_page);
			}
      this.team_creator = players.some(e => e.team_id.creator === this.$store.getters.getUserInfo._id);
			const matches = await gg_match_all(this.$route.params.id);
			if (matches) {
				this.ladder_matches = matches.filter(match => match.status === 'COMPLETED');
				this.ladder_matches.sort((a, b) => b.time - a.time);
				this.length_matches = Math.ceil(this.ladder_matches.length / this.items_per_page);
				// console.log(JSON.stringify(matches));
			}
		} catch (err) {
			console.log(err);
			this.message = err;
		}

		// // populate companies list
		// for (const player of this.ladder_players) {
		// 	if (!this.ladder_companies.some(e => e._id === player.user_id.company_id._id)) {
		// 		let company = player.user_id.company_id;
		// 		company.players = 0;
		// 		company.matches = 0;
		// 		company.points = 0;
		// 		company.score = 0;
		// 		company.rank = 0;
		// 		this.ladder_companies.push(company);
		// 	}
		// 	let i = this.ladder_companies.findIndex(e => e._id === player.user_id.company_id._id);
		// 	this.ladder_companies[i].players++;
		// 	this.ladder_companies[i].points += player.elo;
		// }
    //
    // for (let i = 0; i < this.ladder_companies.length; i++) {
    //   this.ladder_companies[i].score = 0;
    //   if (this.ladder_companies[i].players > 0) {
    //     this.ladder_companies[i].score = this.ladder_companies[i].points / this.ladder_companies[i].players;
    //   }
    // }
    // this.length_companies = Math.ceil(this.ladder_companies.length / this.items_per_page);
    //
    // for (const match of this.ladder_matches) {
		// 	// counting number of matches for each company
		// 	var companies = [];
		// 	for (const player of match.players)
		// 		if (companies.indexOf(player.user_id.company_id._id) === -1) companies.push(player.user_id.company_id._id);
		// 	for (const company_id of companies) {
		// 		const index = this.ladder_companies.findIndex(e => e._id === company_id);
		// 		this.ladder_companies[index].matches++;
		// 	}
		// }
		// this.ladder_companies.sort((a, b) => b.score - a.score);
		// for (var i = 0; i < this.ladder_companies.length; i++) this.ladder_companies[i].rank = i + 1;
	},

	methods: {
		ladderSearch(items, search) {
			search = search.toString().toLowerCase();
			return items.filter(e => e.team_id.name.toLowerCase().includes(search));
		},
		matchSearch(items, search) {
			search = search.toString().toLowerCase();
			return items.filter(
				e =>
					e.players[0].team_id.name.toLowerCase().includes(search) ||
					e.players[1].team_id.name.toLowerCase().includes(search)
			);
		},
		companySearch(items, search) {
			search = search.toString().toLowerCase();
			return items.filter(e => e.name.toLowerCase().includes(search) || e.city.toLowerCase().includes(search));
		},
	},
};
</script>
