import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from './plugins/vuetify';
import VueCookies from 'vue-cookies';
import VueI18n from "vue-i18n";
import en from './locales/en.json'
import nl from './locales/nl.json'
import de from './locales/de.json'

Vue.use(VueI18n);
Vue.use(VueCookies);

const locale = sessionStorage.getItem('locale') || 'en';

const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: 'en',
  messages: { nl, en, de },
  silentTranslationWarn: true,
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");

export default i18n