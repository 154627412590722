<template>
  <Transition name="modal">
    <v-container>
      <div v-if="show" class="modal-mask">
        <div class="middle">
          <v-card color="cardBaseColor" class="rounded-xl mt-8 white--text pa-2">
            <v-card-title>Welcome to Esportsladder!</v-card-title>
            <v-card-text class="white--text text-body-1">
                <v-col cols="12" sm="12">
                  <p>
                    You are almost there...
                    <br/> <br/>
                    Join one of the ladders. The ladders can be accessed by clicking on "Ladders" in the left pane.
                    <br/>
                    To enter a ladder, take the following steps:
                    <br/>
                    1. Go to the ladders page <br/>
                    2. Choose a game you want to compete in <br/>
                    3. Click Sign up <br/>
                    <br/>
                    Once you have signed up, you will receive a message once every week that a match is ready for you.
                  </p>

<!--                  <v-col cols="3">-->
<!--                    <v-img-->
<!--                        :src="image"-->
<!--                    />-->
<!--                  </v-col>-->
                </v-col>

                <Button color="red" class="mt-4 mr-2" @click="$emit('close')">
                  Close
                </Button>
                <Button color="green" class="mt-4" to="/ladders/">
                  Take me to the ladder page
                </Button>
            </v-card-text>

          </v-card>
        </div>
      </div>
    </v-container>
  </Transition>
</template>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: table;
  transition: opacity 0.3s ease;
}

.middle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
</style>

<script>
import Button from "../common/elements/Button";

export default {
  props: {
    show: Boolean
  },

  components: {
    Button
  },

  data() {
    return {
      // image: require(`@/assets/images/ladder-signup.png`)
    }
  }

};

</script>
