<template>
  <Main background="fifa-background.png">
    <Sidebar />
    <v-container>
      <BackBar />
      <PageHeader title="My Ladders">
        <Button v-if="$store.getters.getUserInfo.usertype === 'SUPERUSER'" to="/ladder/add"> Create Team </Button>
      </PageHeader>

<!--      <v-data-iterator-->
<!--          :items="my_teams"-->
<!--          :items-per-page="-1"-->
<!--          hide-default-footer-->
<!--          no-data-text="You are not part of a team"-->
<!--      >-->
<!--        <template v-slot:default="{ items }">-->
<!--          <v-row class="ml-n4 mt-n4" no-gutters>-->
<!--            <div v-for="item in items" :key="item._id" class="ma-4">-->
<!--              <TeamCard :data="item" />-->
<!--            </div>-->
<!--          </v-row>-->
<!--        </template>-->
<!--      </v-data-iterator>-->
  </v-container>
  </Main>

</template>

<script>
import Sidebar from '../components/common/Sidebar';
import BackBar from '../components/common/BackBar';
import Main from '../components/common/Main';
import PageHeader from '../components/common/PageHeader';
import LadderCard from "@/components/ladders/LadderCard.vue";
import Button from "@/components/common/elements/Button.vue";
import SearchBar from "@/components/common/elements/SearchBar.vue";

import { gg_team_my } from '../api';

export default {
  name: 'Teams',

  components: {
    Sidebar,
    BackBar,
    Button,
    Main,
    PageHeader
  },

  data() {
    return {
      my_teams: '',
      search: '',
    };
  },

  async created() {
    try {
      const my_teams = await gg_team_my();
      console.log(my_teams)
      if (my_teams) {
        this.my_teams = my_teams;
      }
      console.log(this.my_teams)
    } catch (err) {
      console.log(err);
      this.message = err;
    }
  }

}

</script>