<template>
	<v-card flat color="primary" class="rounded-xl my-2">
		<v-row align="center" no-gutters>
			<v-col cols="2">
				<span class="text-subtitle-2 font-weight-bold ml-4">
					<span class="hidden-xs-only"> {{ $t("common.date") }} </span>
					<span class="hidden-sm-and-up">Dt</span>
				</span>
			</v-col>

			<v-col cols="3">
				<span class="text-subtitle-2 font-weight-bold">
					<span class="hidden-xs-only"> {{ $t("common.player") }} ID </span>
					<span class="hidden-sm-and-up"> {{ $t("common.player") }} </span>
				</span>
			</v-col>

			<v-col class="d-flex justify-center">
				<span class="text-subtitle-2 font-weight-bold">
					<span class="hidden-xs-only"> {{ $t("common.player") }} {{ $t("common.rank") }} </span>
					<span class="hidden-sm-and-up"></span>
				</span>
			</v-col>

			<v-col class="d-flex justify-center">
				<span class="text-subtitle-2 font-weight-bold">
          <template v-if="type === 'planned'">
            {{ $t("common.time") }}
          </template>
          <template v-else>
            Score
          </template>
        </span>
			</v-col>

			<v-col class="d-flex justify-center">
				<span class="text-subtitle-2 font-weight-bold">
					<span class="hidden-xs-only">{{ $t("common.player") }} {{ $t("common.rank") }} </span>
					<span class="hidden-sm-and-up"></span>
				</span>
			</v-col>

			<v-col cols="3" class="d-flex justify-end">
				<span class="text-subtitle-2 font-weight-bold">
					<span class="hidden-xs-only">{{ $t("common.player") }} ID </span>
					<span class="hidden-sm-and-up">{{ $t("common.player") }}</span>
				</span>
			</v-col>

			<v-spacer />
		</v-row>
	</v-card>
</template>

<script>
export default {
	props: ['type'],
};
</script>
