<template>
  <v-card
      flat
      color="background"
      class="rounded-xl my-2"
      style="overflow: hidden"
  >
    <v-row align="center" no-gutters>
      <v-col
          class="d-flex justify-center align-center align-self-stretch"
          cols="2"
          style="background-color: var(--v-primary-base)"
      >
        <span class="text-h6 font-weight-bold"> {{ convertToDate(notif.created_at) }}</span>
      </v-col>

      <v-col cols="1" />

      <v-col cols="3">
        <span class="text-h7 font-weight-bold">{{ notificationText }}</span>
      </v-col>

      <v-col v-if="notif.ladder_id && !use_team" cols="2">
        <router-link :to="{name: 'LadderView', params: {id: notif.ladder_id}}">
          <span class="text-h7 font-weight-bold" style="color: white">{{ ladderName }}</span>
        </router-link>
      </v-col>

      <v-col v-else-if="notif.ladder_id && use_team" cols="2">
        <router-link :to="{name: 'TeamLadderView', params: {id: notif.ladder_id}}">
          <span class="text-h7 font-weight-bold" style="color: white">{{ ladderName }}</span>
        </router-link>
      </v-col>

      <v-col v-else cols="1" />

      <v-col v-if="opp && opp_score && !use_team" cols="1">
        <router-link :to="{name: 'PlayerProfile', params: {id: opp._id}}">
          <span class="text-h7 font-weight-bold" style="color: white">{{ opp.name }}</span>
        </router-link>
      </v-col>

      <v-col v-else-if="opp && !opp_score && !use_team">
        <router-link :to="{name: 'PlayerProfile', params: {id: opp._id}}">
          <span class="text-h7 font-weight-bold" style="color: white">{{ opp.name }}</span>
        </router-link>
      </v-col>

      <v-col v-else-if="opp && opp_score && use_team" cols="1">
        <router-link :to="{name: 'TeamProfile', params: {id: opp._id}}">
          <span class="text-h7 font-weight-bold" style="color: white">{{ opp.name }}</span>
        </router-link>
      </v-col>

      <v-col v-else-if="opp && !opp_score && use_team">
        <router-link :to="{name: 'TeamProfile', params: {id: opp._id}}">
          <span class="text-h7 font-weight-bold" style="color: white">{{ opp.name }}</span>
        </router-link>
      </v-col>

      <v-col v-else/>

      <v-col v-if="notif.type === 'MATCH_SCORED'" class="d-flex">
        <span class="text-h7 font-weight-bold hidden-sm-and-down">{{ my_score }}-{{ opp_score }}</span>
      </v-col>

      <v-col v-if="notif.ladder_id" cols="1" class="d-flex">
        <!-- Link to match page when match is scored -->
        <router-link
            v-if="matches_link"
            style="text-decoration: none"
            :to="{name: 'ManageMatches',
            params: { id: notif.ladder_id },
            query: {tab: matches_tab}}"
        >
          <v-btn icon color="primary">
            <v-icon> mdi-arrow-right </v-icon>
          </v-btn>
        </router-link>
        <!-- Link to ladder page -->
        <router-link v-else style="text-decoration: none" :to="{name: 'LadderView', params: { id: notif.ladder_id }}">
          <v-btn icon color="primary">
            <v-icon> mdi-arrow-right </v-icon>
          </v-btn>
        </router-link>
      </v-col>

<!--      <v-col cols="1" class="d-flex justify-center">-->
<!--        <v-btn icon color="primary">-->
<!--          <v-icon> mdi-eye </v-icon>-->
<!--        </v-btn>-->
<!--      </v-col>-->
    </v-row>
  </v-card>
</template>

<script>
import {gg_ladder_info, gg_team_byid, gg_user_info} from "@/api";
import i18n from '@/main.js'
export default {
  props: ['notif'],

  name: 'NotifRow',

  data() {
    return {
      type: null,
      ladderName: null,
      my_score: null,
      opp_score: null,
      matches_link: false,
      matches_tab: null,
      opp: null,
      use_team: false,
    }
  },

  async created() {
    this.type = this.notif.type;
    // Get the name of the ladder if needed
    if (this.notif.ladder_id) {
      await this.setLadderInfo(this.notif.ladder_id)

    }
    // Get the match scores if needed
    if (this.notif.match_id) {
      if (this.notif.type === 'MATCH_SCORED') {
        const current_userid = this.$store.getters.getUserInfo._id;
        const match = this.notif.match_id;
        const player = match.players.find(p => p.user_id === current_userid);
        this.my_score = player.stats.my_goal;
        this.opp_score = player.stats.opponent_goal;
      }
      // Check if link to matches or to ladder is needed
      if (['MATCH_SCORED', 'SCORE_OBJECTED'].includes(this.type)) {
        this.matches_link = true;
        this.matches_tab = 'played'
      } else if (['NEW_MATCH', 'NEW_MATCH_AUTO', 'MATCH_ACCEPTED', 'TIME_SET'].includes(this.type)) {
        this.matches_link = true;
        this.matches_tab = 'planned'
      } else if (['MATCH_DECLINED', 'OBJECTED_TOO_MUCH'].includes(this.type)) {
        this.matches_link = true;
        this.matches_tab = 'cancelled'
      }
      // Get the opponent name if needed
      const current_userid = this.$store.getters.getUserInfo._id;
      const match = this.notif.match_id;
      // If the opponent is a user
      if (match.players[0].user_id) {
        const player = match.players.find(p => p.user_id !== current_userid);
        this.opp = await gg_user_info(player.user_id);
        this.opp_name = this.opp.name;
      // If the opponent is a team
      } else {
        for (const p of match.players) {
          const team = await gg_team_byid(p.team_id)
          if (team.creator._id !== current_userid) {
            this.opp = team;
            break;
          }
        }
      }
    }
  },

  methods: {

    convertToDate(timestamp) {
      const d = new Date(timestamp);
      return d.toISOString().slice(0,10);
    },

    async setLadderInfo(ladder_id) {
      const ladder = await gg_ladder_info(ladder_id);
      this.ladderName = ladder.name
      this.use_team = ladder.uses_teams
    }
  },

  computed: {
    notificationText() {
      switch(this.notif.type) {
        case "NEW_MATCH":
          return i18n.t('notifs.custom_match');
        case "NEW_MATCH_AUTO":
          return i18n.t('notifs.ranked_match');
        case "SCORED_MATCH":
          return i18n.t('notifs.scored_match');
        case "SCORE_OBJECTED":
          return i18n.t('notifs.score_object');
        case "MATCH_ACCEPTED":
          return i18n.t('notifs.match_accepted');
        case "MATCH_DECLINED":
          return i18n.t('notifs.match_declined');
        case "TIME_SET":
          return i18n.t('notifs.time_set');
        case "OBJECTED_TOO_MUCH":
          return i18n.t('notifs.objected_too_much');
        case "MATCH_SCORED":
          return i18n.t('notifs.match_scored');
        case "TEAM_CREATED":
          return i18n.t('notifs.team_created');
        case "TEAM_JOIN_DECLINED":
          return i18n.t('notifs.team_join_declined');
        case "TEAM_JOIN_ACCEPTED":
          return i18n.t('notifs.team_join_accepted');
        case "TEAM_JOIN_REQUEST":
          return i18n.t('notifs.team_join_request');
        default:
          return i18n.t('notifs.unknown');
      }
    }
  }
};

</script>

<style scoped>

</style>