<template>
	<DefaultCard :title="$t('dashboard.top3.my_top')" height="100%">
		<div v-if="!ladders.length" class="d-flex justify-center">
			<span class="text--disabled">
          {{ $t("ladders.no_ladders") }}
				<router-link to="/ladders" style="text-decoration: none">
            {{ $t("dashboard.top3.view_all") }}
        </router-link>
			</span>
		</div>
		<div v-else>
			<v-card flat color="primary" class="rounded-xl my-2">
				<v-row align="center" no-gutters>
					<v-col cols="2">
						<span class="text-subtitle-2 font-weight-bold ml-4 hidden-xs-only"> {{ $t("common.rank") }} </span>
						<span class="text-subtitle-2 font-weight-bold ml-4 hidden-sm-and-up"> {{ $t("common.rnk") }} </span>
					</v-col>

					<v-col cols="5">
						<span class="text-subtitle-2 font-weight-bold"> Ladder </span>
					</v-col>

					<v-col class="d-flex justify-center">
						<span class="text-subtitle-2 font-weight-bold"> {{ $t("common.matches") }} </span>
					</v-col>

					<v-col class="d-flex justify-center">
						<span class="text-subtitle-2 font-weight-bold hidden-xs-only"> {{ $t("common.score") }} </span>
						<span class="text-subtitle-2 font-weight-bold hidden-sm-and-up"> {{ $t("common.score") }} </span>
					</v-col>
				</v-row>
			</v-card>

			<div v-for="(item, index) in ladders.slice(0, 3)" :key="index">
				<v-card
					:to="{ name: 'LadderView', params: { id: item.ladder_id._id } }"
					flat
					color="background"
					class="rounded-xl my-2"
					style="overflow: hidden"
				>
					<v-row align="center" no-gutters>
						<v-col
							class="d-flex justify-center align-center align-self-stretch"
							cols="1"
							style="background-color: var(--v-primary-base)"
						>
							<span class="text-h6 font-weight-bold"> {{ item.user.rank }} </span>
						</v-col>

						<v-col cols="1" />

						<v-col cols="5" class="d-flex">
							<span class="text-h6 font-weight-bold"> {{ item.ladder_id.name }} </span>
						</v-col>

						<v-col class="d-flex justify-center">
							<span class="text-h6 font-weight-bold"> {{ item.user.matches }} </span>
						</v-col>

						<v-col class="d-flex justify-center">
							<span class="text-h6 font-weight-bold primary--text"> {{ ladders[index].user.elo }} </span>
						</v-col>
					</v-row>
				</v-card>
			</div>
		</div>
	</DefaultCard>
</template>

<script>
import DefaultCard from '../common/DefaultCard';

export default {
	name: 'Top3',

	components: {
		DefaultCard,
	},

	props: ['ladders'],
};
</script>
