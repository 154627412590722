import gg_axios from '../gg_axios';

/**
 * Obtain dashboard stats of logged in user
 * @returns {Object} {ladders: [Ladder + stats], all_stats, matches: [PLANNING or PLANNED Matches]}
 */
export default async function () {
    try {
        const result = await gg_axios('GET', `/stats/dash`);

        return result;
    } catch (error) {
        throw error;
    }
}
