<template>
  <DefaultCard :title="$t('dashboard.teams.title')" height="100%">
    <div v-if="!teams" class="d-flex justify-center">
			<span class="text--disabled">
          {{ $t("dashboard.teams.none") }}
      </span>
    </div>
    <div v-else>
      <v-row no-gutters>
        <v-col cols="12" lg="12">

          <v-list class="rounded-xl" style="background-color: var(--v-background-base); overflow: hidden">
            <v-list-item v-for="(team, index) in teams" :key="index" ripple>
              <v-row no-gutters align="center">
                <v-col cols="2">
                  <router-link :to="{ name: 'TeamProfile', params: { id: team._id } }">
                    <v-avatar color="black" size="32">
                      <v-img
                          :src="
                                  team.image_id
                                    ? endpoint + '/image/raw/' + team.image_id
                                    : require(`@/assets/images/default-avatar.png`)
                                "
                      />
                    </v-avatar>
                  </router-link>
                </v-col>
                <v-col cols="8">
                  <router-link :to="{ name: 'TeamProfile', params: { id: team._id } }">
                    <span class="text-subtitle-1" style="color: white;">
                      {{ team.name }}
                    </span>
                  </router-link>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </div>
  </DefaultCard>
</template>

<script>
import DefaultCard from '../common/DefaultCard';

export default {
  name: 'MyTeams',

  components: {
    DefaultCard,
  },

  props: ['teams'],
};
</script>