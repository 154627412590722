import axios from 'axios';

import store from '../store';

export const GG_ENDPOINT = process.env.NODE_ENV === 'production' ? window.location.origin + '/api' : process.env.VUE_APP_APIURL;

/**
 * Make a Request to GGHANDLER_ENDPOINT ${GG_ENDPOINT}
 * @param {string} type - POST | PUT | GET
 * @param {string} endpoint -  Endpoint of the request (eg. /login or /tasks/:user_id)
 * @param {Object} data -  Body corresponding to the request that gets JSON.stringify()
 * @param {string} [content_type='application/json'] -  Sets the `Content-Type` header for the request. Default - `application/json`
 * @returns Data if the status code == 200 else error is thrown
 * @throws Error based on the STATUS Codes
 */
async function gg_axios(type, endpoint, data = null, content_type = 'application/json') {
	if (content_type == 'application/json') {
		data = data ? JSON.stringify(data) : {};
	}

	try {
		const response = await axios({
			method: type.toUpperCase(),
			url: GG_ENDPOINT + endpoint,
			headers: {
				'Content-type': content_type,
				Authorization: store.getters.getUser ? store.getters.getUser.token : null,
			},
			data,
		});

		// Update token after every succesful request
		if (response.status === 200 && response.data.message === 'success') {
			store.commit('login', {
				...store.getters.getUser,
				token: response.data.token ? response.data.token : store.getters.getUser ? store.getters.getUser.token : null,
			});

			return response.data.result;
		}
	} catch (err) {
		console.log(err);
		// console.log(err.response);
		if (err.response) {
			throw new Error(err.response.data.message);
		} else if (err.request) {
			throw new Error('Something went wrong. Please try again. (1)');
		} else {
			throw new Error('Something went wrong. Please try again. (2)');
		}
	}
}

export default gg_axios;
