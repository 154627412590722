<template>
	<div class="my-8 d-flex">
		<span class="text-h5 font-weight-bold" :class="left ? 'mr-2' : 'mr-auto'">{{ title }}</span>
		<slot></slot>
	</div>
</template>

<script>
export default {
	props: ['title', 'left'],
};
</script>
