import gg_axios from '../gg_axios';

async function gg_team_byid(team_id) {
    try {
        const result = await gg_axios('GET', `/team/teambyid/${team_id}`);

        return result;
    } catch (error) {
        throw error;
    }
}

export default gg_team_byid;