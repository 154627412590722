<template>
	<Main background="default-ladder-bg.jpg" :image_id="bg_image_id">
		<v-container class="d-flex flex-column justify-center" style="height: 100%; width: 100%">
			<BackBar class="mt-0 align-self-start" />

			<v-card flat color="cardBaseColor" class="rounded-xl">
				<v-row no-gutters>
					<v-col cols="12" lg="8">
						<div class="pa-10">
							<span class="text-h5 font-weight-bold"> {{ name }} </span>
							<div v-if="bio">
								<p class="text-body-2 my-4" style="white-space: pre-line;">{{ bio }}</p>
							</div>

							<div v-else>
								<p class="text--disabled my-4"> {{ $t("ladder.info.no_desc") }}</p>
							</div>

							<div class="d-flex align-center mt-6">
								<Button v-if="signedup" color="green" @click="dialog = true">
									<v-icon left> mdi-check </v-icon> {{ $t("ladder.info.signed_up") }}
								</Button>
								<Button v-else :to="{ name: 'LadderSignup', params: { id: $route.params.id } }"> {{ $t("common.signup") }} </Button>
								<Button v-if="following || signedup" @click="unfollowLadder" :disabled="signedup" class="ml-2">
									<v-icon left> mdi-playlist-check </v-icon> {{ $t("ladders.following") }}
								</Button>
								<Button v-else @click="followLadder" class="ml-2">
									<v-icon left> mdi-playlist-plus </v-icon> {{ $t("ladders.follow") }}
								</Button>
								<v-btn
									v-if="$store.getters.getUserInfo.usertype === 'SUPERUSER'"
									class="ml-2"
									icon
									small
									:to="{ name: 'LadderSettings', params: { mode: 'edit', id: $route.params.id } }"
									><v-icon>mdi-cog</v-icon></v-btn
								>
							</div>
						</div>
					</v-col>
					<v-col>
						<v-card flat color="primary" class="d-flex flex-column rounded-xl pa-10 fill-height">
							<span class="text-h7 font-weight-bold"> {{ $t("ladder.info.total_players") }} </span>
							<span class="stat-label text-h5 font-weight-bold"> {{ player_count }} </span>

							<span class="text-h7 font-weight-bold mt-4"> {{ $t("ladder.info.platform") }} </span>
							<div>
								<v-chip label outlined small class="platform-label"> {{ platform }} </v-chip>
							</div>

							<!--

							<span class="text-h7 font-weight-bold mt-4"> Game modes </span>
							<div class="ml-n1">
								<v-chip pill color="background" class="ma-1"> Team vs. team </v-chip>
								<v-chip pill color="background" class="ma-1"> 2 vs. 2 </v-chip>
								<v-chip pill color="background" class="ma-1"> 3 vs. 3 </v-chip>
							</div>

							<span class="text-h7 font-weight-bold mt-4"> Games per week </span>
							<span class="stat-label text-h5 font-weight-bold"> 156 </span>

							<span class="text-h7 font-weight-bold mt-4"> Popularity </span>
							<span class="text-caption mt-n1"> New players per week </span>
							<span class="stat-label text-h5 font-weight-bold"> 156 </span>

							<span class="text-h7 font-weight-bold mt-4"> Community </span>
							<span class="text-subtitle-2"> Active </span>

							-->
						</v-card>
					</v-col>
				</v-row>
			</v-card>

			<v-dialog v-model="dialog" max-width="400" overlay-opacity="0.9">
				<v-card>
					<v-card-title class="text-h5"> {{ $t("ladder.info.leave") }} </v-card-title>
					<v-card-text>
						<p> {{ $t("ladder.info.sure", {ladder: name}) }}</p>
						<p>
              {{ $t("ladder.info.disc") }}
						</p>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="primary" text @click="dialog = false" style="text-transform: uppercase"> {{ $t("common.cancel") }} </v-btn>
						<v-btn
							text
							color="errorColor"
							@click="removePlayer"
							:loading="loading"
							:disabled="loading"
							style="text-transform: uppercase"
						>
              {{ $t("ladder.info.leave") }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-container>
	</Main>
</template>

<style scoped>
.stat-label {
	line-height: 1.75rem;
}
</style>

<script>
import BackBar from '../components/common/BackBar';
import Main from '../components/common/Main';
import Button from '../components/common/elements/Button';

import {
	gg_ladder_info,
	gg_ladder_remove_player,
	gg_ladder_my,
	gg_ladder_all,
	gg_ladder_follow,
	gg_ladder_unfollow,
} from '../api';

export default {
	name: 'LadderInfo',

	components: {
		BackBar,
		Main,
		Button,
	},

	data() {
		return {
			message: null,
			name: null,
			platform: null,
			bg_image_id: null,
			bio: null,
			signedup: false,
			following: false,
			dialog: false,
			loading: false,
			player_count: 0,
		};
	},

	async created() {
		try {
			const result = await gg_ladder_info(this.$route.params.id);
			if (result) {
				this.name = result.name;
				this.platform = result.device;
				this.bg_image_id = result.bg_image_id;
				this.bio = result.bio ? result.bio : null;
			}

			const all = await gg_ladder_all();
			this.player_count = all.find(e => e._id === this.$route.params.id)?.count;
		} catch (err) {
			console.log(err);
			this.message = err;
		}

		if (this.$store.getters.getLadders.ranked.some(e => e._id === this.$route.params.id)) this.signedup = true;
		if (this.$store.getters.getLadders.follow.some(e => e._id === this.$route.params.id)) this.following = true;
	},

	methods: {
		async removePlayer() {
			this.message = null;
			this.loading = true;

			try {
				const result = await gg_ladder_remove_player(this.$route.params.id);
				this.signedup = false;
				this.dialog = false;

				const my_ladders = await gg_ladder_my();
				this.$store.commit('updateLadders', my_ladders);
			} catch (err) {
				this.message = err;
			}

			this.loading = false;
		},

		async followLadder() {
			this.message = null;
			this.loading = true;

			try {
				const result = await gg_ladder_follow(this.$route.params.id);
				this.following = true;

				const my_ladders = await gg_ladder_my();
				this.$store.commit('updateLadders', my_ladders);
			} catch (err) {
				this.message = err;
			}

			this.loading = false;
		},

		async unfollowLadder() {
			this.message = null;
			this.loading = true;

			try {
				const result = await gg_ladder_unfollow(this.$route.params.id);
				this.following = false;

				const my_ladders = await gg_ladder_my();
				this.$store.commit('updateLadders', my_ladders);
			} catch (err) {
				this.message = err;
			}

			this.loading = false;
		},
	},
};
</script>
