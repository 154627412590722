import gg_axios from '../gg_axios';

async function gg_add_team(name) {
    try {
        const result = await gg_axios('POST', `/team`, { name});

        return result;
    } catch (error) {
        throw error;
    }
}

export default gg_add_team;
