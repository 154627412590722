import gg_axios from '../gg_axios';

// Use this only
const REACTION_TYPE = {
    LIKE: 'LIKE',
    CONGRATS: 'CONGRATS',
    IMPRESS: 'IMPRESS',
};

/**
 * React to post on a post
 * @param {*} post_id - REQUIRED
 * @param {string} reaction_type - REQUIRED !!!! HAS TO BE OF TYPE -> `REACTION_TYPE`
 * @returns 
 */
export default async function (post_id, reaction_type) {
    try {
        return gg_axios('PUT', `/social/${reaction_type}/post/${post_id}`);
    } catch (error) {
        throw error;
    }
}

