<template>
	<Main background="fifa-background.png">
		<Sidebar />
		<v-container>
			<BackBar />
			<PageHeader title="Notifications">
				<Button color="primary"> Clear notifications </Button>
			</PageHeader>

			<Notification />
		</v-container>
	</Main>
</template>

<script>
import Sidebar from '../components/common/Sidebar';
import BackBar from '../components/common/BackBar';
import Main from '../components/common/Main';
import PageHeader from '../components/common/PageHeader';
import Button from '../components/common/elements/Button';
import Notification from '../components/notifications/Notification';

export default {
	name: 'PlayerProfile',

	components: {
		Sidebar,
		BackBar,
		Main,
		PageHeader,
		Button,
		Notification,
	},
};
</script>