<template>
	<Main background="login-background.png">
		<v-row class="px-1 fill-height justify-end" no-gutter>
			<v-col cols="12" sm="8" md="5" align-self="center">
				<p class="text-h4 font-weight-bold text-center">Esportsladder</p>
				<v-card flat class="rounded-xl" color="cardBaseColor" style="z-index: 1">
					<v-card-text class="px-8 py-8">
						<ValidationObserver v-slot="{ handleSubmit }">
							<v-form
								@submit.prevent="
									signup ? handleSubmit(signUp) : forgotpassword ? handleSubmit(recoverPassword) : handleSubmit(logIn)
								"
							>
								<div v-if="!mailsent">
									<v-expand-transition>
										<div v-if="signup">
											<label for="name" class="ml-6">
                          {{ $t('login.name') }}
                      </label>
											<FormField id="name" v-model="name" rules="required|alpha_num|max_user:25" />
											<label for="phone" class="ml-6" style="display: inline-block;">
                          {{ $t("login.phone") }}
											</label>
											<FormField id="phone" v-model="phone" rules="required|min_phone:10|max_phone:12" />
										</div>
									</v-expand-transition>
									<label for="email" class="ml-6" style="display: inline-block;">
                    {{ $t("login.email") }}
                  </label>
									<FormField id="email" v-model="email" rules="required|email" />
									<v-expand-transition>
										<div v-if="!forgotpassword">
											<label for="password" class="ml-6">
                          {{ $t("login.password") }}
                      </label>
											<FormField
												id="password"
												type="password"
												v-model="password"
												rules="required|min_password:8|verify_password"
											/>
										</div>
									</v-expand-transition>
									<v-btn
										color="primary"
										rounded
										block
										depressed
										:loading="loading"
										:disabled="loading"
										:error-messages="errormessage"
										type="submit"
										@click="email = email.toLowerCase()"
									>
                    <template v-if="signup">
                      {{ $t("common.signup") }}
                    </template>
                    <template v-else-if="forgotpassword">
                      {{ $t("login.recover_pass") }}
                    </template>
                    <template v-else>
                      {{ $t("login.login") }}
                    </template>
									</v-btn>
									<span v-if="errormessage" class="text-caption red--text text--accent-2"> {{ errormessage }} </span>
								</div>
								<div class="d-flex flex-column align-center" v-else>
									<v-icon x-large color="primary"> mdi-check-circle-outline </v-icon>
									<span class="text-subtitle-1 font-weight-bold">
                    <template v-if="mailsent === 'recovery'">
                      {{ $t("login.recovery_sent") }}
                    </template>
                    <template v-else>
                      {{ $t("login.signed_up") }}
                    </template>
									</span>
									<span class="text-caption">
                    <template v-if="mailsent === 'recovery'">
                      {{ $t("login.res_pass") }}
                    </template>
                    <template v-else>
                      {{ $t("login.verify_email") }}
                    </template>
									</span>
								</div>
							</v-form>
						</ValidationObserver>
					</v-card-text>
<!--					<v-card v-if="false" elevation="0" class="rounded-xl pt-15 mt-n15" color="primary" style="z-index: 0">-->
<!--						<v-card-text class="d-flex px-8">-->
<!--							<p class="ma-0">Or {{ signup ? 'sign up' : 'log in' }} with</p>-->
<!--							<div class="d-flex flex-grow-1 justify-space-around px-8">-->
<!--								<v-icon> mdi-google </v-icon>-->
<!--								<v-icon> mdi-facebook </v-icon>-->
<!--								<v-icon> mdi-discord </v-icon>-->
<!--							</div>-->
<!--						</v-card-text>-->
<!--					</v-card>-->
					<div v-if="!mailsent" class="d-flex justify-center text-subtitle-2 font-weight-bold mt-4">
						<p class="mb-2">
              <template v-if="signup">
                {{ $t("login.already_acc") }}
              </template>
              <template v-else>
                {{ $t("login.no_acc") }}
              </template>
							<a @click.prevent="toggleForm">
                <template v-if="signup">
                  {{ $t("login.login") }}
                </template>
                <template v-else>
                  {{ $t("login.create_acc") }}
                </template>
              </a>
						</p>
					</div>
					<div v-if="login" class="d-flex justify-center text-subtitle-2 font-weight-bold mt-4 pb-4">
						<a @click.prevent="forgotPassword">
              <template>
                {{ $t("login.forgot_pass") }}
              </template>
            </a>
					</div>
				</v-card>
			</v-col>
		</v-row>

		<v-snackbar v-model="snackbar">
			Check your inbox at {{ emailUpdateSnackbar }} to verify your email address

			<template v-slot:action="{ attrs }">
				<v-btn color="primary" text v-bind="attrs" @click="snackbar = false"> OK </v-btn>
			</template>
		</v-snackbar>
	</Main>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import FormField from '../components/common/elements/FormField';
import Main from '../components/common/Main';


import { gg_login, gg_signup, gg_company_info, gg_ladder_my, gg_forgot_pass } from '../api';

export default {
	components: {
		ValidationObserver,
		FormField,
		Main,
	},

	props: ['emailUpdateSnackbar'],

	data() {
		return {
			name: null,
			phone: null,
			email: null,
			password: null,
			errormessage: null,
			signup: false,
      login: true,
			forgotpassword: false,
			mailsent: null,
			loading: false,
			snackbar: false,
		};
	},

	mounted() {
		if (this.emailUpdateSnackbar) {
			setTimeout(() => {
				this.snackbar = true;
			}, 100);
		}
	},

	methods: {
		async logIn() {
			this.reset();

			this.loading = true;

			try {
				const result = await gg_login(this.email, this.password);
				this.$store.commit('updateUser', result);
				const company_info = await gg_company_info(result.company_id);
				this.$store.commit('updateCompany', company_info);
				const my_ladders = await gg_ladder_my();
				this.$store.commit('updateLadders', my_ladders);
        await this.$router.push(this.$router.currentRoute.query.redirect || 'Dashboard');
			} catch (err) {
				this.errormessage = err;
			}

			this.loading = false;
		},

		async signUp() {
			this.reset();

			this.loading = true;

			try {
				const result = await gg_signup(this.name, this.phone, this.email, this.password);
				this.mailsent = 'signup';
			} catch (err) {
				this.errormessage = err;
			}

			this.loading = false;
		},

		async recoverPassword() {
			this.reset();

			this.loading = true;

			try {
				const result = await gg_forgot_pass(this.email);
				if (result) this.mailsent = 'recovery';
			} catch (err) {
				this.errormessage = err;
			}

			this.loading = false;
		},

		reset() {
			this.errormessage = null;
			this.loading = false;
      this.login = false;
		},

		toggleForm() {
			this.errormessage = null;
			this.forgotpassword = false;
			this.name = null;
			this.phone = null;
			this.email = null;
			this.password = null;
			this.signup = this.signup ? false : true;
      this.login = this.login ? false : true;
      console.log(this.login)
		},

		forgotPassword() {
			this.errormessage = null;
			this.forgotpassword = this.forgotpassword ? false : true;
		},
	},
};
</script>
