import gg_axios from '../gg_axios';

/**
 * Obtain user specific notifications
 * @param user_id
 * @returns {object} {result: [Notification]}
 */
export default async function (user_id) {
    try {
        const result = await gg_axios('GET', `/notif/${user_id}`);

        return result;
    } catch (error) {
        throw error;
    }
}
