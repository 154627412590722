import gg_axios from '../gg_axios';

/**
 * Obtain user specific stats
 * @returns {Object} {ladders: [Ladder + stats], all_stats}
 */
export default async function (user_id) {
    try {
        const result = await gg_axios('GET', `/stats/user/${user_id}`);

        return result;
    } catch (error) {
        throw error;
    }
}
