<template>
	<v-text-field
		v-bind="$attrs"
		v-on="$listeners"
		filled
		rounded
		dense
		hide-details
		background-color="cardBaseColor"
		class="ladder-search"
		v-bind:value="value"
		v-on:input="$emit('input', $event)"
	></v-text-field>
</template>

<style scoped>
.ladder-search >>> .v-input__slot {
	min-height: 36px !important;
}
.ladder-search >>> .v-text-field__slot input {
	padding: 0px;
}
</style>

<script>
export default {
	props: ['value'],
};
</script>