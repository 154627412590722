<template>
	<div>
		<!-- please create a responsive menu top bar in vuejs 2 using vuetify -->
		<v-app-bar
			app
			color="primary"
			dark
			:clipped-left="$vuetify.breakpoint.lgAndUp"
			:clipped-right="$vuetify.breakpoint.lgAndUp"
			:clipped="$vuetify.breakpoint.smAndDown"
			fixed
			:height="$vuetify.breakpoint.smAndDown ? '56px' : '64px'"
			:prominent="$vuetify.breakpoint.smAndDown"
			:src="$vuetify.breakpoint.smAndDown ? '' : '/images/logo.png'"
		>
			<v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="$vuetify.breakpoint.smAndDown" />

			<v-toolbar-title class="font-arial font-weight-bold">ESPORTSLADDER.GG</v-toolbar-title>

			<v-navigation-drawer v-model="drawer" app temporary>
				<v-list>
					<v-list-item v-for="item in menuItems" :key="item.title" @click.prevent="goSection(item.path)">
						<v-list-item-title>{{ item.title }}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-navigation-drawer>

			<!-- Loop over menuitems and create a v-btn for each item -->
			<v-toolbar-items class="ml-8" v-if="!$vuetify.breakpoint.smAndDown">
				<v-btn
					color="primary"
					v-for="item in menuItems"
					:key="item.title"
					@click.prevent="goSection(item.path)"
					class="font-arial"
				>
					{{ item.title }}
				</v-btn>
			</v-toolbar-items>
		</v-app-bar>
	</div>
</template>

<script>
import HomepageVue from '../../views/Homepage.vue';
export default {
	components: {
		HomepageVue,
	},
	data() {
		return {
			drawer: false,
			menuItems: [
				{ title: 'Introduction', path: 'introduction' },
				{ title: 'Ladder competition', path: 'ladder-competition' },
				{ title: 'Business and students', path: 'business-and-students' },
			],
		};
	},
	methods: {
		goSection(val) {
			this.$emit('go-section', val);
		},
	},
};
</script>

<style scoped>
.font-arial {
	font-family: 'Arial Narrow', Arial, sans-serif !important;
	color: white !important;
}
.list-item {
	background: none;
}
</style>
