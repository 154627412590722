import gg_axios from '../gg_axios';

/**
 * Comment on a post
 * @param {*} post_id - REQUIRED
 * @param {string} data - REQUIRED
 * @returns 
 */
export default async function (post_id, data) {
    try {
        return gg_axios('PUT', `/social/comment/`, {
            post_id, data
        });
    } catch (error) {
        throw error;
    }
}

