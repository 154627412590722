<template>
	<Main background="default-ladder-bg.jpg" :image_id="bg_image_id">
		<RightPanel>
			<span class="text-h5 font-weight-bold"> {{ $t("matches.create.create") }} </span>
			<span class="text-caption font-weight-bold"> {{ $t("matches.create.create_sub") }} </span>

			<span class="text-h5 font-weight-bold mt-6"> {{ $t("matches.create.select_opp") }} </span>
			<span class="text-caption font-weight-bold"> {{ $t("matches.create.select_opp_sub") }} </span>

			<v-autocomplete
				v-model="opponent"
        v-if="ladder_players.length > 0 && ladder_players[0].user_id"
				class="mt-4 flex-grow-0"
				filled
				rounded
				dense
				hide-details
				height="42"
				menu-props="rounded"
				:placeholder="$t('matches.create.select_opp')"
				background-color="cardBaseColor"
				:items="ladder_players"
				item-text="user_id.name"
				return-object
				:filter="searchFilter"
			>
				<template v-slot:no-data>
					<v-list-item>
						<v-list-item-title> {{ $t("matches.create.no_users") }} </v-list-item-title>
					</v-list-item>
				</template>
				<template v-slot:item="{ item }">
					<v-list-item-avatar v-if="item.user_id">
						<img
							:src="
								item.user_id.image_id
									? endpoint + '/image/raw/' + item.user_id.image_id
									: require(`@/assets/images/default-avatar.png`)
							"
						/>
					</v-list-item-avatar>
          <!-- Temporary solution to display the default avatar for teams -->
          <v-list-item-avatar v-else>
            <img :src="require(`@/assets/images/default-avatar.png`)"/>
          </v-list-item-avatar>

					<v-list-item-content v-if="item.user_id">
						<v-list-item-title v-text="item.user_id.name"></v-list-item-title>
						<v-list-item-subtitle v-text="item.user_id.company_id.name"></v-list-item-subtitle>
					</v-list-item-content>

          <v-list-item-content v-else>
            <v-list-item-title v-text="item.team_id.name"></v-list-item-title>
          </v-list-item-content>

				</template>
			</v-autocomplete>

      <v-autocomplete
          v-model="opponent"
          v-else-if="ladder_players.length > 0 && ladder_players[0].team_id"
          class="mt-4 flex-grow-0"
          filled
          rounded
          dense
          hide-details
          height="42"
          menu-props="rounded"
          :placeholder="$t('matches.create.select_opp')"
          background-color="cardBaseColor"
          :items="ladder_players"
          item-text="team_id.name"
          return-object
          :filter="searchFilter"
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title> {{ $t("matches.create.no_users") }} </v-list-item-title>
          </v-list-item>
        </template>
        <template v-slot:item="{ item }">
          <v-list-item-avatar>
            <img :src="require(`@/assets/images/default-avatar.png`)"/>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-text="item.team_id.name"></v-list-item-title>
          </v-list-item-content>
        </template>
      </v-autocomplete>

			<span v-if="opponent" class="text-caption mt-2">
				{{ $t("matches.create.opp_availability") }} {{ opponent.availability.map(x => availability_items[x - 1]).join(', ') }}</span
			>

			<span class="text-h5 font-weight-bold mt-6"> {{ $t("matches.create.details") }} </span>
			<span class="text-caption font-weight-bold"> {{ $t("matches.create.details_sub") }} </span>

			<DatePicker v-model="date" class="mt-4" />
			<TimePicker v-model="time" class="mt-4" />
      <span class="error--text"> {{ $t("matches.create.disclaimer") }}</span>

			<Button
				@click="addMatch()"
				:loading="loading"
				:disabled="loading"
				class="align-self-center mt-12 mb-2"
				color="background"
				width="40%"
			>
        {{ $t("matches.plan") }}
			</Button>
			<span v-if="message" class="text-caption red--text text--accent-2 text-center align-self-center mb-auto">
				{{ message }}
			</span>

			<span v-if="false" class="text-caption font-weight-bold text-center align-self-center" style="width: 70%">
				{{ $t("matches.create.can_change") }}
			</span>
		</RightPanel>
	</Main>
</template>

<style scoped>

</style>

<script>
import Main from '../components/common/Main';
import RightPanel from '../components/common/RightPanel';
import Button from '../components/common/elements/Button';
import DatePicker from '../components/common/elements/DatePicker';
import TimePicker from '../components/common/elements/TimePicker';

import { gg_ladder_players, gg_create_match, GG_ENDPOINT, gg_ladder_info } from '../api';

export default {
	name: 'PlanMatch',

	components: {
		Main,
		RightPanel,
		Button,
		DatePicker,
		TimePicker,
	},

	data() {
		return {
			message: null,
			opponent: null,
			date: null,
			time: null,
      my_player: null,
			ladder_players: [],
			endpoint: GG_ENDPOINT,
			loading: false,
			availability_items: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
			bg_image_id: null,
		};
	},

	async created() {
		try {
			const ladder_info = await gg_ladder_info(this.$route.params.id);
			if (ladder_info.bg_image_id) {
				this.bg_image_id = ladder_info.bg_image_id;
			}

			const players = await gg_ladder_players(this.$route.params.id);
			if (players) {
        // If the players are users
        if (players[0].user_id) {
          this.my_player = players.find(p => p.user_id._id === this.$store.getters.getUserInfo._id); // find logged in player
          this.ladder_players = players.filter(p => p.user_id._id !== this.$store.getters.getUserInfo._id); // remove logged in player
        }
        // if the players are teams
        else {
          this.my_player = players.find(p => p.team_id.creator === this.$store.getters.getUserInfo._id); // find logged in player
          this.ladder_players = players.filter(p => p.team_id.creator !== this.$store.getters.getUserInfo._id); // remove logged in player
        }
			}
		} catch (err) {
			console.log(err);
			this.message = err;
		}
	},

	methods: {
		async addMatch() {
			this.reset();
			this.loading = true;

			try {
				if (!this.opponent || !this.date || !this.time) throw 'Please fill in all the fields';
				const result = await gg_create_match(this.$route.params.id, this.opponent._id, this.parseDate(), this.my_player._id);
				if (result) {
          if (this.my_player.user_id) {
            await this.$router.replace({name: 'ManageMatches', params: {id: this.$route.params.id}});
          }
          else {
            await this.$router.replace({name: 'TeamManageMatches', params: {id: this.$route.params.id}});
          }
        }
			} catch (err) {
				this.message = err;
			}

			this.loading = false;
		},

		reset() {
			this.message = null;
			this.loading = false;
		},

		parseDate() {
			return Date.parse(this.date + ' ' + this.time);
		},

    searchFilter(item, queryText, itemText) {
      const hasValue = val => val != null ? val : '';
      const text = hasValue(itemText);
      const query = hasValue(queryText);
      return text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1;
    },
	},
};
</script>
