<template>
	<v-row class="fill-height" no-gutters>
		<v-spacer class="hidden-xs-only" />
		<v-col cols="12" md="5">
			<v-card flat rounded="0" color="primary" height="100%">
				<div class="d-flex flex-column pa-12 fill-height">
					<BackBar v-if="!no_backbar" class="mt-0 mb-12" />
					<slot></slot>
				</div>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import BackBar from './BackBar';

export default {
	name: 'RightPanel',
	components: {
		BackBar,
	},
	props: ['no_backbar'],
};
</script>
