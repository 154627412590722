<template>
  <div>
    <div class="d-flex">
      <router-link :to="{ name: 'PlayerProfile', params: { id: data.user_id._id } }">
        <v-avatar color="black" size="42">

          <v-img
              :src="
								data.user_id.image_id
									? endpoint + '/image/raw/' + data.user_id.image_id
									: require(`@/assets/images/default-avatar.png`)
							"
          />
        </v-avatar>
      </router-link>
      <div class="d-flex flex-column ml-3">
        <div class="d-flex">
          <router-link class="link" :to="{ name: 'PlayerProfile', params: { id: data.user_id._id } }">
						<span class="font-weight-bold">
							{{ data.user_id.name }}
						</span>
          </router-link>
          <span v-if="data.ladder_id"> &nbsp;{{ $t("feed.posted_in") }}&nbsp; </span>
          <router-link v-if="data.ladder_id" class="link" :to="{ name: 'LadderView', params: { id: data.ladder_id._id } }">
            <span v-if="data.ladder_id" class="font-weight-bold"> {{ data.ladder_id.name }} </span>
          </router-link>
        </div>
        <div class="d-flex">
          <span class="text-caption"> {{ timestamp }} </span>
        </div>
      </div>
    </div>
    <div class="mt-2">
      <p class="text-body-2">
        {{ data.data }}
      </p>
    </div>
    <div v-if="data.image_id" class="mx-n3">
      <v-img max-height="500" :src="endpoint + '/image/raw/' + data.image_id" contain/>
    </div>
    <div v-if="embed_link" class="mx-n3">
      <iframe
          type="text/html"
          width="100%"
          height="450"
          :src="embed_link"
          frameborder="0"
          allowfullscreen="true"
      ></iframe>
    </div>
  </div>
</template>

<style scoped>
.link {
  display: flex;
  text-decoration: none;
  color: inherit;
}
</style>

<script>
import {GG_ENDPOINT} from '@/api';
import {youtubeRegEx, twitchclipRegEx, twitchvodRegEx} from '@/plugins/embed_regex';

export default {
  name: 'PostContentSelf',

  props: ['data'],

  data() {
    return {
      endpoint: GG_ENDPOINT,
      timestamp: '',
      embed_link: null,
    };
  },

  created() {
    var d = new Date(this.data.created_at);
    this.timestamp = d.toLocaleDateString('en-NL', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });

    if (this.data.link) {
      var youtubeMatch = this.data.link.match(youtubeRegEx);
      var twitchclipMatch = this.data.link.match(twitchclipRegEx);
      var twitchvodMatch = this.data.link.match(twitchvodRegEx);
      if (youtubeMatch) {
        this.embed_link =
            'https://www.youtube.com/embed/' + youtubeMatch[1] + '?autoplay=0&origin=dev.bmslab.utwente.nl';
      } else if (twitchclipMatch) {
        this.embed_link = 'https://clips.twitch.tv/embed?clip=' + twitchclipMatch[1] + '&parent=dev.bmslab.utwente.nl';
      } else if (twitchvodMatch) {
        this.embed_link =
            'https://player.twitch.tv/?video=v' + twitchvodMatch[1] + '&autoplay=false&parent=dev.bmslab.utwente.nl';
      }
    }
  },
};
</script>
