<template>
	<Main background="fifa-background.png">
		<Sidebar />
		<v-container>
			<BackBar />
			<ProfileInfo type="company" :id="$route.params.id" />
			<!--
			<PageHeader title="Participates in" left="true" class="mt-12 mb-2">
				<div width="300px" class="ml-3">
					<v-select
						class="mt-0 pt-0"
						placeholder="Select ladder"
						background-color="primary"
						height="36"
						rounded
					></v-select>
				</div>
			</PageHeader>
			<LadderHeader />
			-->
		</v-container>
	</Main>
</template>

<script>
import Sidebar from '../components/common/Sidebar';
import BackBar from '../components/common/BackBar';
import ProfileInfo from '../components/profile/ProfileInfo';
import Main from '../components/common/Main';
import PageHeader from '../components/common/PageHeader';
import LadderRow from '../components/ladders/LadderRow';
import LadderHeader from '../components/ladders/LadderHeader';

export default {
	name: 'CompanyProfile',

	components: {
		Sidebar,
		BackBar,
		ProfileInfo,
		Main,
		PageHeader,
		LadderRow,
		LadderHeader,
	},
};
</script>