import gg_axios from '../gg_axios';

/**
 * Gets ladders with the teams the user is part of
 * @param {*} - OPTIONAL PARAM if `user_id` returns users ladders else returns my ladder
 * @returns {array} player static information
 */
async function gg_teamladder_my(user_id = null) {
    try {
        return await gg_axios('GET', user_id ? `/teamladder/my/${user_id}` : `/teamladder/my/`);
    } catch (error) {
        throw error;
    }
}

export default gg_teamladder_my;
