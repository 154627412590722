<template>
	<v-card class="rounded-xl pa-3 my-5" color="cardBaseColor" :style="highlightBorder(data.ladder_id)">
		<PostContentMatch v-if="type === 'MATCH'" :data="data" />
		<PostContentSelf v-if="type === 'PERSONAL'" :data="data" />
		<div v-if="data.reactions.length || data.comments.length" class="d-flex mt-2">
			<div v-if="data.reactions.length" class="d-flex" @click="reactionsdialog = true" style="cursor: pointer">
				<v-icon small>mdi-thumb-up</v-icon>
				<div class="ml-2 mr-4 text-subtitle-2">
					{{ number_of_likes(data.reactions) }} {{ $t("feed.like") }}{{ number_of_likes(data.reactions) === 1 ? '' : 's' }}
				</div>
				<v-icon small>{{ clapicon }}</v-icon>
				<div class="ml-2 mr-4 text-subtitle-2">{{ number_of_congrats(data.reactions) }} {{ $t("feed.congrats") }}</div>
				<v-icon small>{{ staricon }}</v-icon>
				<div class="ml-2 mr-4 text-subtitle-2">{{ number_of_impressed(data.reactions) }} {{ $t("feed.impressed") }}</div>
			</div>
			<div
				v-if="data.comments.length"
				class="d-flex ml-auto"
				style="cursor: pointer"
				@click="showcomments = !showcomments"
			>
				<v-icon small>mdi-message-reply</v-icon>
				<div class="ml-2 text-subtitle-2">
					{{ data.comments.length }} {{ $t("feed.comment") }} {{ data.comments.length === 1 ? '' : 's' }}
				</div>
			</div>
		</div>
		<div class="d-flex flex-wrap mt-2">
			<v-btn-toggle
				v-model="reaction"
				@change="postReact"
				class="reactions d-flex flex-wrap"
				color="primary"
				dense
				shaped
				tile
				group
			>
				<div class="mr-2 mb-2">
					<v-btn elevation="0" rounded class="d-flex" color="background">
						<v-icon small color="primary">mdi-thumb-up</v-icon> <span class="ml-2">{{ $t("feed.like") }}</span>
					</v-btn>
				</div>
				<div class="mr-2 mb-2">
					<v-btn elevation="0" rounded class="d-flex" color="background">
						<v-icon small color="green">{{ clapicon }}</v-icon> <span class="ml-2">{{ $t("feed.congrats") }}</span>
					</v-btn>
				</div>
				<div>
					<v-btn elevation="0" rounded class="d-flex mb-2" color="background">
						<v-icon small color="yellow">{{ staricon }}</v-icon> <span class="ml-2">{{ $t("feed.impressed") }}</span>
					</v-btn>
				</div>
				<div class="ml-10 mb-2">
					<v-btn
						v-if="$store.getters.getUserInfo.usertype === 'SUPERUSER'"
						@click="deletePost"
						elevation="0"
						rounded
						class="d-flex"
						color="background"
					>
						<v-icon small color="primary">mdi-delete-forever</v-icon> <span class="ml-2"></span>
					</v-btn>
				</div>
			</v-btn-toggle>
		</div>
		<div class="d-flex mt-4">
			<v-avatar class="mt-1" color="black" size="32">
				<v-img :src="$store.getters.getAvatar" alt="Avatar" />
			</v-avatar>
			<v-textarea
				v-model="commentbox"
				@keydown.enter.prevent="postComment"
				dense
				filled
				rounded
				class="ml-2"
				background-color="background"
				placeholder="Write a comment..."
				hide-details
				auto-grow
				rows="1"
			/>
		</div>
		<div v-if="showcomments" class="mt-3">
			<v-data-iterator
				:items="data.comments"
				:items-per-page="-1"
				hide-default-footer
				no-data-text="There are no posts to show"
			>
				<template v-slot:default="{ items }">
					<div v-for="item in items" :key="item._id" class="d-flex mt-2">
						<router-link :to="{ name: 'PlayerProfile', params: { id: item.user_id._id } }">
							<v-avatar class="mt-1" color="black" size="32">
								<v-img
									:src="
										item.user_id.image_id
											? endpoint + '/image/raw/' + item.user_id.image_id
											: require(`@/assets/images/default-avatar.png`)
									"
								/>
								<!--								<v-img :src="endpoint + '/image/raw/' + item.user_id.image_id" alt="Avatar" />-->
							</v-avatar>
						</router-link>
						<v-card color="background" flat class="rounded-xl py-2 px-4 ml-2">
							<div class="d-flex flex-column">
								<router-link class="link" :to="{ name: 'PlayerProfile', params: { id: item.user_id._id } }">
									<span class="font-weight-bold text-subtitle-2"> {{ item.user_id.name }} </span>
								</router-link>
								<div class="text-body-2">
									{{ item.data }}
								</div>
							</div>
						</v-card>
						<div class="ml-10 mb-2">
							<v-btn
								v-if="$store.getters.getUserInfo.usertype === 'SUPERUSER'"
								@click="deleteComment(item._id)"
								elevation="0"
								rounded
								class="d-flex"
								color="background"
							>
								<v-icon small color="primary">mdi-delete-forever</v-icon> <span class="ml-2"></span>
							</v-btn>
						</div>
					</div>
				</template>
			</v-data-iterator>
		</div>

		<v-dialog v-model="reactionsdialog" max-width="380">
			<v-card>
				<v-toolbar flat color="cardBaseColor">
					<v-toolbar-title class="font-weight-bold">Reactions</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-btn icon dark @click="reactionsdialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar>
				<v-list color="background">
					<v-list-item v-for="item in data.reactions" :key="item.user_id._id">
						<v-list-item-avatar size="42">
							<v-img :src="endpoint + '/image/raw/' + item.user_id.image_id"></v-img>
						</v-list-item-avatar>

						<v-list-item-content>
							<v-list-item-title v-text="item.user_id.name"></v-list-item-title>
						</v-list-item-content>

						<v-list-item-icon>
							<v-icon v-if="item.type === reactions[0]" color="primary"> mdi-thumb-up </v-icon>
							<v-icon v-if="item.type === reactions[1]" color="green"> {{ clapicon }} </v-icon>
							<v-icon v-if="item.type === reactions[2]" color="yellow"> {{ staricon }} </v-icon>
						</v-list-item-icon>
					</v-list-item>
				</v-list>
			</v-card>
		</v-dialog>
	</v-card>
</template>

<style scoped>
.reactions >>> .v-btn--active:before {
	opacity: 0.25;
}

.link {
	display: flex;
	text-decoration: none;
	color: inherit;
}
</style>

<script>
import PostContentSelf from './PostContentSelf';
import PostContentMatch from './PostContentMatch';

import { mdiHandClap, mdiStarFourPoints } from '@mdi/js';

import {
	GG_ENDPOINT,
	gg_social_comment_post,
	gg_social_react_post,
	gg_social_delete_post,
	gg_social_delete_comment,
} from '../../api';

export default {
	name: 'Post',

	components: {
		PostContentSelf,
		PostContentMatch,
	},

	props: ['type', 'data'],

	data() {
		return {
			endpoint: GG_ENDPOINT,
			showcomments: false,
			reactions: ['LIKE', 'CONGRATS', 'IMPRESS'],
			reaction: null,
			reactionsdialog: false,
			commentbox: null,
			clapicon: mdiHandClap,
			staricon: mdiStarFourPoints,
			likes: [],
			congrats: [],
			impressed: [],
      superuser: false,
      cardBaseColorHighlight: '#5359EB',
		};
	},

	async created() {
    this.superuser = this.$store.getters.getUserInfo.usertype === 'SUPERUSER'

		if (this.data.comments.length === 1) this.showcomments = true;

		var current_user_reaction = this.data.reactions.find(e => e.user_id._id === this.$store.getters.getUserInfo._id);
		if (current_user_reaction) {
			this.reaction = this.reactions.indexOf(current_user_reaction.type);
		}
	},

	methods: {
		async postComment() {
			try {
				await gg_social_comment_post(this.data._id, this.commentbox);
				this.$emit('action');
				this.commentbox = null;
				this.showcomments = 'true';
			} catch (err) {
				console.log(err);
			}
		},

		async deletePost() {
			try {
				await gg_social_delete_post(this.data._id);
				this.$emit('action');
			} catch (err) {
				console.log(err);
			}
		},

		async deleteComment(comment_id) {
			try {
				await gg_social_delete_comment(this.data._id, comment_id);
				this.$emit('action');
			} catch (err) {
				console.log(err);
			}
		},

		async postReact() {
			try {
        if (typeof this.reaction === "undefined") {
          await gg_social_react_post(this.data._id, "DELETE");
        } else {
          await gg_social_react_post(this.data._id, this.reactions[this.reaction]);
        }
				this.$emit('action');
			} catch (err) {
				console.log(err);
			}
		},
		number_of_likes(reactions) {
			let likes = reactions.filter(function(reaction) {
				return reaction.type === 'LIKE';
			});
			return likes.length;
		},
		number_of_congrats(reactions) {
			let congrats = reactions.filter(function(reaction) {
				return reaction.type === 'CONGRATS';
			});
			return congrats.length;
		},
		number_of_impressed(reactions) {
			let impressed = reactions.filter(function(reaction) {
				return reaction.type === 'IMPRESS';
			});
			return impressed.length;
		},
    highlightBorder(ladder_id) {
      let style = '';
      if (!ladder_id) {
        style = { border: "4px solid " + this.cardBaseColorHighlight + " !important" };
      } else {
        style = { border: 'none' }
      }

      return style
    },
	},
};
</script>
