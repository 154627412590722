<template>
    <div class="my-6">
        <Button color="cardBaseColor" @click="$router.go(-1)">
            <v-icon left color="primary"> mdi-arrow-left-circle </v-icon>
          {{ $t("common.go_back") }}
        </Button>
    </div>
</template>

<script>
import Button from './elements/Button';

export default {
	name: 'BackBar',

	components: {
        Button
	}
};
</script>