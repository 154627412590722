<template>
	<nav>
		<v-app-bar-nav-icon v-if="!isLarge" @click="drawer = !drawer"></v-app-bar-nav-icon>
		<v-navigation-drawer v-model="drawer" :permanent="isLarge" app floating color="cardBaseColor">
			<v-list-item>
				<v-list-item-content>
					<v-list-item-title class="text-h5 font-arial font-weight-bold"> ESPORTS<br />LADDER.GG </v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<hr class="mx-4" style="border-color: var(--v-primary-base)" />

			<v-list flat dense nav>
				<v-list-item-group color="primary">
					<v-list-item v-for="item in items" :key="item.title" :to="item.link">
						<v-list-item-icon>
							<v-icon>{{ item.icon }}</v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title>{{ item.title }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-item-group>
			</v-list>

			<template v-slot:append>
				<v-card flat ripple color="primary" class="rounded-0" to="/player">
					<div class="d-flex px-4 py-2">
						<v-avatar color="black" size="56">
							<v-img :src="$store.getters.getAvatar" alt="Avatar" />
						</v-avatar>
						<div class="d-flex flex-column justify-center ml-3">
							<span class="text-h9 font-weight-bold">
								{{ $store.getters.getUser ? $store.getters.getUserInfo.name : 'User' }}
							</span>
							<span class="text-caption">{{ $store.getters.getCompanyInfo.name }}</span>
						</div>
					</div>
				</v-card>

				<v-list-item>
					<v-btn icon @click="logout"><v-icon> mdi-logout </v-icon></v-btn>
					<v-btn icon to="/settings"><v-icon> mdi-cog </v-icon></v-btn>
					  <v-btn v-if="false" icon to="/notifications"><v-icon> mdi-bell </v-icon></v-btn>
          <LocaleChanger @change="setSidebarItems"/>
				</v-list-item>
			</template>
		</v-navigation-drawer>
	</nav>
</template>

<style scoped>
.font-arial {
	font-family: 'Arial Narrow', Arial, sans-serif !important;
	color: white !important;
}
</style>

<script>
import LocaleChanger from '@/components/LocaleChanger.vue';
export default {
  components: {
    LocaleChanger
  },

	data() {
		return {
			items: [],
			right: null,
			drawer: false,
		};
	},

  created() {
    this.import();
    this.setSidebarItems();
  },

	methods: {
		async logout() {
			this.$store.commit('logout');
			await this.$router.push('/login');
		},
    async setSidebarItems() {
      try {
        this.items = [];
        this.items.push({title: this.$i18n.t('sidebar.dashboard'), icon: 'mdi-home', link: '/dashboard'});
        this.items.push({title: this.$i18n.t('sidebar.ladders'), icon: 'mdi-poll', link: '/ladders'});
        this.items.push({title: this.$i18n.t('sidebar.social_feed'), icon: 'mdi-account', link: '/feed'});
        // this.items.push({ title: 'Teams', icon: 'mdi-account-group', link: '/teams' }) ;
        if (this.$store.getters.getUserInfo.usertype === 'SUPERUSER') {
          this.items.push({title: this.$i18n.t('sidebar.users'), icon: 'mdi-account-group', link: '/users'});
          this.items.push({title: this.$i18n.t('sidebar.matches'), icon: 'mdi-controller-classic', link: '/matches'});
        }
      } catch (error) {
        console.log(error);
      }
    },
    async import() {
      this.i18n = await import('@/main.js');
    },
	},

	computed: {
		isLarge() {
			return this.$vuetify.breakpoint.name !== 'xs' && this.$vuetify.breakpoint.name !== 'sm';
		},
	},
};
</script>
