import gg_axios from '../gg_axios';

/**
 * Accept a match
 * @param {*} match_id
 * @returns
 */
export default async function(match_id) {
	try {
		const result = await gg_axios('PUT', `/match/accept/${match_id}`);

		return result;
	} catch (error) {
		throw error;
	}
}
