import gg_axios from '../gg_axios';

/**
 * POST - Login
 * @param {string} email
 * @param {string} password
 * @returns token + payload (user info)
 */
async function gg_login(email, password) {
    try {
        const result = await gg_axios('POST', '/auth/login', { email, password });

        return result;
    } catch (error) {
        throw error;
    }
}

export default gg_login;