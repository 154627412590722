import gg_axios from '../gg_axios';

async function gg_add_ladder(name, uses_teams, device, bio, image_id, bg_image_id, tags) {
	try {
		const result = await gg_axios('POST', `/ladder`, { name, uses_teams, device, bio, image_id, bg_image_id, tags });

		return result;
	} catch (error) {
		throw error;
	}
}

export default gg_add_ladder;
