import gg_axios from '../gg_axios';

async function gg_ladder_info(id) {
	try {
		const result = await gg_axios('GET', `/ladder/${id}`);

		return result;
	} catch (error) {
		throw error;
	}
}

export default gg_ladder_info;
