import gg_axios from '../gg_axios';

async function gg_accept_request(team_id, user_id) {
    try {
        const result = await gg_axios('PUT', `/team/accept`, { team_id, user_id});
        return result;
    } catch (error) {
        throw error;
    }
}

export default gg_accept_request;
