<template>
  <v-card flat color="primary" class="rounded-xl my-2">
    <v-row align="center" no-gutters>
      <v-col cols="3">
        <span class="text-subtitle-2 font-weight-bold ml-4"> {{ $t("dashboard.notifs.date") }} </span>
      </v-col>

      <v-col cols="3" class="d-flex">
        <span class="text-subtitle-2 font-weight-bold"> {{ $t("dashboard.notifs.type") }} </span>
      </v-col>

      <v-col cols="2" class="d-flex">
        <span class="text-subtitle-2 font-weight-bold"> {{ $t("common.ladder") }} </span>
      </v-col>

      <v-col cols="1">
        <span class="text-subtitle-2 font-weight-bold hidden-md-and-down"> {{ $t("dashboard.notifs.opponent") }} </span>
        <span class="text-subtitle-2 font-weight-bold hidden-lg-and-up"> {{ $t("dashboard.notifs.opp") }} </span>
      </v-col>

      <v-col class="d-flex">
        <span class="text-subtitle-2 font-weight-bold hidden-sm-and-down"> {{ $t("common.score") }} </span>
      </v-col>

      <v-col cols="1" align-self="end">
        <span class="text-subtitle-2 font-weight-bold hidden-sm-and-down"> {{ $t("dashboard.notifs.redirect") }} </span>
        <span class="text-subtitle-2 font-weight-bold hidden-md-and-up"> {{ $t("dashboard.notifs.link") }} </span>
      </v-col>

    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "NotifHeader"
}
</script>

<style scoped>

</style>