<template>
	<div>
		<div class="d-flex">
			<router-link :to="{ name: 'LadderView', params: { id: data.ladder_id._id } }">
				<v-avatar color="primary" size="42">
					<v-icon size="26" class="mt-1" color="white"> {{ trophyicon }} </v-icon>
				</v-avatar>
			</router-link>
			<div class="d-flex flex-column ml-3">
				<router-link class="link" :to="{ name: 'LadderView', params: { id: data.ladder_id._id } }">
					<span class="font-weight-bold"> {{ data.ladder_id.name }} </span>
				</router-link>
				<div class="d-flex">
					<span class="text-caption"> {{ timestamp }} </span>
					<span class="text-caption mx-2"> • </span>
					<span class="text-caption"> {{ $t("common.match") }} </span>
				</div>
			</div>
		</div>
		<div class="mt-2 mx-n3">
			<v-card flat class="rounded-0" color="background">
				<div class="d-flex justify-space-around py-8 px-12">
					<div class="d-flex flex-column align-center">
						<v-avatar color="black" size="96" class="mb-3">
							<v-img
								:src="
									data.match_id.players[0].user_id.image_id
										? endpoint + '/image/raw/' + data.match_id.players[0].user_id.image_id
										: require(`@/assets/images/default-avatar.png`)
								"
								alt="Avatar"
							/>
						</v-avatar>

						<span class="text-subtitle-1 font-weight-bold"> {{ data.match_id.players[0].user_id.name }} </span>
						<span class="text-subtitle-2"> {{ data.match_id.players[0].user_id.company_id.name }} </span>
						<div v-if="false"><v-chip x-small color="primary"> {{ $t("common.rank") }} 1 </v-chip></div>
					</div>
					<div class="d-none d-sm-flex flex-column justify-center align-center">
						<span class="text-subtitle-1"> {{ $t("common.score") }} </span>
						<span class="text-h2 font-weight-bold mb-5 primary--text">
							{{ data.match_id.players[0].stats.my_goal }} - {{ data.match_id.players[0].stats.opponent_goal }}
						</span>
					</div>
					<div class="d-flex d-sm-none flex-column justify-center align-center">
						<span class="text-h2 font-weight-bold mb-5 primary--text">
							-
						</span>
					</div>
					<div class="d-flex flex-column align-center">
						<v-avatar color="black" size="96" class="mb-3">
							<v-img
								:src="
									data.match_id.players[1].user_id.image_id
										? endpoint + '/image/raw/' + data.match_id.players[1].user_id.image_id
										: require(`@/assets/images/default-avatar.png`)
								"
								alt="Avatar"
							/>
						</v-avatar>
						<span class="text-subtitle-1 font-weight-bold"> {{ data.match_id.players[1].user_id.name }} </span>
						<span class="text-subtitle-2"> {{ data.match_id.players[1].user_id.company_id.name }} </span>
						<div v-if="false"><v-chip x-small color="primary"> {{ $t("common.rank") }} 2 </v-chip></div>
					</div>
				</div>
				<div class="d-flex d-sm-none flex-column justify-center align-center">
					<span class="text-subtitle-1"> {{ $t("common.score") }} </span>
					<span class="text-h2 font-weight-bold mb-5 primary--text">
						{{ data.match_id.players[0].stats.my_goal }} - {{ data.match_id.players[0].stats.opponent_goal }}
					</span>
				</div>
			</v-card>
		</div>
	</div>
</template>

<style scoped>
.link {
	display: flex;
	text-decoration: none;
	color: inherit;
}
</style>

<script>
import { GG_ENDPOINT } from '@/api';
import { mdiTrophyVariant } from '@mdi/js';

export default {
	name: 'PostContentMatch',

	props: ['type', 'data'],

	data() {
		return { endpoint: GG_ENDPOINT, timestamp: '', trophyicon: mdiTrophyVariant };
	},

	created() {
		var d = new Date(this.data.created_at);
		this.timestamp = d.toLocaleDateString('en-NL', {
			year: 'numeric',
			month: 'short',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
		});
	},
};
</script>
