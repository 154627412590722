import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '../store';

import Homepage from '../views/Homepage.vue';
import Login from '../views/Login.vue';
import Register from '../views/Register.vue';
import Reset from '../views/Reset.vue';
import PlayerProfile from '../views/PlayerProfile.vue';
import CompanyProfile from '../views/CompanyProfile.vue';
import Settings from '../views/Settings.vue';
import CompanySettings from '../views/CompanySettings.vue';
import Ladders from '../views/Ladders.vue';
import LadderSettings from '../views/LadderSettings.vue';
import LadderView from '../views/LadderView.vue';
import Dashboard from '../views/Dashboard.vue';
import LadderSignup from '../views/LadderSignup.vue';
import TeamLadderSignup from '../views/TeamLadderSignup.vue';
import PlanMatch from '../views/PlanMatch.vue';
import CustomMatch from '../views/CustomMatch.vue';
import LadderInfo from '../views/LadderInfo.vue';
import ManageMatches from '../views/ManageMatches.vue';
import Notifications from '../views/Notifications.vue';
import Upload from '../views/Upload.vue';
import Error from '../views/Error.vue';
import SocialFeed from '../views/SocialFeed.vue';
import MatchInfo from '../views/MatchInfo.vue';
import Users from '../views/Users.vue';
import Matches from '../views/Matches.vue';
import Teams from '../views/Teams.vue';
import TeamManage from '../views/TeamManage.vue';
import TeamLadderView from "../views/TeamLadderView.vue";
import TeamManageMatches from "../views/TeamManageMatches.vue";
import TeamUpload from "../views/TeamUpload";
import TeamProfile from "../views/TeamProfile";

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		redirect: '/login',
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
		props: true,
		beforeEnter: checkLoggedIn,
	},
	{
		path: '/register',
		name: 'Register',
		component: Register,
		props: true,
	},
	{
		path: '/reset/:id/:resettoken',
		name: 'Reset password',
		component: Reset,
		props: true,
	},
	{
		path: '/player/:id?',
		name: 'PlayerProfile',
		component: PlayerProfile,
		beforeEnter: checkAccess,
	},
	{
		path: '/company/:id?',
		name: 'CompanyProfile',
		component: CompanyProfile,
		beforeEnter: checkAccess,
	},
	{
		path: '/settings',
		name: 'Settings',
		component: Settings,
		beforeEnter: checkAccess,
	},
	{
		path: '/settings/company',
		name: 'CompanySettings',
		component: CompanySettings,
		beforeEnter: checkAccess,
		meta: { permission: 'admin' },
	},
	{
		path: '/ladders',
		name: 'Ladders',
		component: Ladders,
		beforeEnter: checkAccess,
	},
	{
		path: '/ladder/view/:id',
		name: 'LadderView',
		component: LadderView,
		beforeEnter: checkAccess,
	},
	{
		path: '/ladder/matches/:id',
		name: 'ManageMatches',
		component: ManageMatches,
		beforeEnter: checkAccess,
		// meta: { needsLadderSignup: true },
		props: true,
	},
	{
		path: '/ladder/signup/:id',
		name: 'LadderSignup',
		component: LadderSignup,
		beforeEnter: checkAccess,
	},
	{
	path: '/ladder/teamsignup/:id',
	name: 'TeamLadderSignup',
	component: TeamLadderSignup,
	beforeEnter: checkAccess,
	},
	{
		path: '/ladder/info/:id',
		name: 'LadderInfo',
		component: LadderInfo,
		beforeEnter: checkAccess,
	},
	{
		path: '/ladder/:mode/:id?',
		name: 'LadderSettings',
		component: LadderSettings,
		beforeEnter: checkAccess,
		meta: { permission: 'superuser' },
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		component: Dashboard,
		beforeEnter: checkAccess,
	},
	{
		path: '/planmatch',
		name: 'PlanMatch',
		component: PlanMatch,
		beforeEnter: checkAccess,
	},
	{
		path: '/custommatch/:id',
		name: 'CustomMatch',
		component: CustomMatch,
		beforeEnter: checkAccess,
		// meta: { needsLadderSignup: true },
	},
	{
		path: '/upload/:id',
		name: 'Upload',
		component: Upload,
		beforeEnter: checkAccess,
		props: true,
	},
	{
		path: '/notifications',
		name: 'Notifications',
		component: Notifications,
		beforeEnter: checkAccess,
	},
	{
		path: '/feed',
		name: 'SocialFeed',
		component: SocialFeed,
		beforeEnter: checkAccess,
	},
	{
		path: '/error',
		alias: '*',
		name: 'Error',
		component: Error,
	},
	{
		path: '/matchinfo/:id?',
		name: 'MatchInfo',
		component: MatchInfo,
		beforeEnter: checkAccess,
	},
	{
		path: '/users',
		name: 'Users',
		component: Users,
		beforeEnter: checkAccess,
		meta: { permission: 'superuser' },
	},
	{
		path: '/matches',
		name: 'Matches',
		component: Matches,
		beforeEnter: checkAccess,
		meta: { permission: 'superuser' },
	},
	{
		path: '/teams',
		name: 'Teams',
		component: Teams,
		beforeEnter: checkAccess,
	},
	{
		path: '/team/manage/:id',
		name: 'TeamManage',
		component: TeamManage,
		beforeEnter: checkAccess,
	},
	{
		path: '/teamladder/view/:id',
		name: 'TeamLadderView',
		component: TeamLadderView,
		beforeEnter: checkAccess,
	},
	{
		path: '/teamladder/matches/:id',
		name: 'TeamManageMatches',
		component: TeamManageMatches,
		beforeEnter: checkAccess,
		// meta: { needsLadderSignup: true },
		props: true,
	},
	{
		path: '/teamupload/:id',
		name: 'TeamUpload',
		component: TeamUpload,
		beforeEnter: checkAccess,
		props: true,
	},
	{
		path: '/team/:id',
		name: 'TeamProfile',
		component: TeamProfile,
		beforeEnter: checkAccess,
		props: true,
	}

];

const router = new VueRouter({
	mode: 'hash',
	routes,
});

function checkAccess(to, from, next) {
	if (!store.getters.getUser) {
		next({ name: 'Login', query: { redirect: to.fullPath } });
	} else if (
		to.meta.permission === 'admin' &&
		store.getters.getUserInfo.usertype !== 'ADMIN' &&
		store.getters.getUserInfo.usertype !== 'SUPERUSER'
	) {
		next({ name: 'Error' });
	} else if (to.meta.permission === 'superuser' && store.getters.getUserInfo.usertype !== 'SUPERUSER') {
		next({ name: 'Error' });
	// } else if (to.meta.needsLadderSignup && !store.getters.getLadders.ranked.some(e => e._id === to.params.id)) {
	// 	next({ name: 'Error' });
	} else {
		next();
	}
}

function checkLoggedIn(to, from, next) {
	if (store.getters.getUser && store.getters.getUserInfo) {
		next({ name: 'Dashboard' });
	} else {
		next();
	}
}

export default router;
