<template>
	<v-card flat color="cardBaseColor" class="rounded-xl my-2" style="overflow: hidden">
		<v-row align="center" no-gutters style="min-height: 44px">
			<v-col
				class="d-flex justify-center align-center align-self-stretch"
				cols="1"
				style="background-color: var(--v-primary-base)"
			>
				<span class="text-h6 font-weight-bold"> {{ data.rank }} </span>
			</v-col>

			<v-col class="d-flex justify-center" cols="1">
				<router-link :to="{ name: 'CompanyProfile', params: { id: data._id } }">
					<v-avatar color="black" size="32">
						<v-img
							:src="
								data.image_id
									? endpoint + '/image/raw/' + data.image_id
									: require(`@/assets/images/default-company.png`)
							"
						/>
					</v-avatar>
				</router-link>
			</v-col>

			<v-col class="d-flex flex-column" cols="5">
				<span class="text-h7 font-weight-bold"> {{ data.name }} </span>
				<span class="text-caption"> {{ data.city }} </span>
			</v-col>

			<v-col class="d-flex justify-center">
				<span class="text-h6 font-weight-bold"> {{ data.players }} </span>
			</v-col>

			<v-col class="d-flex justify-center">
				<span class="text-h6 font-weight-bold"> {{ data.matches }} </span>
			</v-col>

			<v-col class="d-flex justify-center">
				<span class="text-h6 font-weight-bold primary--text"> {{ Math.round(data.score) }} </span>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
import { GG_ENDPOINT } from '@/api';

export default {
	props: ['data'],

	data() {
		return {
			endpoint: GG_ENDPOINT,
		};
	},
};
</script>