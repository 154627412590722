<template>
  <div class="locale-changer">
    <v-select
        v-model="$i18n.locale"
        :items="langs"
        item-text="text"
        item-value="code"
        :placeholder="$i18n.locale"
        background-color="background"
        height="25"
        rounded
        append-icon=""
        style="min-width: 70%;"
        @change="commitLanguage(); $emit('change')"
    ></v-select>
  </div>
</template>

<script>
export default {
  name: "locale-changer",
  data() {
    return {

      langs: [
        { code: "en", text: "English"},
        { code: "nl", text: "Nederlands"},
        { code: "de", text: "Deutsch"},
      ],
    };
  },
  methods: {
    commitLanguage() {
      sessionStorage.setItem("locale", this.$i18n.locale);
    },
  },
};
</script>