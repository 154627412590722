import gg_axios from '../gg_axios';

async function gg_update_company(payload) {
    try {
        const result = await gg_axios('PUT', '/company', payload);

        return result;
    } catch (error) {
        throw error;
    }
}

export default gg_update_company;