<template>
	<Main background="fifa-background.png">
		<Sidebar />
		<v-container class="my-15">
			<v-row>
				<v-col cols="12" md="6">
					<Top3 :ladders="top_ladders" />
				</v-col>
				<v-col cols="12" md="6">
					<Upcoming
              :matches="matches"
              :title="$t('dashboard.upcoming.title')"
              :none="$t('dashboard.upcoming.none')"
          />
				</v-col>
			</v-row>
      <v-row>
        <v-col cols="12" md="4">
          <MyTeams :teams="teams.teams" />
        </v-col>
        <v-col cols="12" md="4">
          <Upcoming
              :matches="tbd_matches"
              :title="$t('dashboard.auto.title')"
              :none="$t('dashboard.auto.none')"
          />
        </v-col>
        <v-col cols="12" md="4">
          <SearchUsers
            :title="$t('dashboard.search.title')"
          />
        </v-col>
      </v-row>
			<v-row>
				<v-col>
					<AllStats :stats="all_stats" />
				</v-col>
			</v-row>
			<v-row>
<!--				<v-col cols="12" md="5">-->
<!--					<Connections />-->
<!--				</v-col>-->
				<v-col cols="12" md="12">
          <DefaultCard title="Notifications" height="100%">
          <NotifHeader />
          <v-data-iterator
              :items="notifs"
              :items-per-page=items_per_page
              :page=page_notifs
              :length=length_notifs
              hide-default-footer
          >
            <template v-slot:default="{ items }">
              <NotifRow v-for="item in items" :key="item._id" :notif="item" :none="$t('dashboard.auto.none')"/>
            </template>
            <template v-slot:footer>
              <div class="text-center">
                <v-pagination v-model="page_notifs" :length="length_notifs"></v-pagination>
              </div>
            </template>
          </v-data-iterator>
        </DefaultCard>

				</v-col>
			</v-row>
		</v-container>

    <ReminderModal :show="showModal" @close="showModal = false">
    </ReminderModal>

	</Main>
</template>

<script>
import Sidebar from '../components/common/Sidebar';
import Main from '../components/common/Main';
import Top3 from '../components/dashboard/Top3';
import Upcoming from '../components/dashboard/Upcoming';
import AllStats from '../components/dashboard/AllStats';
import ReminderModal from '../components/dashboard/ReminderModal.vue';
import MyTeams from '../components/dashboard/MyTeams.vue';
import DefaultCard from '../components/common/DefaultCard';
import NotifRow from '../components/dashboard/NotifRow.vue';
import NotifHeader from '../components/dashboard/NotifHeader.vue';
import SearchUsers from '../components/dashboard/SearchUsers.vue'

import {gg_dash_stat, gg_ladder_my, gg_run_matchmaking, gg_update_user, gg_user_info, gg_team_my, gg_teamladder_my, gg_notif_user} from '../api';

export default {
	name: 'Dashboard',

	components: {
		Sidebar,
		Main,
		Top3,
		Upcoming,
		AllStats,
    ReminderModal,
    MyTeams,
    DefaultCard,
    NotifRow,
    NotifHeader,
    SearchUsers,
	},

	data() {
		return {
			message: null,
			top_ladders: [],
			all_stats: {},
			matches: [],
      showModal: false,
      teams: [],
      team_matches: [],
      tbd_matches: [],
      notifs: [],
      page_notifs: 1,
      length_notifs: 0,
      items_per_page: 10,
		};
	},
	methods: {
		async matchmaking() {
			try {
				const result = await gg_run_matchmaking();
				if (result) {
          return result
				}
			} catch (err) {
				console.log(err);
				this.message = err;
			}
		},
	},

	async created() {
		try {
			const result = await gg_dash_stat();
			if (result) {
				this.top_ladders = result.ladders;
				this.top_ladders.sort((a, b) =>
					a.user.rank === b.user.rank ? a.user.elo - b.user.elo : a.user.rank - b.user.rank
				);
				this.all_stats = result.all_stats;
				this.matches = result.matches.filter((match) => match.time);
        this.matches.push(...result.teammatches.filter((match) => match.time));
				this.matches.sort((a, b) => a.time - b.time);
        this.tbd_matches = result.matches.filter((match) => !match.time);
        this.tbd_matches.push(...result.teammatches.filter((match) => !match.time));
        // console.log(this.matches)
			}
     const teams = await gg_team_my();
      if (teams) {
        this.teams = teams;
      }
      const notifs = await gg_notif_user(this.$store.getters.getUserInfo._id);
      if (notifs) {
        // Reversed so newest notifications are shown on top.
        this.notifs = notifs.reverse();
        this.length_notifs = Math.ceil(this.notifs.length / this.items_per_page);
      }

      // If a user is not in a ladder, just logged in or last modal was 24hrs ago, show modal
        const user_id = this.$store.getters.getUserInfo._id
        const user_ladders = await gg_ladder_my(user_id)
        const user_team_ladders = await gg_teamladder_my();
        // If you are not in a ladder
        if(user_ladders['ranked'].length === 0 && user_ladders['follow'].length === 0 && user_team_ladders['ranked'].length === 0) {
          const user_info = await gg_user_info(user_id)
          const last_modal_time = user_info['last_modal']
          const now_time = Date.now()
          // Threshold for the frequency of the popups is 24 hrs
          const threshold = 86400000
          // If you came from the login screen, or haven't had a popup yet, or haven't had one for 24 hrs
          if(typeof last_modal_time === 'undefined' || now_time - last_modal_time > threshold) {
            this.showModal = true
            await gg_update_user({last_modal: now_time})
          }
        }

		} catch (err) {
			console.log(err);
			this.message = err;
		}
	},
};
</script>
