import Vue from 'vue';
import Vuex from 'vuex';

import { GG_ENDPOINT } from '@/api';

Vue.use(Vuex);

const SESSIONSTORAGE_TOKEN = 'gg_token';
const SESSIONSTORAGE_USER = 'gg_user';
const SESSIONSTORAGE_COMPANY = 'gg_company';
const SESSIONSTORAGE_LADDERS = 'gg_ladders';
const SESSIONSTORAGE_LANGUAGE = 'gg_language';

export default new Vuex.Store({
	state: {
		user: localStorage.getItem(SESSIONSTORAGE_TOKEN)
			? JSON.parse(localStorage.getItem(SESSIONSTORAGE_TOKEN))
			: null,
		user_info: localStorage.getItem(SESSIONSTORAGE_USER)
			? JSON.parse(localStorage.getItem(SESSIONSTORAGE_USER))
			: null,
		company_info: localStorage.getItem(SESSIONSTORAGE_COMPANY)
			? JSON.parse(localStorage.getItem(SESSIONSTORAGE_COMPANY))
			: null,
		ladders: localStorage.getItem(SESSIONSTORAGE_LADDERS)
			? JSON.parse(localStorage.getItem(SESSIONSTORAGE_LADDERS))
			: null,
		language: localStorage.getItem(SESSIONSTORAGE_LANGUAGE)
			? JSON.parse(localStorage.getItem(SESSIONSTORAGE_LANGUAGE))
			: null,
	},
	mutations: {
		// USER
		login(state, payload) {
			state.user = payload;
			localStorage.setItem(SESSIONSTORAGE_TOKEN, JSON.stringify(payload));
		},

		logout(state) {
			state.user = null;
			localStorage.removeItem(SESSIONSTORAGE_TOKEN);
			localStorage.removeItem(SESSIONSTORAGE_USER);
			localStorage.removeItem(SESSIONSTORAGE_COMPANY);
			localStorage.removeItem(SESSIONSTORAGE_LADDERS);
		},

		updateUser(state, payload) {
			state.user_info = payload;
			localStorage.setItem(SESSIONSTORAGE_USER, JSON.stringify(state.user_info));
		},

		updateCompany(state, payload) {
			state.company_info = payload;
			localStorage.setItem(SESSIONSTORAGE_COMPANY, JSON.stringify(state.company_info));
		},
		updateLadders(state, payload) {
			state.ladders = payload;
			localStorage.setItem(SESSIONSTORAGE_LADDERS, JSON.stringify(state.ladders));
		},
		updateLanguage(state, payload) {
			state.language = payload;
			localStorage.setItem(SESSIONSTORAGE_LANGUAGE, JSON.stringify(state.language));
		}
	},

	actions: {},

	getters: {
		getUser(state) {
			return state.user;
		},

		getUserInfo(state) {
			return state.user_info;
		},

		getCompanyInfo(state) {
			return state.company_info;
		},

		getLadders(state) {
			return state.ladders;
		},

		getAvatar(state) {
			if (state.user_info.image_id) return GG_ENDPOINT + '/image/raw/' + state.user_info.image_id;
			else return require(`@/assets/images/default-avatar.png`);
		},

		getLanguage(state) {
			return state.language;
		},
	},

	modules: {},
});
