<!-- Extending v-main to include a background image -->

<template>
	<v-main :style="backgroundStyles(background, image_id)">
		<slot></slot>
	</v-main>
</template>

<script>
import { GG_ENDPOINT } from '../../api';

export default {
	// always provide filename and extension of bg image from /assets/images/ in `background` prop. if using server image as background, provide `image_id` prop
	props: ['background', 'image_id'],
	methods: {
		backgroundStyles(background, image_id) {
			var url;
			if (image_id) url = GG_ENDPOINT + '/image/raw/' + image_id;
			else url = require(`../../assets/images/${background}`);

			return {
				'background-image': 'url(' + url + ')',
				'background-size': 'cover',
				'background-attachment': 'fixed',
			};
		},
	},
};
</script>
