<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-card v-bind="attrs" v-on="on" flat color="primary" class="rounded-pill" style="overflow: hidden">
        <div class="d-flex align-center py-2 px-3">
          <v-avatar color="black" size="32">
            <v-img :src="$store.getters.getAvatar" alt="Avatar"/>
          </v-avatar>
          <span class="text-caption-2 font-weight-bold ml-3">
            <span v-if="isSuperuser || hasLadder">Hey {{ $store.getters.getUserInfo.name }}, {{ $t("feed.what_share") }}</span>
            <span v-else>Hey {{ $store.getters.getUserInfo.name }}, {{ $t("feed.sign_up_to_post") }}</span>
					</span>
          <v-icon class="ml-auto mr-2"> mdi-pencil</v-icon>
        </div>
      </v-card>
    </template>

    <v-card color="cardBaseColor" class="pb-2">
      <v-toolbar flat color="cardBaseColor">
        <v-toolbar-title class="font-weight-bold">Create post</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
            icon
            dark
            @click="
						dialog = false;
						select = null;
					"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <div class="d-flex flex-column justify-center px-2 mb-2">
        <span class="ml-2 text-caption"> Post to </span>
        <v-select
            v-model="select"
            :items="ladders"
            item-text="ladder_id.name"
            return-object
            class="pt-0"
            :placeholder="$t('feed.select_ladder')"
            background-color="background"
            height="42"
            rounded
            hide-details
        ></v-select>
      </div>
      <v-card flat color="background" class="rounded-xl px-4 pb-2 ma-2">
        <v-textarea
            class="hideline"
            background-color="background"
            v-model="postcontent"
            :placeholder="$t('misc.write_sth')"
            hide-details
            auto-grow
        />
        <div class="d-flex">
          <v-file-input
              v-if="!postlink"
              v-model="postimage"
              class="hideline"
              background-color="background"
              :label="$t('feed.attach_image')"
              prepend-icon="mdi-image-outline"
              hide-details
              style="width: 100%"
          ></v-file-input>
          <v-text-field
              v-if="!postimage"
              v-model="postlink"
              class="hideline"
              :label="$t('feed.embed_video')"
              placeholder="Twitch/YouTube link"
              prepend-icon="mdi-link"
              hide-details
              style="width: 100%"
          ></v-text-field>
        </div>
      </v-card>

      <span v-if="message" class="text-caption red--text text--accent-2 mx-2"> {{ message }} </span>

      <v-card-actions>
        <Button
            block
            @click="createPost"
            :disabled="!postcontent || (!select && !superuser)"
            :loading="loading"
            style="text-transform: uppercase"
        >
          {{ $t('feed.post') }}
        </Button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.hideline >>> .v-input__slot::after {
  border-style: none !important;
}

.hideline >>> .v-input__slot::before {
  border-style: none !important;
}
</style>

<script>
import Button from '../common/elements/Button';

import {gg_user_stat, gg_upload_image, gg_social_create_post} from '../../api';
import {youtubeRegEx, twitchclipRegEx, twitchvodRegEx} from '../../plugins/embed_regex';

export default {
  name: 'CreatePost',

  components: {
    Button,
  },

  data() {
    return {
      dialog: false,
      select: null,
      superuser: false,
      ladders: [],
      postcontent: '',
      postimage: null,
      postlink: null,
      image_id: null,
      message: null,
      loading: false,
    };
  },

  watch: {
    dialog: function (val) {
      this.select = null;
      this.postlink = null;
      this.postimage = null;
      this.message = null;
    },
  },

  async created() {
    try {
      const stats = await gg_user_stat(this.$store.getters.getUserInfo._id);
      this.ladders = stats.ladders;
      this.superuser = this.$store.getters.getUserInfo.usertype === 'SUPERUSER'
    } catch (err) {
      console.log(err);
    }
  },

  methods: {
    async createPost() {
      this.message = null;
      this.loading = true;

      try {
        if (this.postimage) {
          const image_result = await gg_upload_image(this.postimage);
          this.image_id = image_result._id;
          // console.log(this.image_id);
        }

        if (this.postlink) {
          if (
              !this.postlink.match(youtubeRegEx) &&
              !this.postlink.match(twitchclipRegEx) &&
              !this.postlink.match(twitchvodRegEx)
          )
            throw new Error('Please enter a valid YouTube or Twitch link');
        }

        const result = await gg_social_create_post(
            this.select ? this.select.ladder_id._id : null,
            this.postcontent,
            this.postlink,
            this.image_id
        );

        this.dialog = false;
        this.$emit('action');
      } catch (err) {
        this.message = err;
      }

      this.loading = false;
    },
  },
  computed: {
    isSuperuser() {
      return this.$store.getters.getUserInfo.usertype === 'SUPERUSER';
    },
    hasLadder() {
      return this.ladders.length > 0;
    }
  },
};
</script>
