<template>
	<Main background="fifa-background.png">
		<Sidebar />
		<v-container>
			<BackBar />
			<ProfileInfo type="player" :id="this.$route.params.id" />
			<PageHeader :title="$t('profile.my_stats')" left="true" class="mt-12 mb-2"> </PageHeader>
			<div>
				<v-select
					v-model="select"
					:items="ladders"
					item-text="ladder_id.name"
					return-object
					class="dropdown mt-0 pt-0"
					placeholder="Select ladder"
					background-color="primary"
					height="36"
					rounded
					style="width: 350px"
				></v-select>
			</div>
			<LadderStats :stats="getSelectedStats" />
		</v-container>
	</Main>
</template>

<style scoped>
</style>

<script>
import Sidebar from '../components/common/Sidebar';
import BackBar from '../components/common/BackBar';
import ProfileInfo from '../components/profile/ProfileInfo';
import Main from '../components/common/Main';
import PageHeader from '../components/common/PageHeader';
import LadderStats from '../components/profile/LadderStats';

import { gg_user_stat } from '../api';

export default {
	name: 'PlayerProfile',

	components: {
		Sidebar,
		BackBar,
		ProfileInfo,
		Main,
		PageHeader,
		LadderStats,
	},

	data() {
		return {
			select: null,
			ladders: [],
		};
	},

    async created() {
      await this.updateInfo();
    },

  methods: {
    async updateInfo() {
      try {
        const stats = await gg_user_stat(
            this.$route.params.id ? this.$route.params.id : this.$store.getters.getUserInfo._id
        );
        this.ladders = stats.ladders;
        this.ladders.unshift({ladder_id: {name: 'All ladders'}, user: stats.all_stats});
        this.select = this.ladders[0];
      } catch (err) {
        console.log(err);
      }
    },
  },

	computed: {
		getSelectedStats() {
			if (this.select) return this.select.user;
			else return { matches: 0, wins: 0, losses: 0, draws: 0 };
		},
	},

  watch: {
    '$route.params.id': function () {
      this.updateInfo();
    },
  },
};
</script>
